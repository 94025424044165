import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { environment } from 'environments/environment';
import { PracticesService } from 'app/services/practices/practices.service';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-community-options',
  templateUrl: './community-options.component.html',
  styleUrls: ['./community-options.component.css']
})
export class CommunityOptionsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() practiceId;
  @Input() value;
  @Input() controlName = '';
  @Input() label = 'Glo Community';
  @Input() placeholder = 'Select Community';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() optional = false;
  @Input() disabled = false;
  @Input() dropdownParent = '';
  @Input() primaryCommunity;
  // values: string[];

  apiUrl = environment.apiUrl;
  community;
  options: Options;
  selectedCommunities = [];
  touched = false;
  data = [];
  dataOption = [];

  constructor(private practicesService: PracticesService) { }

  ngOnInit() {
    if (this.value && this.value.length) {
      this.data = this.value.map((community) => ({
        id: community.id.toString(),
        glo_community_id: community.glo_community_id,
        is_primary: community.is_primary,
        name: community.name,
        text: community.name,
        description: community.description,
        logo: community.logo || '/assets/images/community_logo.jpg'
      })
      );
      setTimeout(() => {
        this.onSelect({ data: this.data });
      });
    }

    this.options = {
      tags: false,
      multiple: this.multiple,
      placeholder: this.placeholder,
      language: { errorLoading: () => 'Searching...' },
      minimumInputLength: 1,
      dropdownParent: (this.dropdownParent ? $(`#acticity-artifact`) : $(`body`)),

      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/Communities',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1
        }),
        processResults: (data) => {
          this.dataOption = data.communities
            .filter(c => this.primaryCommunity?.id != c.id)
            .map((community) => ({
              id: community.id.toString(),
              glo_community_id: community.glo_community_id,
              is_primary: community.is_primary,
              name: community.name,
              text: community.name,
              description: community.description,
              logo: community.logo || '/assets/images/community_logo.jpg'
            })
            );

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 20) < data.total_count
            }
          };
        }
      },

      escapeMarkup: (markup) => markup,
      templateResult: this.formatRepo,
    };
  }

  formatRepo(repo) {
    if (repo.loading) {
      return repo.text;
    }
    const markup = `<div class="avtar-list">
      <span class="avatar avatar-sm"><img src="${repo.logo}"></span>
      <div class="avtar-body"><span> ${repo.name}</span></div>
      </div>` ;
    return markup;
  }

  valueChanged(event) {
    if (event) {
      this.onSelect({
        data: this.dataOption.filter(o =>
          Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
      });
    }
  }

  ngOnChanges(change) {
    // if (!this.multiple && change.value) {
    //   this.values = this.value ? this.value[0]?.id : null;
    // }
    if (this.primaryCommunity) {
      this.onSelect({ data: this.form.get(this.controlName).value });
    }
  }

  onSelect(event) {
    const communities = event.data.map(community => ({
      id: community.id.toString(),
      glo_community_id: community.glo_community_id,
      is_primary: community.is_primary,
      name: community.name,
      text: community.name,
      description: community.description,
      logo: community.logo || '/assets/images/community_logo.jpg'
    }));

    if (communities.length) {
      this.touched = true;
      if (this.multiple) {
        this.selectedCommunities = lodash.uniqBy(communities.concat(this.form.get(this.controlName).value || []), 'glo_community_id');
      } else {
        this.selectedCommunities = communities;
      }
      this.selectedCommunities = this.selectedCommunities.filter(c => this.primaryCommunity?.id != c.id)
      if (!isEqual(this.selectedCommunities, this.data)) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(this.selectedCommunities);
      $('#' + this.controlName).val(null).trigger('change');
    }
  }

  remove(id) {
    this.touched = true;
    this.selectedCommunities = this.form.get(this.controlName).value.filter(community => community.id !== id);
    if (!isEqual(this.selectedCommunities, this.data)) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(this.selectedCommunities);
    $('#' + this.controlName + ' option[value="' + id + '"]').remove();
  }

}
