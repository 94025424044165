import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getFeedbacks(params) {
    return this.http.get(this.apiUrl + '/v1/feedbacks', { params });
  }

  getFeedback(id) {
    return this.http.get(this.apiUrl + `/v1/feedbacks/${id}`);
  }

  submitFeedback(data) {
    return this.http.post(this.apiUrl + '/v1/feedbacks', data);
  }

  resolveFeedback(id, data) {
    return this.http.put(this.apiUrl + `/v1/feedbacks/${id}`, data);
  }

  getFeedbackStatuses() {
    return this.http.get(this.apiUrl + '/v1/feedback-statuses');
  }
}
