<div>
  <form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
    <div class="form-group">
      <app-person-options [form]="form" controlName="people" i18n-label label="Share with" [submitted]="submitted"
        [multiple]="true" i18n-placeholder placeholder="Select people" [scroll]="true" [value]="people"></app-person-options>
    </div>
    <!-- http errors -->
    <div class="form-group">
      <div class="invalid" *ngFor="let error of httpError">
        {{error['message']}}
      </div>
    </div>
    <!-- submit botton -->
    <button type="submit" class="btn btn-primary" [disabled]="submitted"
      [ngClass]="{'submitted':submitted}">{{submitBtnText | uppercase}}</button>
    <span class="btn btn-light ml-5" (click)="cancel()" i18n>Cancel</span>
  </form>
</div>
