import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { PeopleService } from 'app/services/people/people.service';
import { Router } from '@angular/router';
import { PracticesService } from 'app/services/practices/practices.service';
import { LoginService } from 'app/services/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'app/services/filter/filter.service';

@Component({
  selector: 'app-search-people',
  templateUrl: './search-people.component.html',
  styleUrls: ['./search-people.component.css']
})
export class SearchPeopleComponent implements OnInit {
  options;
  apiUrl = environment.apiUrl;
  bucketUrl = environment.bucketUrl;

  constructor(private practiceService: PracticesService, private peopleService: PeopleService, private router: Router,
    private loginService: LoginService, private toastrService: ToastrService, private filterService: FilterService) { }

  ngOnInit() {
    this.options = {
      tags: false,
      multiple: false,
      placeholder: $localize`Search people and add to practice`,
      language: { errorLoading: () => `Searching...` },
      minimumInputLength: 1,
      dropdownParent: $('#search-person-add'),
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/people/autocomplete',
        data: (params) => {
          // if (this.peopleService.searchAsFilter) {
          //   this.filterService.form.get('search_people').setValue(params.term)
          // }
          return {
            'filters[search]': params.term.trim(),
            page: params.page || 1
          };
        },
        processResults: (data) => {
          const people = data.people.map((person) => ({
            id: person.person_id,
            login: person.login,
            text: this.peopleService.getPersonName(person),
            email: person.contact.email_id,
            photo_url: person.photo_url,
            cityId: person.location ? person.location.id : 0,
            isMember: this.isPracticeMember(person.practices)
          })
          );

          return {
            results: people,
            pagination: {
              more: (data.page * 20) < data.total_count
            }
          };
        }
      },
      escapeMarkup: (markup) => markup,
      templateResult: this.formatRepo.bind(this),
      templateSelection: this.formatRepoSelection.bind(this)
    };

    $(document).on('input', '#search-person-add .select2-search__field', (e) => {
      if (this.peopleService.searchAsFilter) {
        this.filterService.form.get('search_people').setValue(e.target.value.trim())
      }
    });
  }

  isPracticeMember(practices) {
    const fp = practices.filter(p => {
      return p.id == this.practiceService.currentPractice[0]['id'] && p.memberships.find(m => m.member_type_id != 3)
    })
    return fp.length;
  }

  formatRepo(repo) {
    if (repo.loading) {
      return repo.text;
    }
    const markup = `<div class="avtar-list">
      <span class="avatar avatar-sm"><img src="${repo.photo_url}"></span>
      <div class="avtar-body avtar-body-member">
        <span class="truncate-1">${repo.text + '  |  ' + repo.email}</span>
      </div>
      <div class="btn-actions-search mt-5 ml-10">
        <span class="btn btn-primary btn-sm">`+ (repo.isMember ? $localize`Edit` : $localize`Add`) + `</span>
      </div>
    </div>`;

    return markup;
  }

  formatRepoSelection(repo) {
    if (repo.id && this.practiceService.currentPractice) {
      const ccLocation = this.loginService.getCenterCoordinatorLocationId([this.practiceService.currentPractice[0]['id']]);
      if (this.loginService.isPrivilegedUser([this.practiceService.currentPractice[0]['id']]) || ccLocation.includes(repo.cityId)) {
        this.router.navigate(['/practices', this.practiceService.currentPractice[0]['c_name'], 'add-member'],
          { queryParams: { id: repo.login } });
      } else {
        this.toastrService.error($localize`You are not authorized to add member of another location.`);
      }
    }
    return repo.text;
  }
}
