import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  apiUrl = environment.apiUrl;
  searchHistory = [];

  constructor(private http: HttpClient) { }

  saveSearchHistory(data) {
    return this.http.post(this.apiUrl + '/v1/search/history', data);
  }

  getSearchHistory(params) {
    return this.http.get(this.apiUrl + '/v1/search/recent', { params });
  }

  getTrending(params) {
    return this.http.get(this.apiUrl + '/v1/search/trending', { params });
  }

  deleteSearchHistory() {
    return this.http.delete(this.apiUrl + '/v1/search/history');
  }

  searchNuggets(params) {
    return this.http.get(this.apiUrl + '/v1/search/nuggets', { params });
  }

  getTokensBySearchTerm(params) {
    return this.http.get(this.apiUrl + '/v1/search/tokens', { params: params });
  }

  searchLabs(params) {
    return this.http.get(this.apiUrl + '/v1/search/labs', { params });
  }

  getOtherPages(params) {
    return this.http.get(this.apiUrl + '/v1/search/others', { params });
  }

  mapSearchData(page) {
    let url = '';
    const other = page['other'];
    if (page['search_type'] === 'poc') {
      page['url'] = '/pocs/' + page['id'];
      page['img'] = 'poc';
      page['parent_title'] = 'Innovation';
    } else if (page['search_type'] === 'tech_talk') {
      page['url'] = '/tech-talks/' + page['id'];
      page['img'] = 'tech-talk';
      page['parent_title'] = 'Tech Talk';
    } else if (page['search_type'] === 'tech_document') {
      page['url'] = '/tech-papers/' + page['id'];
      page['img'] = 'tech-doc';
      page['parent_title'] = 'Tech Document';
    } else if (page['search_type'] === 'blog') {
      page['url'] = '/blogs/' + page['id'];
      page['img'] = 'blog';
      page['parent_title'] = 'Blog';
    } else if (page['search_type'] === 'case_study') {
      page['url'] = '/case-studies/' + page['id'];
      page['img'] = 'case-study';
      page['parent_title'] = 'Case Study';
    } else if (page['search_type'] === 'digital_accelerator') {
      if (other['entity_category']['c_name'] === 'solution_accelerator') {
        url = '/solution-accelerators/';
        page['img'] = 'solutions';
      } else if (other['entity_category']['c_name'] === 'industry_solution') {
        url = '/industry-solutions/';
        page['img'] = 'solutions';
      } else if (other['entity_category']['c_name'] === 'technology_selection') {
        url = '/technology-selections/';
        page['img'] = 'frameworks';
      } else if (other['entity_category']['c_name'] === 'assessment_framework') {
        url = '/assessments/';
        page['img'] = 'frameworks';
      }
      url += other['c_name']
      page['url'] = other['page_url'] || url;
      page['parent_title'] = other['entity_category']['name'];
    } else if (page['search_type'] === 'cms_page') {
      page['url'] = other['page']['url'] || '/pages/' + page['id'];
      page['parent_title'] = other['page']['parent_title'];
      page['img'] = 'page';
    } else if (page['search_type'] === 'sales_collateral') {
      const practice = page['practices'][0];
      page['url'] = page['url'] || `/practices/${practice?.c_name}/sales-materials/${page['id']}`;
      page['parent_title'] = page['parent_title'] || 'Overview & Offerings';
      page['img'] = 'tech-doc';
    } else if (page['search_type'] === 'curated_content') {
      const practice = page['practices'][0];
      page['url'] = page['url'] || `/practices/${practice?.c_name}/recommended-reads/${page['id']}`;
      page['parent_title'] = page['parent_title'] || 'Recommended Reads';
      page['img'] = 'tech-doc';
    } else if (page['search_type'] === 'technology_radar') {
      page['url'] = page['url'] || `/technology-radar/${other['c_name']}`;
      page['parent_title'] = page['parent_title'] || 'Technology Radar';
      page['img'] = 'radars';
    } else if (page['search_type'] === 'partnership') {
      page['url'] = page['url'] || `/partners/${other['c_name']}`;
      page['parent_title'] = page['parent_title'] || 'Partnerships';
      page['img'] = 'tech-doc';
    }
    return page
  }
}
