import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TechTalksService } from 'app/services/tech-talks/tech-talks.service';
import * as lodash from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-language-options',
  templateUrl: './language-options.component.html',
  styleUrls: ['./language-options.component.css']
})
export class LanguageOptionsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;

  label = $localize`Language`;
  placeholder = $localize`Select language`;
  languages = [{ id: '', text: '' }];
  options: Options;
  selectedLanguages = [];
  touched = false;
  values: string[];

  constructor(private techTalkService: TechTalksService) { }

  ngOnInit() {
    if (this.multiple) {
      this.label = $localize`Languages`;
      this.placeholder = $localize`Select languages`;
    }
    this.techTalkService.getLanguages()
      .subscribe(response => {
        this.languages = this.languages.concat(
          response['rows'].map(language => ({ id: language.id, text: language.language }))
        );
        if (this.value && this.value.length) {
          // this.onSelect({ data: this.value.map(language => ({ id: language.id, text: language.language })) });
          // if (this.multiple) {
          setTimeout(() => {
            this.values = this.value.map(v => v.id.toString());
          })
          // } else {
          //   this.values = this.value ? this.value[0]?.id : null;
          // }
        }
      });

    this.options = {
      placeholder: this.placeholder,
      multiple: this.multiple,
      minimumResultsForSearch: -1
    };
  }

  ngOnChanges(change) {
    // if (!this.multiple && change.value) {
    //   this.values = this.value ? this.value[0]?.id : null;
    // }
  }

  valueChanged(event) {
    this.onSelect({
      data: this.languages.filter(o =>
        Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
    });
  }

  onSelect(event) {
    let languages = event.data.map(language => +language.id)
      .filter(id => id);
    if (languages.length) {
      this.touched = true;
      if (this.multiple) {
        languages = lodash.uniq(languages.concat(this.form.get(this.controlName).value || []));
        // $('#language-options select').val(null).trigger('change');
      }
      this.selectedLanguages = this.languages.filter(language => languages.includes(language.id));
      this.form.get(this.controlName).setValue(languages);
      if (this.multiple && this.selectedLanguages.length) {
        const input = document.querySelector('.select2-search__field') as HTMLInputElement
        input.placeholder = this.placeholder;
        input.style.width = 'auto';
      }
    }
  }

  remove(id) {
    this.touched = true;
    const languages = this.form.get(this.controlName).value.filter(language => language !== id);
    this.selectedLanguages = this.languages.filter(language => languages.includes(language.id));
    this.form.get(this.controlName).setValue(languages);
    this.values = this.selectedLanguages.map(l => l.id.toString())
  }
}
