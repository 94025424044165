import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class ConferenceActivityService {
  apiUrl = environment.apiUrl;
  deleteEventEvent = new EventEmitter();
  deleteEventConfirmEvent = new EventEmitter();

  constructor(private loginService: LoginService, private http: HttpClient) { }

  // get events list
  getEvents(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/conference-activities', { params, headers });
  }

  // get event info
  getEvent(id, headers?) {
    return this.http.get(this.apiUrl + `/v1/conference-activities/${id}`, { headers });
  }

  // delete event
  deleteEvent(id) {
    return this.http.delete(this.apiUrl + `/v1/conference-activities/${id}`);
  }

  // submit event
  submitEvent(data) {
    return this.http.post(this.apiUrl + '/v1/conference-activities', data);
  }

  // update event
  updateEvent(id, data) {
    return this.http.put(this.apiUrl + `/v1/conference-activities/${id}`, data);
  }

  canEditDelete(event) {
    let practices = [];
    if (event['practices'] && event['practices'].length > 0) {
      practices = event['practices'].map(practice => practice.id);
    }

    let locations = [];
    if (event['location']) {
      locations = [event['location'].city];
    }

    return this.loginService.isPrivilegedUser(practices) || this.loginService.isCenterCoordinator(locations, practices) ||
      (event.creator?.contact.email_id === this.loginService.currentUser.user.email);
  }
}

export interface ConferenceActivities {
  conference_activities: [];
  total_count: number;
}
