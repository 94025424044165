import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  field: FieldConfig;
  scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  open = false;

  constructor() { }

  ngOnInit() {
  }

  toogle() {
    this.open = !this.open;
  }

  close() {
    this.open = false;
  }
}
