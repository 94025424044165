import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-move-from-core',
  templateUrl: './move-from-core.component.html',
  styleUrls: ['./move-from-core.component.css']
})
export class MoveFromCoreComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() data;
  submitted = false;
  httpError;

  constructor(private fb: UntypedFormBuilder, private modalService: ModalPopupService, private practiceService: PracticesService,
    private toastrService: ToastrService, private commonService: CommonMethodsService) { }

  ngOnInit() {
    this.form = this.fb.group({
      memberType: this.fb.control(0, [])
    });
  }

  cancel() {
    this.modalService.close();
  }

  onSubmit() {
    if (this.form.value['memberType'] > 0) {
      if (!this.submitted) {
        this.submitted = true;

        const to = parseInt(this.form.value['memberType']);
        const from = this.data['orignalMemberId'];

        const memberData = {
          original_member_type: from,
          member_type: to
        };

        this.practiceService.updateMembership(this.data['id'], this.data['login'], memberData)
          .subscribe(response => {
            const headers = new HttpHeaders({
              'Cache-Control': 'no-cache'
            });
            this.toastrService.success(response['message'])
            this.practiceService.updateMembershipEvent.emit(headers);
            this.updateGmailGroups(this.data['email'], this.data['id'], 'move', to, from);
            this.cancel();
            this.submitted = false;
          }, error => {
            this.submitted = false;
            this.httpError = this.commonService.parseHttpErrors(error)[0]['message'];

          });
      } else {
        this.cancel();
      }
    }
  }

  updateGmailGroups(emailId, practiceId, action, member_type_id?, from_member_type_id?) {
    this.practiceService.updateGmailGroups(emailId, practiceId, action, member_type_id, from_member_type_id)
      .subscribe();
  }

}
