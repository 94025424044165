import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { Options } from 'select2';
import { DigitalAcceleratorsService } from '../../../modules/feature/digital-accelerator/digital-accelerators.service';

@Component({
  selector: 'app-industry-options',
  templateUrl: './industry-options.component.html',
  styleUrls: ['./industry-options.component.css']
})
export class IndustryOptionsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() optional = false;
  @Input() hideLabel = false;

  label = $localize`Industry`;
  placeholder = $localize`Select industry`;
  industries = [{ id: '', text: '' }];
  options: Options;
  selectedindustries = [];
  touched = false;
  values: string[];

  constructor(private digitalAcceleratorService: DigitalAcceleratorsService) { }

  ngOnInit() {
    if (this.multiple) {
      this.label = $localize`Industries`;
      this.placeholder = $localize`Select Industries`;
    }
    this.digitalAcceleratorService.getIndustries()
      .subscribe(response => {
        this.industries = this.industries.concat(
          response['industries'].map(industry => ({ id: industry.id, text: industry.name }))
        );
        if (this.value && this.value.length) {
          // this.onSelect({ data: this.value.map(industry => ({ id: industry.id, text: industry.name })) });
          this.values = this.value.map(v => v.id.toString());
        }
      });

    this.options = {
      placeholder: this.placeholder,
      multiple: this.multiple
    };
  }

  valueChanged(event) {
    this.onSelect({
      data: this.industries.filter(o =>
        Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
    });
  }

  onSelect(event) {
    let industries = event.data.map(industry => +industry.id)
      .filter(id => id);
    if (industries.length) {
      this.touched = true;
      if (this.multiple) {
        industries = lodash.uniq(industries.concat(this.form.get(this.controlName).value || []));
      }

      this.selectedindustries = this.industries.filter(industry => industries.includes(industry.id));
      this.form.get(this.controlName).setValue(industries);
      // $('#industry-options select').val(null).trigger('change');
      if (this.multiple && this.selectedindustries.length) {
        const input = document.querySelector(`.industry-options .select2-search__field`) as HTMLInputElement
        input.placeholder = this.placeholder;
        input.style.width = 'auto';
      }
    }
  }

  remove(id) {
    this.touched = true;
    const industries = this.form.get(this.controlName).value.filter(industry => industry !== id);
    this.selectedindustries = this.industries.filter(industry => industries.includes(industry.id));
    this.form.get(this.controlName).setValue(industries);
    this.values = this.selectedindustries.map(l => l.id.toString())
  }
}
