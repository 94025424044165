import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { FilterService } from 'app/services/filter/filter.service';
import { CarouselService, Carousels } from 'app/services/carousels/carousel.service';
import { LoginService } from 'app/services/auth/login.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NuggetsService } from 'app/services/nuggets/nuggets.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { PeopleService } from 'app/services/people/people.service';
import { AwardsService, Awards } from 'app/services/awards/awards.service'
import { findIndex, uniq } from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  pageId = environment.cmsPageIds['home'];
  carousels = { carousels: [], total_count: 0 };
  awards = { awards: [], total_count: 0 };
  editAccess = false;
  deleteCarouselEvent = new Subscription();
  showNavigationArrows = false;
  showNavigationIndicators = false;
  latestNuggets;
  trendingNugget;
  latestNuggetsLoaders = new Array(4);
  homeAwardsLoaders = new Array(16);
  carouselsLoaded = false;
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(private filterService: FilterService, private carouselService: CarouselService, private loginService: LoginService,
    private toastr: ToastrService, private modalService: ModalPopupService, private commonService: CommonMethodsService,
    private nuggetService: NuggetsService, private peopleService: PeopleService, private awardsService: AwardsService) { }

  ngOnInit() {
    if (this.loginService.submitAccess() && this.loginService.hasCmsEditAccess()) {
      this.editAccess = true;
    } else {
      this.editAccess = false;
    }

    this.getCarousels();
    this.getTrendingtNugget();
    this.getLatestNuggets();
    this.getLastQuaterAwards();
  }

  getLastQuaterAwards() {
    const filter = [{ key: 'perPage', values: [16] }];
    // get api params
    const params = this.filterService.getApiParams(filter);
    this.awardsService.getLastQuaterAwards(params).subscribe(response => {
      this.awards = <Awards>response;
    })
  }

  getCarousels() {
    this.carousels = { carousels: [], total_count: 0 };
    this.carouselsLoaded = false;
    const filter = [{ key: 'page', values: [1] }];
    // get api params
    const params = this.filterService.getApiParams(filter);

    this.carouselService.getCarousels(params)
      .subscribe(response => {
        this.carousels = <Carousels>response;
        if (this.carousels.carousels.length > 1) {
          this.showNavigationArrows = true;
          this.showNavigationIndicators = true;
        }
        this.carouselsLoaded = true;
      }, () => { this.carouselsLoaded = true; });
  }

  delete(id, title) {
    // this.nuggetService.deleteNuggets(this.id, this.poc['title'], 'poc', true);

    const data = {
      title: $localize`Delete carousel`, message: $localize`Are you sure you want to delete ${title} carousel?`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = true;
          this.carouselService.deleteCarousel(id)
            .subscribe(response => {
              this.toastr.success(response['message']);
              setTimeout(() => {
                this.carouselService.deleteCarouselEvent.emit(response);
              }, 1000);
            }, error => {
              this.toastr.error(error['error']['error']);
              this.carouselService.deleteCarouselEvent.emit(error);
            });
        }
      })
      .catch(() => { });

    this.deleteCarouselEvent = this.carouselService.deleteCarouselEvent
      .subscribe(() => {
        this.getCarousels();
        this.commonService.showLoader = false;
      });
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person) || person.login
  }

  getLatestNuggets() {
    let filter = [
      { key: 'filters[nugget_type][]', values: ['blog', 'poc', 'tech_document', 'tech_talk'] }
    ];
    const params = this.filterService.getApiParams(filter);
    this.nuggetService.getNuggets(params)
      .subscribe(response => {
        const latestNuggets = response['nuggets'];
        const personIds = uniq(latestNuggets.map(n => n.created_by?.split('@')[0]));
        if (personIds.length) {
          const params = this.filterService.getApiParams([{ key: 'filters[people][]', values: personIds }]);
          this.peopleService.getPeople(params)
            .subscribe(r => {
              this.latestNuggets = latestNuggets.map(n => {
                const login = n.created_by?.split('@')[0];
                const i = findIndex(r['people'], { login });
                n['person'] = { login };
                if (i > -1) {
                  n['person'] = r['people'][i];
                }
                return n;
              });
            });
        } else {
          this.latestNuggets = latestNuggets
        }
      })
  }

  getTrendingtNugget() {
    this.nuggetService.getTrendingtNugget()
      .subscribe(response => {
        const trendingNugget = response['trending_nugget'];
        const personIds = uniq(trendingNugget['authors'].map(n => n.login));
        if (personIds.length) {
          const params = this.filterService.getApiParams([{ key: 'filters[people][]', values: personIds }]);
          this.peopleService.getPeople(params)
            .subscribe(r => {
              trendingNugget['authors'] = trendingNugget['authors'].map(n => {
                const login = n.login;
                const i = findIndex(r['people'], { login });
                if (i > -1) {
                  n = r['people'][i];
                }
                return n;
              });
              this.trendingNugget = trendingNugget;
            });
        } else {
          this.trendingNugget = trendingNugget;
        }
      })
  }

  // getType(data) {
  //   let type = ''
  //   if (data.search_type === 'poc') {
  //     type = `Poc`;
  //   } else if (data.search_type === 'tech_talk') {
  //     type = `Tech Talk`;
  //   } else if (data.search_type === 'tech_document') {
  //     type = `Tech Document`;
  //   } else if (data.search_type === 'case_study') {
  //     type = `Case Study`;
  //   } else if (data.search_type === 'blog') {
  //     type = `Blog`;
  //   }
  //   return type;
  // }

  getRoute(data) {
    let url = ''
    if (data.search_type === 'poc' || data.nugget_type === 'Innovation') {
      url = `/pocs`;
    } else if (data.search_type === 'tech_talk' || data.nugget_type === 'Tech Talk') {
      url = `/tech-talks`;
    } else if (data.search_type === 'tech_document' || data.nugget_type === 'Tech Document') {
      url = `/tech-papers`;
    } else if (data.search_type === 'case_study' || data.nugget_type === 'Case Study') {
      url = `/case-studies`;
    } else if (data.search_type === 'blog' || data.nugget_type === 'Blog') {
      url = `/blogs`;
    }
    return url;
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }

  ngOnDestroy() {
    this.deleteCarouselEvent.unsubscribe();
    this.commonService.showLoader = false;
  }
}
