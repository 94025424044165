import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { BreadcrumbModule } from 'xng-breadcrumb';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FilterModule } from './modules/shared/filter/filter.module';
import { TimeagoModule } from 'ngx-timeago';
import { FooterComponent } from './components/footer/footer.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgDragDropModule } from 'ng-drag-drop';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CmsModule } from './modules/feature/cms/cms.module';
import { SharedModule } from './modules/shared/shared.module';

import { DatePipe, TitleCasePipe } from '@angular/common';
import { RemoveSpacePipe } from './pipes/remove-space.pipe';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { GetTokenComponent } from './components/get-token/get-token.component';
import { HomeComponent } from './components/home/home.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SubmitProspectComponent } from './components/customer-activities/submit-prospect/submit-prospect.component';
import { SubmitDocumentComponent } from './components/documents/submit-document/submit-document.component';
import { HttpCancelInterceptor } from './interceptors/http-cancel.interceptor';
import { CmsLoginComponent } from './components/cms/cms-login/cms-login.component';
import { HaveAQueryComponent } from './header/have-a-query/have-a-query.component';
import { CookieAlertComponent } from './components/cookie-alert/cookie-alert.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function tokenGetter() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return accessToken;
  }
  return '';
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    GetTokenComponent,
    FooterComponent,
    HomeComponent,
    BackButtonComponent,
    SubmitProspectComponent,
    SubmitDocumentComponent,
    CmsLoginComponent,
    HaveAQueryComponent,
    CookieAlertComponent,
    LoginCallbackComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BreadcrumbModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.apiDomain],
        disallowedRoutes: [environment.apiUrl + '/v1/verify-auth', environment.apiUrl + '/v1/verify-auth/token'],
        throwNoTokenError: true,
        skipWhenExpired: true
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TimeagoModule.forRoot(),
    ClickOutsideModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    FilterModule,
    CmsModule,
    SharedModule,
    NgDragDropModule.forRoot(),
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
    TitleCasePipe,
    RemoveSpacePipe,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
