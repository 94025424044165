<div class="add-il-form" *ngIf="form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group" id="titleGroup"
      [ngClass]="{'validate':form.get('name').invalid && (form.get('name').touched || invalidForm) || (spellCheckService.errorData && spellCheckService.errorData['title'])}">
      <label i18n>Title</label>
      <input type="text" class="form-control" i18n-placeholder placeholder="Enter title" spellcheck="true"
        formControlName="name">
      <div class="invalid" *ngIf="form.get('name').invalid && (form.get('name').touched || invalidForm)">
        <div *ngIf="form.get('name').errors.whitespace" i18n>Enter title</div>
        <div *ngIf="form.get('name').errors.maxlength" i18n>Title should be less than
          {{form.get('name').errors.maxlength.requiredLength}} characters
        </div>
      </div>
      <div class="invalid" *ngIf="spellCheckService.errorData && spellCheckService.errorData['name']">
        {{spellCheckService.errorData['name']['error']}}
      </div>
    </div>

    <div class="form-group"
      [ngClass]="{'validate':form.get('url').invalid && (form.get('url').touched || invalidForm)}">
      <label i18n>Url</label>
      <input type="text" class="form-control" i18n-placeholder placeholder="Enter url" formControlName="url">
      <div class="invalid" *ngIf="form.get('url').invalid && (form.get('url').touched || invalidForm)">
        <div *ngIf="form.get('url').errors.whitespace">Enter url</div>
        <div *ngIf="form.get('url').errors.url && !form.get('url').errors.whitespace" i18n>Enter a valid url</div>
        <div *ngIf="form.get('url').errors.maxlength" i18n>Url should be less than
          {{form.get('url').errors.maxlength.requiredLength}} characters
        </div>
      </div>
    </div>

    <!-- Target -->
    <div class="form-group" id="targetGroup">
      <label i18n>Target</label>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" id="target-self" formControlName="target" value="_self">
        <label class="custom-control-label font-weight-normal" for="target-self" i18n>Current Tab</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" id="target-blank" formControlName="target" value="_blank">
        <label class="custom-control-label font-weight-normal" for="target-blank" i18n>New Tab</label>
      </div>
    </div>


    <div class="form-group"
      [ngClass]="{'validate':form.get('position').invalid && (form.get('position').touched || invalidForm)}">
      <label><ng-container i18n>Position</ng-container> <span class="help" i18n>(Optional)</span></label>
      <input type="text" class="form-control" i18n-placeholder placeholder="Enter position" spellcheck="true"
        formControlName="position">
      <div class="invalid" *ngIf="form.get('position').invalid && (form.get('position').touched || invalidForm)">
        <div *ngIf="form.get('position').errors.pattern" i18n>Enter a valid position</div>
      </div>
    </div>

    <!-- http errors -->
    <div class="form-group">
      <div class="invalid" *ngFor="let error of httpError">
        {{error['message']}}
      </div>
    </div>

    <button class="btn btn-primary btn-sm mt-10" type="submit" [disabled]="form.invalid"
      [ngClass]="{'btn-disabled':form.invalid}">{{submitBtnText}}</button>
    <button class="btn btn-light btn-sm mt-10 ml-10" (click)="cancel()" i18n>Cancel</button>
  </form>
</div>