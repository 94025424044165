<div class="nugget-card" [routerLink]="['/team',data.person.login]">
  <div class="card-body">
    <div class="mb-10">
      <ng-template #toolTipContent>
        <app-tooltip-person-card [data]="data.person" (mouseleave)="t.close()">
        </app-tooltip-person-card>
      </ng-template>
      <span class="avatar avatar-xl" [ngbTooltip]="toolTipContent" tooltipClass='p-card-tooltip' container="body"
        triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
        (mouseleave)="onMouseleave($event,t)"> <img [src]="data.person.photo_url"> </span>
    </div>
    <div class="title height-30 truncate-2" [title]="peopleService.getPersonName(data.person)">
      {{peopleService.getPersonName(data.person)}}
    </div>
    <div class="type height-40 truncate-2" [title]="data.description">
      {{data.description}}
    </div>
    <a routerLink="/practices/{{practiceCName}}/add-member"
      [queryParams]="{id:data.person.person_id,channel:'recommend'}" class="btn btn-primary mt-20 btn-border"
      appStopPropagation i18n>Add to Practice</a>
  </div>
</div>