import { Component, OnInit, ElementRef, ViewEncapsulation, Input, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from 'app/services/cms/cms.service';
import { LoginService } from 'app/services/auth/login.service';
import { environment } from 'environments/environment';
import { Title } from '@angular/platform-browser';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { decode } from 'he';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IntrojsService } from 'app/services/introjs/introjs.service';
import { FilterService } from 'app/services/filter/filter.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-cms-dom',
  templateUrl: './cms-dom.component.html',
  styleUrls: ['./cms-dom.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CmsDomComponent implements OnInit, OnDestroy {

  content = '';
  parentRoute = '';
  editAccess = false;
  editUrl = '';
  // previewUrl = '';
  isPreview = false;
  url = '';
  showPreview = false;
  listenClickFunc: Function;
  breadcrumbs = [];
  page: any;
  cmsBucketUrl = '';
  @Input() page_id = '';
  @Input() title = '';
  @ViewChild('element', { static: true }) element: ElementRef;
  editRole = '';
  loaded = false;

  constructor(private cmsService: CmsService, private route: ActivatedRoute, private router: Router,
    private loginService: LoginService, private titleService: Title, private filterService: FilterService,
    private commonService: CommonMethodsService, private jwtHelper: JwtHelperService, private introjsService: IntrojsService,
    private breadcrumbsService: BreadcrumbService) { }

  ngOnInit() {
    this.cmsBucketUrl = environment.cmsBucketUrl;
    this.editAccess = this.loginService.hasCmsEditAccess();
    this.url = this.router.url;
    if (this.page_id) {
      this.route.queryParamMap.subscribe(params => {
        if (params.has('preview')) {
          this.isPreview = params.get('preview') === 'true' ? true : false;
        }
      });

      this.route.data.subscribe(data => {
        if (data.editRole) {
          this.editRole = data.editRole;
          this.editAccess = this.editAccess || this.loginService.hasFullAccess(data.editRole);
        }
      });
      this.getContent(this.page_id);
    } else {
      this.route.queryParamMap.subscribe(params => {
        if (params.has('preview')) {
          this.isPreview = params.get('preview') === 'true' ? true : false;
        }
        if (params.has('page_id')) {
          this.page_id = params.get('page_id');
          this.getContent(params.get('page_id'));
        }
      });

      this.route.data.subscribe(data => {
        if (data.editRole) {
          this.editRole = data.editRole;
          this.editAccess = this.editAccess || this.loginService.hasFullAccess(data.editRole);
        }

        if (data.id) {
          this.page_id = data.id;
          this.getContent(data.id);
        }
      });

      this.route.paramMap.subscribe(params => {
        if (params.has('id')) {
          this.page_id = params.get('id');
          this.getContent(params.get('id'));
        }
      });
    }

    setTimeout(() => {
      if (!this.page_id) {
        this.router.navigate(['/']);
      }
    });
  }

  getContent(id) {
    this.content = '';
    this.loaded = false;
    if (!this.isPreview) {
      this.cmsService.getPage(id)
        .subscribe(response => {
          try {
            this.renderPageContent(response['page']);
            // load cms content
            this.getContentCMS(id);
            this.loaded = true;
          } catch (e) {
            console.error(e);
            this.getContentCMS(id);
          }
        }, () => this.getContentCMS(id)
        );
    } else {
      this.getContentCMS(id);
    }
  }

  getContentCMS(id) {
    const filter = [{ key: 'preview', values: [this.isPreview.toString()] }];
    if (this.editRole) {
      filter.push({ key: 'role', values: [this.editRole] })
    }
    if (isNaN(id)) {
      filter.push({ key: 'type', values: ['slug'] })
    }
    const params = this.filterService.getApiParams(filter);
    this.cmsService.getPageCMS(id, params)
      .subscribe(response => {
        try {
          if (this.page || response['page']) {
            this.renderPageContent(response['page'], response['current_status']);
            this.loaded = true;
          } else {
            this.router.navigate([this.url]);
          }
        } catch (e) {
          this.router.navigate([this.url]);
        }
      }, () => { this.loaded = true; });
  }

  renderPageContent(page, currentStatus?) {
    if (page) {
      if (!this.page || (this.page && (this.page['version_id'] !== page['version_id'] || this.page['content']['rendered'] !== page['content']['rendered']))) {
        this.page = page;
        this.content = this.page['content']['rendered'];
        this.titleService.setTitle(this.title ? this.title : decode(this.page['title']['rendered']));
        this.breadcrumbsService.set('/newsletters/:id', this.title ? this.title : decode(this.page['title']['rendered']));

        const token = localStorage.getItem('cmsToken');
        if (!this.jwtHelper.isTokenExpired(token)) {
          this.editUrl = environment.cmsUrl + `wp-admin/post.php?post=${this.page['id']}&action=edit&t=${token}`;
        }
        if (!this.introjsService.displayTour) {
          this.route.fragment.subscribe(fragment => {
            const all = this.element.nativeElement.querySelectorAll('.mailing-card .target');
            all.forEach(ele => ele.classList.remove('target'));
            if (fragment) {
              setTimeout(() => {
                const element = this.element.nativeElement.querySelector('#' + fragment);
                this.commonService.scrollTo(element);
                element.classList.add("target");
              }, 500);
            } else {
              window.scrollTo(0, 0);
            }
          })
        };

        this.createAccordion();
        this.createTooltip();
      }
    }

    if (currentStatus && currentStatus.length > 0) {
      if (currentStatus === 'private' || currentStatus === 'publish') {
        this.showPreview = false;
      } else {
        this.showPreview = true;
      }
    }
  }

  createAccordion() {
    setTimeout(() => {
      if (document.querySelector('#cms-dom')) {
        const shadowDomParent = document.querySelector('#cms-dom').shadowRoot;
        const acc = shadowDomParent.querySelectorAll('.wpsm_panel-heading');
        for (let i = 0; i < acc.length; i++) {
          acc[i].addEventListener('click', function (e) {
            this.classList.toggle('acc-a');
            const link = this.getElementsByTagName('a')[0];
            link.removeAttribute('href');
            const panel = this.nextElementSibling;
            if (panel.classList.contains('in')) {
              link.querySelector('.fa-minus').className = 'ac_open_cl_icon fa fa-plus';
              panel.className = 'wpsm_panel-collapse collapse';
            } else {
              const contents = this.parentElement.parentElement.querySelectorAll('.in');
              for (let j = 0; j < contents.length; j++) {
                contents[j].className = 'wpsm_panel-collapse collapse';
              }
              const links = this.parentElement.parentElement.querySelectorAll('.fa-minus');
              for (let j = 0; j < links.length; j++) {
                links[j].className = 'ac_open_cl_icon fa fa-plus';
              }
              link.querySelector('.fa-plus').className = 'ac_open_cl_icon fa fa-minus';
              panel.className = 'wpsm_panel-collapse collapse in';
            }
          });
        }
      }
    }, 10);
  }

  closeTooltip(t, element) {
    let x = 0;
    let y = 0;
    const onMouseMove = (e) => {
      x = e.x;
      y = e.y;
    }
    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('mouseenter', onMouseMove, false);
    try {
      setTimeout(() => {
        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseenter', onMouseMove, false);
        if (!document.elementFromPoint(x, y).closest(element)) {
          (<any>$(t)).tooltip('hide')
        }
      }, 100);
    } catch (e) {
      console.error(e);
    }
  }

  createTooltip() {
    setTimeout(() => {
      if (document.querySelector('#cms-dom')) {
        const shadowDomParent = document.querySelector('#cms-dom').shadowRoot;
        const tooltipJquery = <any>$(shadowDomParent.querySelectorAll("[data-bs-toggle='tooltip']"));
        tooltipJquery.tooltip();

        const tooltips = shadowDomParent.querySelectorAll("[data-bs-toggle='tooltip']");
        for (let i = 0; i < tooltips.length; i++) {
          const comp = this;
          tooltips[i].addEventListener('mouseover', function (e) {
            (<any>$(this)).tooltip('show'); //show tooltip

            // listen tooltip creation event
            $(this).on('shown.bs.tooltip', () => {
              const tooltip = document.querySelectorAll(".tooltip");
              for (let j = 0; j < tooltip.length; j++) {
                //add eventlistner on tooltip 
                tooltip[j].addEventListener('mouseout', (e) => {
                  comp.closeTooltip(this, '.p-card-tooltip')
                })

                // add inner tooltip
                const innerTooltipJquery = <any>$(tooltip[j].querySelectorAll(".cms-tooltip"));
                for (let k = 0; k < innerTooltipJquery.length; k++) {
                  $(innerTooltipJquery[k]).on({
                    'mouseenter': function () {
                      (<any>$(this))
                        .tooltip({
                          html: true,
                          // trigger: 'hover',
                          title: function () {
                            return this.nextElementSibling.innerHTML;
                          }
                        })
                      // .tooltip('show');
                    },
                    'mouseleave': function () {
                      // (<any>$(this)).tooltip('hide');
                    }
                  });
                }
              }


            })
          });
          tooltips[i].addEventListener('mouseout', function (e) {
            comp.closeTooltip(this, '.p-card-tooltip')
          });
        }
      }
    }, 10);
  }

  ngOnDestroy() {
    this.breadcrumbsService.set('/newsletters/:id', 'Newsletter');
  }
}
