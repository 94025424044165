import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-industries-chip',
  templateUrl: './industries-chip.component.html',
  styleUrls: ['./industries-chip.component.css']
})
export class IndustriesChipComponent implements OnInit, OnChanges {
  @Input() industries;
  @Input() data;
  @Input() displayLength = 100;
  industriesList = [];
  displayNumber = 0;
  moreSegments = 0;

  constructor(private modalService: ModalPopupService) { }

  ngOnInit(): void {
    if (this.data) {
      this.industriesList = this.data;
      this.displayLength = 100000;
      this.getDisplayNumber();
    }
  }

  ngOnChanges(change?) {
    if (change?.industries) {
      if (change.industries.currentValue) {
        this.industriesList = change.industries.currentValue
          .flatMap(i => i.industry_segments.map(s => ({ id: s.id, name: s.name, groupBy: i.name })));
      }
    }
    this.getDisplayNumber();
  }

  getDisplayNumber() {
    // get no of industries display
    let industriesName = '';
    for (const segment of this.industriesList) {
      industriesName += segment['name'] + ' ';
      if (industriesName.length > (this.displayLength - 6)) {
        break;
      }
      industriesName += ' ';
      this.displayNumber++;
    }
    // check is only one segment left and it can be display
    if (this.industriesList.length === (this.displayNumber + 1)) {
      if (industriesName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }
    this.moreSegments = this.industriesList.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Industry Segments`, message: '', component: 'IndustriesChipComponent', data: this.industriesList };
    this.modalService.open(data);
  }
}
