import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-list-loader',
  templateUrl: './customer-list-loader.component.html',
  styleUrls: ['./customer-list-loader.component.css']
})
export class CustomerListLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
