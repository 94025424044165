import { Component, OnInit, Input } from '@angular/core';
import { PeopleService } from 'app/services/people/people.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-practice-head',
  templateUrl: './practice-head.component.html',
  styleUrls: ['./practice-head.component.css']
})
export class PracticeHeadComponent implements OnInit {
  @Input() headInfo;

  constructor(public peopleService: PeopleService, private commonService: CommonMethodsService) { }

  ngOnInit() {
    this.peopleService.getPersonInfo(this.headInfo.login)
      .subscribe(response => this.headInfo = {
        ...response['person'], type: this.headInfo['type'], label: this.headInfo['label'],
        glo_url: this.headInfo['glo_url'], linkedin: this.headInfo['linkedin']
      });
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}
