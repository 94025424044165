import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { CustomerActivityService } from 'app/services/customer-activities/customer-activity.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PeopleService } from 'app/services/people/people.service';
import { LoginService } from 'app/services/auth/login.service';

@Component({
  selector: 'app-activity-artifact',
  templateUrl: './activity-artifact.component.html',
  styleUrls: ['./activity-artifact.component.css']
})
export class ActivityArtifactComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() value = [];
  @Input() controlName = '';
  @Input() optional = false;

  artifactForm: UntypedFormGroup;
  artifactFileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'rtf', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'xls', 'xlsx'];
  artifactMimeTypes = `image/png,image/jpeg,image/jpg,application/pdf,application/vnd.google-apps.document,application/msword,` +
    `application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,application/vnd.ms-powerpoint,` +
    `application/vnd.google-apps.presentation,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,` +
    'application/vnd.google-apps.spreadsheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  artifactType = [];
  selectedArtifacts = [];
  touched = false;
  // artifactFolerId = environment.customerActivityPublicFolderId;

  constructor(private fb: UntypedFormBuilder, private customerActivityService: CustomerActivityService, private loginService: LoginService,
    private changeDetectorRef: ChangeDetectorRef, private commonService: CommonMethodsService, private peopleService: PeopleService) { }

  ngOnInit() {
    if (this.value) {
      this.selectedArtifacts = this.value.map(file => ({
        fileName: file['title'],
        id: this.commonService.getDriveFileIdFromUrl(file['url']),
        owner: (file['owner'] ? {
          id: file['owner'].person_id.toString(),
          login: file['owner'].login,
          text: this.peopleService.getPersonName(file['owner']),
          title: this.peopleService.getPersonTitle(file['owner']),
          email: file['owner'].contact.email_id,
          photo_url: file['owner'].photo_url
        } : {
          id: 'horizontal-practices@globallogic.com',
          login: '',
          text: 'Horizontal Practices',
          title: '',
          email: 'horizontal-practices@globallogic.com',
          photo_url: ''
        }),
        is_confidential: file['is_confidential'],
        type: (file['type'] ? file['type']['id'] : 1),
        url: file['url']
      }));
      this.form.get(this.controlName).setValue(this.selectedArtifacts);
    }

    this.getArtifactTypes();
    this.artifactForm = this.fb.group({
      type: this.fb.control('', []),
      document: this.fb.control(null, []),
      confidential: this.fb.control({ value: false, disabled: true }, []),
      owner: this.fb.control(null, []),
    });

    this.artifactForm.get('type').valueChanges
      .subscribe(value => {
        // this.updateFolderId();
        if (value === '2') {
          this.artifactForm.get('confidential').disable();
          this.artifactForm.get('confidential').setValue(true);
        } else {
          this.artifactForm.get('confidential').enable();
          this.artifactForm.get('confidential').setValue(false);
        }
      });

    this.artifactForm.get('confidential').valueChanges
      .subscribe(value => {
        // if (!value) {
        this.artifactForm.get('owner').setValue([this.setDefaultOwner()]);
        // } else {
        //   this.artifactForm.get('owner').setValue([this.setDefaultOwner()]);
        // }
        // this.updateFolderId();
      });

    this.artifactForm.get('document').valueChanges
      .subscribe(value => {
        if (value) {
          const type = this.artifactForm.get('type').value;
          const confidential = type === '2' ? true : this.artifactForm.get('confidential').value;
          const owner = this.artifactForm.get('owner').value && this.artifactForm.get('owner').value.length ?
            this.artifactForm.get('owner').value[0] : this.setDefaultOwner();

          this.selectedArtifacts.push({ owner: owner, is_confidential: confidential, type: type, ...value });
          this.form.get(this.controlName).setValue(this.selectedArtifacts);
          this.resetForm();
        }
      });

    this.artifactForm.valueChanges
      .subscribe(() => {
        if (this.artifactForm.dirty && this.form.pristine) {
          this.form.markAsDirty();
        }
      });
  }

  setDefaultOwner() {
    const currentUser = this.loginService.currentUser;

    if (currentUser['person']['login']) {
      return {
        id: currentUser['person'].person_id.toString(),
        login: currentUser['person'].login,
        text: this.peopleService.getPersonName(currentUser['person']),
        title: this.peopleService.getPersonTitle(currentUser['person']),
        email: currentUser['person'].contact.email_id,
        photo_url: currentUser['person'].photo_url
      };
    }
    return {
      id: 'horizontal-practices@globallogic.com',
      login: '',
      text: 'Horizontal Practices',
      title: '',
      email: 'horizontal-practices@globallogic.com',
      photo_url: ''
    };
  }

  getArtifactTypes() {
    this.customerActivityService.getArtifactTypes()
      .subscribe(response => {
        this.artifactType = response['rows'];
      });
  }

  resetForm() {
    setTimeout(() => {
      this.artifactForm.get('type').setValue('');
      this.artifactForm.get('document').setValue(null);
      this.artifactForm.get('confidential').setValue(false);
      this.artifactForm.get('owner').setValue(null);
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  // updateFolderId() {
  //   if (this.artifactForm.get('type').value === '2') {
  //     this.artifactFolerId = environment.advisoryFolderId;
  //   } else if (this.artifactForm.get('confidential').value) {
  //     this.artifactFolerId = environment.customerActivityConfidentialFolderId;
  //   } else {
  //     this.artifactFolerId = environment.customerActivityPublicFolderId;
  //   }
  // }

  getArtifactType(typeId) {
    switch (+typeId) {
      case 1: return 'Presentation';
      case 2: return 'Advisory';
      case 3: return 'Other';
    }
  }

  remove(event, id) {
    event.preventDefault();
    this.touched = true;
    this.selectedArtifacts = this.form.get(this.controlName).value.filter(artifact => artifact.id !== id);
    this.form.get(this.controlName).setValue(this.selectedArtifacts);
  }
}
