<!-- White Conatiner -->
<div class="white-container">
  <!-- Form Container -->
  <div class="form-container">
    <form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
      <!-- name -->
      <div class="form-group"
        [ngClass]="{'validate':form.get('name').invalid && (form.get('name').touched || invalidForm)}">
        <label>Customer or Prospect Name</label>
        <input type="text" class="form-control" i18n-placeholder placeholder="Enter name" formControlName="name"
          readonly>
        <div class="invalid" *ngIf="form.get('name').invalid && (form.get('name').touched || invalidForm)">
          <div *ngIf="form.get('name').errors.whitespace">
            Enter name
          </div>
          <div *ngIf="form.get('name').errors.maxlength">
            Name should be less than {{form.get('name').errors.maxlength.requiredLength}} characters
          </div>
        </div>
      </div>

      <!-- website -->
      <div class="form-group"
        [ngClass]="{'validate':(form.get('website').invalid) && (form.get('website').touched || invalidForm)}">
        <label>Website<span class="help">(Optional)</span></label>
        <input type="text" class="form-control" i18n-placeholder placeholder="Enter url" formControlName="website">
        <div class="invalid" *ngIf="(form.get('website').invalid) && (form.get('website').touched || invalidForm)">
          <div *ngIf="form.get('website').errors.whitespace">
            Enter url
          </div>
          <div *ngIf="form.get('website').errors.url && !form.get('website').errors.whitespace">
            Enter a valid url
          </div>
          <div *ngIf="form.get('website').errors.maxlength">
            Url should be less than {{form.get('website').errors.maxlength.requiredLength}}
            characters
          </div>
        </div>
      </div>

      <!-- logo -->
      <app-file-picker [form]="form" controlName="logo" i18n-label label="Upload Logo" [fileTypes]="logoFileTypes"
        [mimeTypes]="logoMimeTypes" [submitted]="invalidForm" parentUrl="/my-documents" nuggetType="documents"
        googleSelect="true" helpText="" [extension]="extension">
      </app-file-picker>

      <!-- Description -->
      <div class="form-group" id="descriptionGroup" [ngClass]="{'validate':form.get('description').invalid && (form.get('description').touched || invalidForm) || 
          (spellCheckService.errorData && spellCheckService.errorData['description'])}">
        <label>Description</label>
        <textarea class="remarks-field" formControlName="description"></textarea>
        <div class="invalid"
          *ngIf="form.get('description').invalid && (form.get('description').touched || invalidForm)">
          <div *ngIf="form.get('description').errors.whitespace">
            Enter description
          </div>
          <div *ngIf="form.get('description').errors.minlength">
            Description should be more than {{form.get('description').errors.minlength.requiredLength}} characters
          </div>
          <div *ngIf="form.get('description').errors.maxlength">
            Description should be less than {{form.get('description').errors.maxlength.requiredLength}} characters
          </div>
        </div>
        <div class="invalid" *ngIf="spellCheckService.errorData && spellCheckService.errorData['description']">
          {{spellCheckService.errorData['description']['error']}}
        </div>
      </div>
      <!-- spell errors -->
      <div class="form-group" *ngIf="spellCheckService.spellError">
        <div class="alert alert-info">
          Misspelled words in:
          <ng-container
            *ngFor="let error of spellCheckService.errorData | keyvalue | orderBy:'value.order' ;let l = last">
            <a pageScroll [pageScrollDuration]="500" [pageScrollOffset]="140" href="#{{error['value']['id']}}"
              class="spell-error-link">{{error['value']['label']}}</a>{{(!l ) ? ', ':''}}
          </ng-container>. Fix
          spellings or click on below button to continue.
        </div>
      </div>

      <!-- http errors -->
      <div class="form-group" *ngIf="httpError">
        <div class="invalid" *ngFor="let error of httpError">
          {{error.message}}
        </div>
      </div>

      <!-- submit botton-->
      <button type="submit" class="btn btn-primary" [disabled]="(submitted)"
        [ngClass]="{'submitted':submitted}">{{submitBtnText | uppercase}}</button>
      <span class="btn btn-light ml-5" (click)="cancel()">Cancel</span>
    </form>
  </div>
  <!-- End of Form Conatiner -->
</div>
<!-- End of White Conatiner -->