import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LikeService } from 'app/services/like/like.service';
import { FilterService } from 'app/services/filter/filter.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { LoginService } from 'app/services/auth/login.service';

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.css']
})
export class LikeComponent implements OnInit, OnChanges {
  @Input() entityId;
  @Input() entityCategoryId;
  @Input() likeCount = 0;
  liked = false;
  likes;
  submitted = false;
  isPerson = this.loginService.isPerson();

  constructor(private likeService: LikeService, private filterService: FilterService, private modalService: ModalPopupService,
    private loginService: LoginService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getLikes();
  }

  like() {
    if (!this.submitted) {
      this.submitted = true;
      const data = { entity_category_id: this.entityCategoryId };
      this.likeService.like(this.entityId, data)
        .subscribe(response => {
          this.liked = response['like'];
          if (this.liked) {
            this.likeCount += 1;
          } else {
            this.likeCount -= 1;
          }
          this.submitted = false;
          this.getLikes();
        }, () => this.submitted = false);
    }
  }

  getLikes() {
    const filter = [{ key: 'entity_category_id', values: [this.entityCategoryId] }, { key: 'perPage', values: [0] }];
    const params = this.filterService.getApiParams(filter);
    this.likeService.getLikes(this.entityId, params)
      .subscribe(response => {
        this.likes = response['likes'];
        this.likeCount = response['total_count'];
        this.liked = this.likes.filter(l => l.person.login === this.loginService.currentUser.person.login).length > 0;
      });
  }

  open() {
    const data = {
      title: 'Liked By', message: '', component: 'LikedByComponent', size: 'sm',
      data: { id: this.entityId, cid: this.entityCategoryId, likes: this.likes }
    };
    this.modalService.open(data);
  }
}
