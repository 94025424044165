import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Cacheable } from 'ts-cacheable';
import { ModalPopupService } from '../util/modal-popup.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethodsService } from '../util/common-methods.service';
import { TitleCasePipe } from '@angular/common';
import { PocsService } from '../pocs/pocs.service';
import { TechTalksService } from '../tech-talks/tech-talks.service';
import { TechDocumentsService } from '../tech-documents/tech-documents.service';
import { CaseStudiesService } from '../case-studies/case-studies.service';
import { Router } from '@angular/router';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class NuggetsService {
  apiUrl = environment.apiUrl;
  deleteNuggetEvent = new EventEmitter();
  deleteNuggetConfirmEvent = new EventEmitter();
  assignReviewerEvent = new EventEmitter();
  reportNuggetEvent = new EventEmitter();

  constructor(private http: HttpClient, private commonService: CommonMethodsService, private modalService: ModalPopupService,
    private toastr: ToastrService, private titleCase: TitleCasePipe, private pocService: PocsService,
    private techTalkService: TechTalksService, private techDocService: TechDocumentsService, private router: Router,
    private caseStudyService: CaseStudiesService, private loginService: LoginService) { }

  @Cacheable()
  getNuggetsSummary() {
    return this.http.get(this.apiUrl + '/v1/nuggets/summary');
  }

  getNuggetType() {
    return this.http.get(this.apiUrl + '/v1/entity-categories');
  }

  getNuggets(params?) {
    return this.http.get(this.apiUrl + '/v1/nuggets', { params });
  }

  getNuggetContribution(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/nuggets/contribution', { params, headers });
  }

  getTrendingtNugget() {
    return this.http.get(this.apiUrl + '/v1/nuggets/trending');
  }

  getFlags(id, params) {
    return this.http.get(this.apiUrl + `/v1/nuggets/${id}/flags`, { params });
  }

  submitFlag(id, data) {
    return this.http.post(this.apiUrl + `/v1/nuggets/${id}/flags`, data);
  }

  deleteFlag(id, fid, data) {
    return this.http.request('delete', this.apiUrl + `/v1/nuggets/${id}/flags/${fid}`, { body: data });
  }

  resolveFlag(id, fid, data) {
    return this.http.post(this.apiUrl + `/v1/nuggets/${id}/flags/${fid}/resolve`, data);
  }

  deleteNuggets(id, title, type, showLoader = false) {
    const data = {
      title: $localize`Delete ${this.titleCase.transform(type)}`, message: $localize`Are you sure you want to delete ${title} ${type}?`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = showLoader;
          this.deleteNuggetConfirmEvent.emit(type);
          this.getDeteteApi(type, id)
            .subscribe(response => {
              this.toastr.success(response['message']);
              setTimeout(() => {
                this.deleteNuggetEvent.emit(type);
              }, 1000);
            }, error => {
              this.toastr.error(error['error']['error']);
              this.deleteNuggetEvent.emit(type);
            });
        }
      })
      .catch(() => { });
  }

  getDeteteApi(type, id) {
    switch (type) {
      case 'poc':
        return this.pocService.delete(id);
      case 'tech talk':
        return this.techTalkService.delete(id);
      case 'tech document':
        return this.techDocService.delete(id);
      case 'case study':
        return this.caseStudyService.delete(id);
    }
  }

  canDelete(data) {
    let practices = [];
    if (data.practices && data.practices.length > 0) {
      practices = data.practices.map(p => p.id);
    }

    let locations = [];
    if (data.locations && data.locations.length > 0) {
      locations = data.locations.map(location => location.city);
    } else if (data.location) {
      locations = [data.location.city];
    }
    return (this.loginService.isPrivilegedUser(practices) || this.loginService.isCenterCoordinator(locations, practices));
  }

  assignTo(id, data) {
    return this.http.post(this.apiUrl + `/v1/nuggets/${id}/reviewer`, data);
  }

  deleteAssignee(id: number, options) {
    return this.http.delete(this.apiUrl + `/v1/nuggets/${id}/reviewer`, options);
  }

  checkNuggetUrls(params) {
    return this.http.get(this.apiUrl + '/v1/check-nugget-urls', { params });
  }
}
