import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PracticesService } from 'app/services/practices/practices.service';
import { FilterService } from 'app/services/filter/filter.service';

@Component({
  selector: 'app-practice-options-new',
  templateUrl: './practice-options-new.component.html',
  styleUrls: ['./practice-options-new.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PracticeOptionsNewComponent
    }
  ]
})
export class PracticeOptionsNewComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() multiple = false;
  @Input() secondary = false;
  @Input() helpText = '';
  @Input() optional = false;
  @Input() filterPractices = [];
  @Input() hasCommunity = false;
  label = $localize`Practice`
  placeholder = $localize`Select practice`;
  htmlId = 'practice-options';
  practices = [];
  options: any = [];
  selectedPractices = [];
  showSelectedPractices = [];

  onChange: any = () => {
    // on change function
  }
  onTouched = () => { this.touched = true; }

  disabled = false;
  touched = false;

  constructor(private practiceService: PracticesService, private filterService: FilterService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.createOptions();
    this.options = this.options.filter(option => !this.filterPractices.includes(option.id))
    this.showSelectedPractices = this.options.filter(option => this.selectedPractices.includes(option.id))
  }

  ngOnInit(): void {
    this.updateLabelAndPlaceholder();
    this.getPractices();
  }

  updateLabelAndPlaceholder() {
    if (this.secondary && this.multiple) {
      this.label = 'Secondary Practices';
      this.placeholder = 'Select secondary practices';
      this.htmlId = 'secondary-practices-options';
    } else if (this.secondary) {
      this.label = 'Secondary Practice';
      this.placeholder = 'Select secondary practice';
      this.htmlId = 'secondary-practice-options';
    } else if (this.multiple) {
      this.label = 'Practices';
      this.placeholder = 'Select practices';
      this.htmlId = 'practices-options';
    }
  }

  getPractices() {
    let params;
    if (this.hasCommunity) {
      const filter = [{ key: 'filters[has_community]', values: [this.hasCommunity] }];
      params = this.filterService.getApiParams(filter);
    }
    this.practiceService.getPractices(params).subscribe({
      next: (response: any) => {
        this.practices = response['rows'];
        this.createOptions();
      },
    });
  }

  createOptions() {
    this.options = this.practices.map(practice => {
      return {
        id: practice.id,
        label: practice.name,
        parent: practice.type.name == 'Vertical' ? "Industries" : "Practices",
      };
    });
    if (this.multiple) {
      this.options = this.options.filter(option => !this.filterPractices.includes(option.id))
      if (this.selectedPractices.length) {
        this.showSelectedPractices = this.options.filter(option => this.selectedPractices.includes(option.id))
      }
    }
  }

  valueChange(value: any) {
    if (this.multiple) {
      this.onChange(this.selectedPractices);
      this.showSelectedPractices = this.options.filter(option => this.selectedPractices.includes(option.id))
      if (this.secondary) {
        this.practiceService.selectVerticalPracticeEvent.emit(value);
      }
    } else {
      this.onChange([this.selectedPractices]);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  writeValue(value: any): void {
    if (this.multiple) {
      this.selectedPractices = value;
      if (this.secondary) {
        this.practiceService.selectVerticalPracticeEvent.emit(this.options.filter(option => option.id == value));
      }
    } else {
      this.selectedPractices = value ? value[0] : [];
    }
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  remove(id: number) {
    if (this.selectedPractices.length) {
      this.selectedPractices = this.selectedPractices.filter(p => p !== id);
      this.valueChange(this.options.filter(option => this.selectedPractices.includes(option.id)))
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
