<label for="file-upload" *ngIf="label">{{label}} <span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<div class="custom-file"
  [ngClass]="{'validate':(form?.get(controlName).invalid && (touched || submitted)) || invalidFile}">
  <button class="btn btn-border btn-radius" tooltipClass='document-tooltip' [ngbTooltip]="extension || ''"
    container="body" type="button" (click)="openPicker()" *ngIf="driveAccess; else disable">
    <img class="img-14" src="assets/images/icon-gdrive.png">
    &nbsp;<ng-container i18n>Select from Drive</ng-container></button>

  <ng-template #disable>
    <button class="btn btn-border btn-radius btn-disabled" tooltipClass='document-tooltip' [ngbTooltip]="cookie"
      triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
      (mouseleave)="onMouseleave($event,t)" container="body" type="button">
      <img class="img-14" src="assets/images/icon-gdrive-gray.png">
      &nbsp;<ng-container i18n>Select from Drive</ng-container></button>

    <ng-template #cookie>
      <div (mouseleave)="t.close()" i18n>Your browser cookies are disabled. Please fix your browser settings to enable
        this button. Click <a class="btn-link" href="javascript:;" (click)="openPopup()">here</a> to know more.</div>
    </ng-template>
  </ng-template>
</div>

<div *ngIf="(form?.get(controlName).invalid && (touched || submitted))" class="invalid">
  <div *ngIf="(!pickerApiLoaded) && touched" i18n>Unable to load the file picker</div>
  <div *ngIf="invalidFile" i18n>Select a valid file</div>
</div>
<div #forClickGooglePicker hidden></div>
<div class="invalid" *ngIf="errorMessage">{{errorMessage}}</div>