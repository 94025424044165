import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as lodash from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class SpellCheckService {
  apiUrl = environment.apiUrl;
  spellChecked = false;
  spellError = false;
  errorData = {};
  constructor(private http: HttpClient) { }

  checkSpelling(data) {
    return this.http.post(this.apiUrl + '/v1/spell-checker', data);
  }

  uniqueErrors(errors) {
    const error = lodash.uniq(errors).join(', ');
    if (error.length) {
      return $localize`Misspelled words: ${error}`;
    }
    return '';
  }

  reset() {
    this.spellChecked = false;
    this.spellError = false;
    this.errorData = {};
  }
}
