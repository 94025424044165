<label>{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<div class="form-row" [formGroup]="form">
  <div class="col-md-3">
    <select class="form-select" formControlName="entity_type">
      <option value="" i18n>Nugget Type</option>
      <option [value]="type['id']" *ngFor="let type of nuggetTypes">{{type['name']}}</option>
    </select>
  </div>
  <div class="col-md-9 md-mt-10 add-nugget-reference">
    <ng-select2 width="100%" [options]="options" (valueChanged)="valueChanged($event)" class="custom-select-field"
      [id]="controlName" [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}" [data]="data"
      *ngIf="options;else search;" [disabled]="!form.get('entity_type').value">
    </ng-select2>
    <ng-template #search>
      <input type="text" class="form-control" i18n-placeholder placeholder="Select nugget">
    </ng-template>
    <div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
      <div *ngIf="form.get(controlName).errors.required">
        {{placeholder}}
      </div>
    </div>
  </div>
</div>

<div class="nuggets-references">
  <div class="reference" *ngFor="let nugget of selectedNuggets">
    <a [routerLink]="['/' + nugget.uri, nugget.entity_id]" target="_blank">
      <div class="reference-type"><i class="fas fa-circle"
          [ngClass]="nugget.nugget_type | class"></i>{{nugget.nugget_type}}</div>
      <label class="reference-label">{{nugget.title | truncate:60}}</label>
    </a>
    <div class="remove">
      <a href="javascript:;" class="circle-actions" (click)="remove(nugget.entity_id)"><i class="fas fa-times"></i></a>
    </div>
  </div>
</div>