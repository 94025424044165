import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CloudPickerService } from 'app/services/cloud-picker/cloud-picker.service';
import { DocumentsService } from 'app/services/documents/documents.service';
import { DriveService } from 'app/services/drive/drive.service';

@Component({
  selector: 'app-embed-document',
  templateUrl: './embed-document.component.html',
  styleUrls: ['./embed-document.component.css']
})
export class EmbedDocumentComponent implements OnInit {
  document;
  url = '';
  viewerType;
  docHeight = '540px';

  constructor(private documentService: DocumentsService, private cloudPickerService: CloudPickerService,
    private driveService: DriveService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data.id) {
        this.getDocument(data.id);
      }
    });
  }

  getDocument(id) {
    this.viewerType = '';
    this.documentService.getDocument(+id)
      .subscribe(response => {
        this.document = response['document'];
        this.cloudPickerService.getSignedUrl(this.document.url).subscribe(signedUrl => {
          this.cloudPickerService.getMetaData(this.document.url).subscribe(metaData => {
            this.viewerType = this.cloudPickerService.getFileType(metaData['mime_type']);
            this.docHeight = this.driveService.getDocHeight(metaData['mime_type']);
            this.url = signedUrl['signed_url'];
          });
        })
      }, () => this.router.navigate(['/admin']));
  }
}
