<div class="deck-card" (mouseenter)="showPreviewIcon=true" (mouseleave)="showPreviewIcon=false" [ngClass]="classes">
  <a [routerLink]="[url,data.id]" [queryParams]="queryParams">
    <div class="status-chip"
      *ngIf="data.status && data.status.name === 'Pending Approval' && (type === 'tech-doc' || type === 'tech-talk')">
      {{data.status.name}}
    </div>
    <div class="card" [ngStyle]="{backgroundImage: 'url(' + data.preview_url + ')'}">
      <div class="language-chip" *ngIf="data.language?.language">{{data.language.language | slice:0:3}}</div>
      <div class="card-top-actions">
        <ng-container *ngIf="!data.readOnly">
          <app-change-nugget-preview [practice]="data.practices[0]" [entityId]="data.id"
            [entityCategory]="data.search_type" [previewUrl]="data.preview_url"
            *ngIf="previewAccess && isAccess && showPreviewIcon">
          </app-change-nugget-preview>
          <app-create-collection [entityId]="data.id" [entityCategory]="entityCategoryid"
            *ngIf="showPreviewIcon"></app-create-collection>
        </ng-container>
        <a (click)="removeNugget($event, data.id, entityCategoryid)" class="circle-actions"
        *ngIf="data.removeCollection && showPreviewIcon" i18n-ngbTooltip i18n-ngbTooltip
        ngbTooltip="Remove from My Collection" container="body"><i class="fa-solid fa-xmark"></i></a>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-10">
        <app-members-chip [members]="data.authors" [displayNumber]="displayNumber" class="d-inline-block">
        </app-members-chip>
        <img src="assets/images/card-education-logo.png" class="d-inline-block float-right gl-edu-card"
          *ngIf="isGloballogicEducation">
      </div>
      <div class="title truncate-2">
        <span [title]="data['title']">
          {{data.title}}
        </span>
      </div>
      <div class="type">
        {{data.date | date:'mediumDate':'GMT'}}
        <ng-container *ngIf="types">
          | {{types | truncate:30}}
        </ng-container>
        <ng-container *ngIf="data.status && type === 'poc'">
          | {{data.status.name}}
        </ng-container>
      </div>
      <ng-container *ngIf="displayPractices">
        <div class="divider divider-1"></div>
        <app-practices-chip [practices]="data.practices"></app-practices-chip>
      </ng-container>
    </div>
  </a>
</div>