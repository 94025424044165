<!-- 
  all cookies - cookieEnabled
  third party cookie - thirdPartyCookieEnabled
  cross site tracking cookie - driveAccess
 -->
<!-- <ng-container *ngIf="cookieChecked && (!thirdPartyCookieEnabled || !driveAccess && loginService.currentUser)"> -->
<!-- <div class="tracking-cookie-warning" *ngIf="!isWarningClosed && !loginService.currentUser && isLoggedOut">
    <i class="fas fa-exclamation-triangle"></i>
    <div class="cookie-message">
      <ng-container *ngIf="cookieEnabled; else allCookie">
        Your browser cookies are disabled. Please fix your browser settings to login into the practice portal. Click <a
          href="javascript:;" class="btn-link" (click)="loginService.cookieAlertClosed=false;isWarningClosed=true">
          here</a> to know more.
      </ng-container>

      <ng-template #allCookie>
        Your browser cookies are disabled, this can impact several practices portal features like login, submit nuggets
        and UI experience. Please fix your browser settings. Click <a href="javascript:;" class="btn-link"
          (click)="loginService.cookieAlertClosed=false;isWarningClosed=true">
          here</a> to know more.
      </ng-template>
    </div>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isWarningClosed=true">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

<div class="tracking-cookie-alert-wrapper" *ngIf="!loginService.cookieAlertClosed">
  <div class="tracking-cookie-alert">
    <div class="tracking-cookie-content">
      <div [perfectScrollbar]="config">
        <h1>Fix your {{browser}} settings</h1>
        Please follow below steps to fix your {{browser}} settings:
        <ng-container [ngSwitch]="browser">
          <ng-container *ngSwitchCase="'Edge'">
            <ol>
              <li>Go to Edge Settings</li>
              <li>Go to Cookies and site permissions</li>
              <li>Click on Manage and delete cookies and site data</li>
              <li>Click on Add in Allow section</li>
              <li>Enter {{siteUrl}} in site and check Include third-party cookies on this site </li>
              <li>Refresh page after updating cookies setting</li>
            </ol>
            <img src="https://storage.googleapis.com/gl-practice-library/images/edge.gif">
          </ng-container>
          <ng-container *ngSwitchCase="'Firefox'">
            <ol>
              <li>Click on the Shield</li>
              <li>Turn off Enhanced Tracking Protection for this site</li>
              <li>Please refresh page after updating cookies setting</li>
            </ol>
            <img src="https://storage.googleapis.com/gl-practice-library/images/firefox.gif">
          </ng-container>
          <ng-container *ngSwitchCase="'Chrome'">
            <ol>
              <li>Go to Chrome Settings</li>
              <li>Go to Privacy and security</li>
              <li>Click Cookies and other site data</li>
              <li>Go to Sites that can always use cookies and click Add</li>
              <li>Enter {{siteUrl}} in site and check Including third-party cookies on this site
              </li>
              <li>Please refresh page after updating cookies setting</li>
            </ol>
            <img src="https://storage.googleapis.com/gl-practice-library/images/chrome.gif">
          </ng-container>
          <ng-container *ngSwitchCase="'Safari'">
            <ol>
              <li>Go to Safari preferences</li>
              <li>Go to Privacy</li>
              <li>Uncheck Website tracking</li>
              <li>Please refresh page after updating cookies setting</li>
            </ol>
            <img src="https://storage.googleapis.com/gl-practice-library/images/safari.gif">
          </ng-container>
        </ng-container>
      </div>
      <span class="close" (click)="dismiss()">&times;</span>
    </div>
  </div>
</div>
<!-- </ng-container> -->
