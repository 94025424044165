import { LoginService } from '../auth/login.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, CanActivateChild } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(protected router: Router, protected loginService: LoginService) { }

  canActivate(route, state: RouterStateSnapshot) {
    const url = state.url.toString();
    return this.loginService.isLoggedIn(url);
  }

  canActivateChild(route, state: RouterStateSnapshot) {
    const url = state.url.toString();
    return this.loginService.isLoggedIn(url)
  }
}
