import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechRadarService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getTechRadar(params) {
    return this.http.get(this.apiUrl + `/v1/tech-radar`, { params });
  }

  getTechRadarCategories() {
    return this.http.get(this.apiUrl + '/v1/tech-radar/categories');
  }

  getTechRadarInfo(id, params?) {
    return this.http.get(this.apiUrl + `/v1/technology-radars/${id}`, { params });
  }

  getTechRadars(params?) {
    return this.http.get(this.apiUrl + `/v1/technology-radars`, { params });
  }

  getTechRadarsMe(params?) {
    return this.http.get(this.apiUrl + `/v1/technology-radars/me`, { params });
  }

  technologiesAutocomplete(params) {
    return this.http.get(this.apiUrl + '/v1/technology-radars/technologies/autocomplete', { params });
  }

  submitTechRadar(data) {
    return this.http.post(this.apiUrl + '/v1/technology-radars', data);
  }

  updateTechRadar(id, data) {
    return this.http.put(this.apiUrl + `/v1/technology-radars/${id}`, data);
  }

  updateTechRadarStatus(id, data?) {
    return this.http.post(this.apiUrl + `/v1/technology-radars/${id}/status`, data);
  }

  deleteTechRadar(id, data?) {
    return this.http.request('delete', this.apiUrl + `/v1/technology-radars/${id}`,
      { body: data });
  }

  getRadarTypes() {
    return this.http.get(this.apiUrl + `/v1/radar-types`);
  }

  getGLAdoptionLevels() {
    return this.http.get(this.apiUrl + `/v1/radar-adoption-levels`);
  }

  getIndustryTrends() {
    return this.http.get(this.apiUrl + `/v1/radar-industry-trends`);
  }

  getFileData(params?) {
    return this.http.get(this.apiUrl + `/v1/technology-radars/read-file`, { params });
  }

  exportToExcel(id, data?) {
    const headers = new HttpHeaders({
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    return this.http.post(this.apiUrl + `/v1/technology-radars/${id}/export`, data, { headers: headers, responseType: 'blob' });
  }
}

export interface Radars {
  radars: [];
  total_count: number;
}
