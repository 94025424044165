import { Component, OnInit, Input } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-create-collection',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.css']
})
export class CreateCollectionComponent implements OnInit {
  @Input() entityId;
  @Input() entityCategory;

  constructor(private modalService: ModalPopupService,) { }

  ngOnInit(): void {
  }

  open(event) {
    event.preventDefault();
    event.stopPropagation();
    const previewData = { entityId: this.entityId, entityCategory: this.entityCategory };
    const data = { title: $localize`My Collections`, message: '', component: 'AddInCollectionComponent', data: previewData, size: 'sm' };
    this.modalService.open(data);
  }
}
