<ng-container *ngIf="members.length">
  <div class="small-heading mt-40" i18n>Top Recommendations</div>
  <div class="recommendations" [perfectScrollbar]="config" appMatchHeight="row">
    <div class="nugget-item nugget-item-full mb-10" *ngFor="let member of members">
      <app-contributor-card [member]="member"></app-contributor-card>
      <div class="add-to-practice">
        <a routerLink="/add-member" [queryParams]="{p:practiceCName, id:member.person.person_id,channel:'recommend'}"
          class="btn btn-primary btn-sm add-practice-link" appStopPropagation>ADD</a>
      </div>
    </div>
  </div>
</ng-container>
