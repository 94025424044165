import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-locations-chip',
  templateUrl: './locations-chip.component.html',
  styleUrls: ['./locations-chip.component.css']
})
export class LocationsChipComponent implements OnInit, OnChanges {
  @Input() locations;
  @Input() displayLength = 20;
  locationsList;
  displayNumber = 0;
  moreSkills = 0;

  constructor(private modalService: ModalPopupService) { }

  ngOnInit(): void {
    // if (this.data) {
    //   this.locations = this.data;
    //   this.displayLength = 1000;
    //   this.ngOnChanges();
    // }
  }

  ngOnChanges() {
    this.locationsList = this.locations.map(c => c?.city);

    let locationsName = '';
    this.displayNumber = 0;
    for (const location of this.locationsList) {
      locationsName += location + ' ';
      if (locationsName.length > (this.displayLength - 6)) {
        break;
      }
      locationsName += ' ';
      this.displayNumber++;
    }

    if (this.locationsList.length === (this.displayNumber + 1)) {
      if (locationsName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }

    this.moreSkills = this.locationsList.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Locations`, message: '', component: 'LocationsListComponent', data: this.locations, size: 'sm' };
    this.modalService.open(data);
  }
}
