import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-change-carousel-preview',
  templateUrl: './change-carousel-preview.component.html',
  styleUrls: ['./change-carousel-preview.component.css']
})
export class ChangeCarouselPreviewComponent implements OnInit {
  @Input() type;
  @Input() form: UntypedFormGroup;
  @Input() controlName;
  @Input() value;
  @Input() label;
  @Input() entityCategoryId;
  previewData;

  constructor() { }

  ngOnInit(): void {
    this.previewData = { type: this.type, form: this.form, controlName: this.controlName, value: this.value, entityCategoryId: this.entityCategoryId };
  }

}
