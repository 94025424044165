import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { LikeService } from 'app/services/like/like.service';
import { FilterService } from 'app/services/filter/filter.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CommentService } from 'app/services/comment/comment.service';
import { Subscription } from 'rxjs';
import { LoginService } from 'app/services/auth/login.service';

@Component({
  selector: 'app-like-comment-view-count',
  templateUrl: './like-comment-view-count.component.html',
  styleUrls: ['./like-comment-view-count.component.css']
})
export class LikeCommentViewCountComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entityId;
  @Input() entityCategoryId;
  @Input() likeCount = 0;
  @Input() viewCount = 0;
  @Input() uniqueViewCount = 0;
  @Input() showUniqueView = 0;
  @Input() showLike = true;
  @Input() showComment = true;
  liked = false;
  likes;
  visitorLogEvent = new Subscription();
  original;
  submitted = false;
  isPerson = false;

  constructor(private likeService: LikeService, private filterService: FilterService, private modalService: ModalPopupService,
    public commentService: CommentService, private loginService: LoginService) { }

  ngOnInit() {
    this.isPerson = this.loginService.isPerson();
  }

  ngOnChanges() {
    if (this.showLike) {
      if (this.entityId !== this.original) {
        this.original = this.entityId;
        this.getLikes();
      }
    }
  }

  like() {
    if (!this.submitted) {
      this.submitted = true;
      const data = { entity_category_id: this.entityCategoryId };
      this.likeService.like(this.entityId, data)
        .subscribe(response => {
          this.liked = response['like'];
          if (this.liked) {
            this.likeCount += 1;
          } else {
            this.likeCount -= 1;
          }
          this.submitted = false;
          this.getLikes();
        }, () => this.submitted = false);
    }
  }

  getLikes() {
    const filter = [{ key: 'entity_category_id', values: [this.entityCategoryId] }, { key: 'perPage', values: [0] }];
    const params = this.filterService.getApiParams(filter);
    this.likeService.getLikes(this.entityId, params)
      .subscribe(response => {
        this.likes = response['likes'];
        this.likeCount = response['total_count'];
        this.liked = this.likes.filter(l => l.person.login === this.loginService.currentUser.person.login).length > 0;
      });
  }

  open() {
    const data = {
      title: 'Liked By', message: '', component: 'LikedByComponent', size: 'sm',
      data: { id: this.entityId, cid: this.entityCategoryId, likes: this.likes }
    };
    this.modalService.open(data);
  }

  ngOnDestroy() {
    this.visitorLogEvent.unsubscribe();
  }
}
