import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PracticesService } from 'app/services/practices/practices.service';
import * as lodash from 'lodash';
import { FilterService } from 'app/services/filter/filter.service';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-practice-options',
  templateUrl: './practice-options.component.html',
  styleUrls: ['./practice-options.component.css']
})
export class PracticeOptionsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() value = [];
  @Input() controlName = '';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() hasCommunity = false;
  @Input() optional = false;
  @Input() label = $localize`Practice`;
  @Input() primaryPractice;
  placeholder = $localize`Select practice`;
  practices = [{ id: '', text: '' }];
  options: Options;
  selectedPractices = [];
  touched = false;
  practiceList = [];
  loaded = false;
  htmlId = 'practice-options';
  values: string[];

  constructor(private practiceService: PracticesService, private filterService: FilterService) { }

  ngOnInit() {
    if (this.multiple && this.label === 'Practice') {
      this.placeholder = $localize`Select practices`;
      this.label = $localize`Practices`;
    }
    let params;
    if (this.hasCommunity) {
      const filter = [{ key: 'filters[has_community]', values: [this.hasCommunity] }];
      params = this.filterService.getApiParams(filter);
    }
    this.practiceService.getPractices(params)
      .subscribe(response => {
        this.practiceList = response['rows'];
        if(!this.primaryPractice) {
          this.practiceList = this.practiceList.filter(p => p.type.c_name === "horizontal");
        }
        this.setValue();
      });

    this.options = {
      placeholder: this.placeholder,
      multiple: this.multiple,
      minimumResultsForSearch: -1
    };
    if (!this.value) {
      this.value = [];
    }

    if (!this.primaryPractice) {
      this.htmlId += '-primary'
    }
  }

  ngOnChanges(change) {
    // if (change.value) {
    //   if (this.multiple) {
    //     this.values = this.value ? this.value.map(v => v.id.toString()) : null;
    //   } else {
    //     this.values = this.value ? this.value[0]?.id : null;
    //   }
    // }
    if(change.primaryPractice) {
      if (this.primaryPractice?.length) {
        this.setOption();
        this.onSelect({ data: this.value });
      }
    }
    if (!this.loaded) {
      this.loaded = true;
      this.setValue();
    }

    // if (this.primaryPractice?.length) {
    //   this.setOption();
    //   this.onSelect({ data: this.value });
    // }
  }

  setOption() {
    this.practices = [{ id: '', text: '' }];
    // filter primary practice from option and change required format
    this.practices = this.practices.concat(
      this.practiceList
        .filter(p => !(this.primaryPractice || []).includes(p.id))
        .map(practice => ({ id: practice.id, text: practice.name }))
    );
    // if(this.primaryPractice) {
    //   let transformedPractices = {};
    //   for (let practice of this.practices) {
    //     if (!transformedPractices[practice.type]) {
    //       transformedPractices[practice.type] = [];
    //     }
    //     transformedPractices[practice.type].push(practice);
    //   }
  
    //   this.practices = Object.keys(transformedPractices).map(key => ({
    //     id: key,
    //     text: key,
    //     children: transformedPractices[key]
    //  }));
    // }
  }

  setValue() {
    this.setOption();
    if (this.value && this.value.length) {
      // this.onSelect({ data: this.value.map(practice => ({ id: practice.id, text: practice.name })) });
      // if (this.multiple) {
      this.values = this.value ? this.value.map(v => v.id.toString()) : null;
      // } else {
      //   this.values = this.value ? this.value[0]?.id : null;
      // }
    }
  }

  valueChanged(event) {
    if (event) {
      this.onSelect({
        data: this.practices.filter(o =>
          Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
      });
    }
  }

  onSelect(event) {
    let practices = event.data.map(practice => +practice.id)
      .filter(id => id);
    if (this.primaryPractice || practices.length) {
      this.touched = true;
      if (this.multiple) {
        // concat and filter primary from value
        practices = lodash.uniq(practices.concat(this.form.get(this.controlName).value || []))
          .filter(p => !(this.primaryPractice || []).includes(p));
        // $(`#${this.htmlId}`).val(null);
      }
      this.selectedPractices = this.practices.filter(practice => practices.includes(practice.id));
      if(this.primaryPractice) {
        this.practiceService.selectVerticalPracticeEvent.emit(this.selectedPractices);
      }
      if (!isEqual(practices.sort(), this.value.map(p => p.id).sort())) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(practices);

      if (this.multiple && this.selectedPractices.length) {
        const input = document.querySelector(`.${this.htmlId} .select2-search__field`) as HTMLInputElement
        input.placeholder = this.placeholder;
        input.style.width = 'auto';
      }
    }
  }

  remove(id) {
    this.touched = true;
    const practices = this.form.get(this.controlName).value.filter(practice => practice !== id);
    this.selectedPractices = this.practices.filter(practice => practices.includes(practice.id));
    if (!isEqual(practices, this.value.map(p => p.id))) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(practices);
    this.values = this.selectedPractices.map(p => p.id.toString())
  }
}
