import { Component, OnInit } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { Router } from '@angular/router';

import { PracticesService } from 'app/services/practices/practices.service';
import { LocationsService } from 'app/services/locations/locations.service';
import { PeopleService } from 'app/services/people/people.service';
import { FilterService } from 'app/services/filter/filter.service';
import { TechDocumentsService } from 'app/services/tech-documents/tech-documents.service';
import { PocsService } from 'app/services/pocs/pocs.service';
import { AwardsService } from 'app/services/awards/awards.service';
import { TechTalksService } from 'app/services/tech-talks/tech-talks.service';
import { DigitalAcceleratorsService } from 'app/modules/feature/digital-accelerator/digital-accelerators.service';
import { InitiativesService } from 'app/services/initiatives/initiatives.service';
import { FeedbackService } from 'app/services/feedback/feedback.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  // filterConfig: FieldConfig[] = [];

  constructor(private practicesService: PracticesService, private locationsService: LocationsService,
    private peopleService: PeopleService, public filterService: FilterService, private techDocumentService: TechDocumentsService,
    private pocService: PocsService, private awardService: AwardsService,
    private techTalkService: TechTalksService, private digitalAcceleratorService: DigitalAcceleratorsService,
    private initiativesService: InitiativesService, private router: Router, private feedbackService: FeedbackService) { }

  ngOnInit() {
    // create configuration for filter form
    this.filterService.pageFilters.forEach(filter => {
      if (['filterDropdown', 'nestedFilter'].includes(this.filterService.fieldMap[filter].type) || (this.filterService.fieldMap[filter].type === 'noUi' && filter === 'primaryPractices')) {
        if (filter === 'practices' || filter === 'primaryPractices') {
          this.practicesService.getPractices()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'].map(p => {
                return {
                  id: p.id,
                  name: p.name,
                  groupBy: p.type.name == 'Vertical' ? "Industries" : "Practices",
                };
              });
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'locations') {
          this.locationsService.getLocations()
            .subscribe(response => {
              let options = response['rows'].map(c => {
                c['name'] = c.full_name;
                return c;
              });
              if (this.filterService.ccLocations.length) {
                options = options.filter(c => this.filterService.ccLocations.includes(c.id));
              }
              this.filterService.fieldMap[filter]['options'] = options;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'member_types') {
          this.peopleService.getMemberType()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'tech_document_types') {
          this.techDocumentService.getTechDocumentTypes()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'initiatives') {
          let entityCategoryId = 0
          if (this.router.url === '/tech-papers') {
            entityCategoryId = 3
          } else if (this.router.url === '/tech-talks') {
            entityCategoryId = 2
          }

          let f = [];
          if (entityCategoryId) {
            f.push({ key: 'filters[categories][]', values: [entityCategoryId] });
          }
          const params = this.filterService.getApiParams(f);
          this.initiativesService.getInitiativeTypes(params)
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['initiative_types'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'languages') {
          this.techTalkService.getLanguages()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'].map(l => {
                l['name'] = l.language;
                return l;
              });
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'poc_statuses') {
          this.pocService.getStatuses()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'featured') {
          this.filterService.fieldMap[filter]['options'] = [
            { id: 1, name: 'Yes' },
            { id: 0, name: 'No' }]
            ;
          this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
          this.filterService.setFilterByParams(filter);
        } else if (filter === 'poc_purposes') {
          this.pocService.getPurposes()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'visible') {
          this.filterService.fieldMap[filter]['options'] = [
            { id: 1, name: 'Shown' },
            { id: 0, name: 'Hidden' }
          ];
          this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
          this.filterService.setFilterByParams(filter);
        } else if (filter === 'nugget_type') {
          this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
          this.filterService.setFilterByParams(filter);
        } else if (filter === 'nugget_statuses') {
          this.pocService.getStatuses()
            .subscribe(response => {
              const option = response['rows'].map(a => ({ ...a }));
              option.map(s => {
                if (+s.id === 7) {
                  s.name = s.name + ' / Published';
                }
                return s;
              });

              option.push(
                {
                  id: 'none',
                  name: 'None'
                });

              this.filterService.fieldMap[filter]['options'] = option;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'feedback_statuses') {
          this.feedbackService.getFeedbackStatuses()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['statuses'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'award_types') {
          this.awardService.getAwardsTypes()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'quarters') {
          const filterData = [{ key: 'time_zone', values: [<any>Intl.DateTimeFormat().resolvedOptions().timeZone] }];
          // get api params
          const params = this.filterService.getApiParams(filterData);

          this.awardService.getAwardsQuarter(params)
            .subscribe(response => {
              let options = response['financial_quarters'].map(q => {
                q['name'] = q.quarter;
                q['id'] = q.quarter;
                return q;
              });

              this.filterService.fieldMap[filter]['options'] = options;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'entity_categories') {
          const filterData = [{ key: 'parent_c_name', values: ['digital_accelerator'] }];
          const params = this.filterService.getApiParams(filterData);
          this.digitalAcceleratorService.getAcceleratorTypes(params)
            .subscribe(response => {
              response = response['rows'].filter(item => ![17, 24].includes(item.id));
              this.filterService.fieldMap[filter]['options'] = response;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            })
        } else if (filter === 'industries') {
          this.digitalAcceleratorService.getIndustries()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['industries']
                .map(i => ({ id: i.id, name: i.name }));
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'industry_segments') {
          this.digitalAcceleratorService.getIndustries()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['industries']
                .flatMap(i => i.industry_segments.map(s => ({ id: s.id, name: s.name, groupBy: i.name })));
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'marketplaces') {
          this.digitalAcceleratorService.getMarketPlaces()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['market_places'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        } else if (filter === 'providers') {
          this.digitalAcceleratorService.getProviders()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
            });
        }
      } else {
        this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
        this.filterService.setFilterByParams(filter);
      }
    });
  }
}
