<div class="modal-header" *ngIf="showHeader">
  <h4 class="modal-title" *ngIf="data.title">{{data.title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
  </button>
</div>
<div class="modal-body" [perfectScrollbar]="config" (psYReachEnd)="psYReachEnd()" [ngClass]="classes">
  <div *ngIf="data.message" class="modal-message" [innerHTML]="data.message"></div>
  <ng-template appHostComponent [data]="data.data" [component]="data.component" *ngIf="data.component" [type]="'model'">
  </ng-template>
</div>
<div class="modal-footer" *ngIf="data.showFooter">
  <button type="button" class="btn" *ngIf="showBtnCancel" (click)="cancel()" [ngClass]="btnCancelClass"
    [ngbTooltip]="data.btnCancelTooltip" container="body" tooltipClass="card-tooltip">
    {{ data.btnCancelText }}
    <ng-container *ngIf="!data.btnCancelText" i18n>Cancel</ng-container>
  </button>
  <button type="button" class="btn" (click)="accept(id)" [disabled]="disableBtnOk" [ngClass]="btnOkClass"
    [ngbTooltip]="data.btnOkTooltip" container="body">{{ data.btnOkText }}
    <ng-container *ngIf="!data.btnOkText" i18n>Ok</ng-container>
  </button>
</div>
