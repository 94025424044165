import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { FilterService } from 'app/services/filter/filter.service';

@Component({
  selector: 'app-nested-filter',
  templateUrl: './nested-filter.component.html',
  styleUrls: ['./nested-filter.component.css']
})
export class NestedFilterComponent implements OnInit {

  field: FieldConfig;
  group: UntypedFormGroup;
  selected = [];
  scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public filterService: FilterService) { }

  ngOnInit(): void {
    this.filterService.advanceForm?.get(this.field.name)?.valueChanges
      .subscribe(v => {
        this.selected = v;
      })

    this.filterService.form?.get(this.field.name)?.valueChanges
      .subscribe(v => {
        this.selected = v;
      })
  }

  valueChange(event) {
    this.filterService.advanceForm?.get(this.field.name)?.setValue(this.selected)
    this.filterService.form?.get(this.field.name)?.setValue(this.selected)
  }
}
