import { Component, OnInit, Input } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-skills-card',
  templateUrl: './skills-card.component.html',
  styleUrls: ['./skills-card.component.css']
})
export class SkillsCardComponent implements OnInit {
  moreSkills = 0;
  skills = [];
  @Input() data;
  @Input() type = '';
  @Input() displayNumber = 6;
  constructor(private modalService: ModalPopupService, private commonMethods: CommonMethodsService) { }

  ngOnInit() {
    if (this.type === 'model') {
      this.displayNumber = 100;
    }
    this.skills = this.data;
    this.displayNumber = this.commonMethods.getDisplayCount(this.skills['value'].length, this.displayNumber);
    this.moreSkills = this.skills['value'].length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Skills`, message: '', component: 'SkillsCardComponent', data: this.skills };
    this.modalService.open(data);
  }
}
