<div class="filter_dd_multiselect" [formGroup]="group" (clickOutside)="close()">
  <div (click)="toogle()" #filterLabel>
    <span class="fd-placeholder">
      <span class="truncate-1">
        {{field.placeholder || "Select " + field.label}}
      </span>
    </span>
    <span class="d-arrow"><i class="fas fa-chevron-down"></i></span>
  </div>
  <ul #filterOption class="multiselect_list" [perfectScrollbar]="config" [class]="field.name+'-filter'"
    [ngClass]="{'d-block':open}">
    <li [formArrayName]="field.name" *ngFor="let item of field.options; let i = index;">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [formControlName]="i" [id]="field.name+i">
        <label class="form-check-label" [for]="field.name+i">
          {{item.name}}
        </label>
      </div>
    </li>
  </ul>
</div>