import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FieldConfig } from 'app/interfaces/field';
import { FilterService } from 'app/services/filter/filter.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-advance-filter-fields',
  templateUrl: './advance-filter-fields.component.html',
  styleUrls: ['./advance-filter-fields.component.css']
})
export class AdvanceFilterFieldsComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];
  form: UntypedFormGroup;
  @Output() clearEvent = new EventEmitter<boolean>();

  constructor(private fb: UntypedFormBuilder, public filterService: FilterService) { }

  ngOnInit(): void {
    this.filterService.advanceForm = this.createControl();

    this.filterService.advanceForm.valueChanges.subscribe(filterValues => {
      // set filter as placeholder
      this.fields.forEach(field => {
        if (field.type === 'filterDropdown') {
          const filters =
            filterValues[field.name].map((checked, index) => {
              let selected = null;
              if (checked) {
                field.options[index]['filterType'] = field.name;
                selected = field.options[index];
              }
              return selected;
            })
              .filter(value => value)
              .reduce((acc, curr) => {
                acc.push(curr.name);
                return acc;
              }, []);

          if (filters.filter) {
            this.filterService.setFilterLabel(field.key || field.name, filters.join(', '))
          }
        }
      });

      for (const value of Object.values(filterValues)) {
        if (value == null) {
          this.clearEvent.emit(false);
        } else if (typeof value == 'object' && Array.isArray(value) && value.some(v => v)) {
          this.clearEvent.emit(true);
          break;
        } else if (typeof value == 'object' && !Array.isArray(value)) {
          this.clearEvent.emit(true);
          break;
        }
      }
    });

    if (this.filterService.advanceForm.get('practices') && this.filterService.advanceForm.get('primaryPractices')) {
      this.filterService.advanceForm.get('practices').valueChanges
        .subscribe(value => {
          if (this.filterService.advanceForm.get('primaryPractices').value.filter(f => f).length) {
            this.filterService.advanceForm.get('practices').value.forEach((v, i) => {
              if (v && this.filterService.advanceForm.get('primaryPractices').value[i]) {
                this.filterService.advanceForm.get('primaryPractices').value[i] = false;
              }
            })
          }
        })
    }
    setTimeout(() => {
      this.filterService.setFilters();
    }, 1000);
  }


  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === 'button' || field.type === 'filterLink') {
        return;
      }
      let control;
      if (field.type === 'filterDropdown' || field.type === 'facetOptions' || (field.type === 'noUi' && field.name === 'primaryPractices')) {
        const options = field.options.map(c => this.fb.control(false));
        control = this.fb.array(options);
      } else {
        control = this.fb.control(
          field.value
        );
      }
      group.addControl(field.name, control);
    });
    return group;
  }

}
