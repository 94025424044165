import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {
  apiUrl = environment.apiUrl;
  deleteCarouselEvent = new EventEmitter();

  constructor(private http: HttpClient) { }
  // get carousels list
  getCarousels(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/carousels', { params, headers });
  }

  // get carousel info
  getCarousel(id) {
    return this.http.get(this.apiUrl + `/v1/carousels/${id}`);
  }

  submitCarousel(data) {
    return this.http.post(this.apiUrl + '/v1/carousels', data);
  }

  updateCarousel(id, data) {
    return this.http.put(this.apiUrl + `/v1/carousels/${id}`, data);
  }

  deleteCarousel(id: number) {
    return this.http.delete(this.apiUrl + `/v1/carousels/${id}`);
  }
}

export interface Carousels {
  carousels: [];
  total_count: number;
}
