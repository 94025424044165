import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.css']
})
export class FilePickerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName = '';
  @Input() label = '';
  @Input() folderId = '';
  @Input() fileTypes = [];
  @Input() mimeTypes = '';
  @Input() submitted = false;
  // @Input() value;
  @Input() parentUrl = '';
  @Input() nuggetType;
  @Input() asIndependent = false;
  @Input() helpText = '';
  @Input() onlySelect: boolean = true;
  @Input() extension;
  @Input() optional = false;

  uploadSource = "local";
  fileDetails = { name: "", url: "" }
  touched = false;

  constructor() { }

  ngOnInit(): void {
    this.fileDetails.url = this.form.get(this.controlName).value && this.form.get(this.controlName).value.url;
    this.fileDetails.name = this.form.get(this.controlName).value && this.form.get(this.controlName).value.name.replace(/-\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}/gu, "").replace(/-\d{13}/gu, "");
    this.form.get(this.controlName).valueChanges.subscribe((data) => {
      this.fileDetails.url = data && data.url;
      this.fileDetails.name = data && data.name.replace(/-\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}/gu, "").replace(/-\d{13}/gu, "");
    })
  }

  remove(event, file) {
    event.preventDefault();
    event.stopPropagation();
    this.form.get(this.controlName).setValue(null)
  }
}
