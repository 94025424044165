<!-- <button [ngClass]="{'btn-disabled':!liked}" class="btn btn-primary" (click)="like()"><i class="fas fa-heart"></i>
  <ng-container *ngIf="likeCount"> ({{likeCount}})</ng-container>
</button> -->
<ng-container *ngIf="isPerson; else noAction;">
  <a href="javascript:;" [ngClass]="{'liked':liked}" (click)="like()">
    <i class="fas fa-thumbs-up"></i>
  </a>
</ng-container>
<a href="javascript:;" *ngIf="likeCount" (click)="open()"> ({{likeCount}})</a>

<ng-template #noAction>
  <i class="fas fa-thumbs-up"></i>
</ng-template>
