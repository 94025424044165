<div class="row members-row no-gutters" *ngFor="let member of data">
  <div class="col-md-2">
    <ng-template #toolTipContent>
      <app-tooltip-person-card [data]="member" (mouseleave)="t.close()"></app-tooltip-person-card>
    </ng-template>
    <span class="avatar avatar-sm" [ngbTooltip]="toolTipContent" tooltipClass='p-card-tooltip' container="body"
      triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
      (mouseleave)="onMouseleave($event,t)">
      <a [routerLink]="['/team',member['login'] || member['person_id']]">
        <img [src]="member.photo_url">
      </a>
    </span>
  </div>
  <div class="col-md-9 mt-15 ml-10">
    <a [routerLink]="['/team',member['login'] || member['person_id']]">{{peopleService.getPersonName(member)}}</a>
  </div>
</div>
