import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { environment } from 'environments/environment';
import { RemoveSpacePipe } from 'app/pipes/remove-space.pipe';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-tag-options',
  templateUrl: './tag-options.component.html',
  styleUrls: ['./tag-options.component.css']
})
export class TagOptionsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() helpText = '';
  @Input() submitted = false;
  placeholder = $localize`Select tags`;
  label = $localize`Tags`;
  options: Options;
  selectedTags = [];
  touched = false;
  data = [];
  dataOption = [];
  values: string[];

  apiUrl = environment.apiUrl;

  constructor(private removeSpace: RemoveSpacePipe) { }

  ngOnInit() {
    if (this.value && this.value.length) {
      this.data = this.value.map((tag) => ({
        id: tag.name,
        text: tag.name
      }));
      this.dataOption = this.data;
      // this.onSelect({ data: this.data });
      setTimeout(() => {
        this.values = this.data.map(l => l.id)
      }, 0);
    }

    this.options = {
      tags: true,
      multiple: true,
      placeholder: this.placeholder,
      language: { errorLoading: () => $localize`Searching...` },
      minimumInputLength: 1,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/tags',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1,
          'filters[approved]': 1
        }),
        processResults: (data) => {
          this.dataOption = data.tags.map((tag) => ({
            id: tag.name,
            text: tag.name
          }));

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 30) < data.total_count
            }
          };
        }
      },
      createTag: (params) => {
        const term = $.trim(params.term.toLowerCase());

        if (term === '') {
          return null;
        }

        if (this.validateTag(term)) {
          return {
            id: term,
            text: term,
            newTag: true
          };
        }

        return null;
      }
    };
  }

  validateTag(tag) {
    const tagRegex = /^[a-zA-Z0-9-+. ]*$/;
    return tagRegex.test(tag);
  }

  valueChanged(event) {
    this.onSelect({ data: this.dataOption.filter(o => event.includes(o.id.toString())) });
  }

  onSelect(event) {
    const tags = event.data.map(tag => ({
      id: tag.id,
      text: tag.text,
    }));

    if (tags.length) {
      this.touched = true;
      this.selectedTags = lodash.uniqBy(tags.concat(this.form.get(this.controlName).value || []), 'id');
      if (!isEqual(this.selectedTags, this.data)) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(this.selectedTags);
      // $('#' + this.removeSpace.transform(this.label)).val(null).trigger('change');
      if (this.selectedTags.length) {
        setTimeout(() => {
          const input = document.querySelector(`.tag-options .select2-search__field`) as HTMLInputElement
          input.placeholder = this.placeholder;
          input.style.width = 'auto';
        }, 0)
      }
    }
  }

  remove(id) {
    this.touched = true;
    this.selectedTags = this.form.get(this.controlName).value.filter(t => t.id !== id);
    if (!isEqual(this.selectedTags, this.data)) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(this.selectedTags);
    // $('#' + this.removeSpace.transform(this.label) + ' option[value="' + id + '"]').remove();
    this.values = this.selectedTags.map(l => l.id)
  }
}
