import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';
import { CommonModule } from '@angular/common';
import { PipeModule } from 'app/modules/shared/pipe/pipe.module';

import { TranslateDirective } from 'app/directives/translate.directive';

import { CmsPageComponent } from 'app/components/cms/cms-page/cms-page.component';
import { CmsDomComponent } from 'app/components/cms/cms-dom/cms-dom.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';

const routes: Routes = [
  // {
  //   path: 'digital-delivery', data: { fullWidth: true, hideBreadcrumb: true },
  //   children: [
  //     {
  //       path: '', component: CmsPageComponent,
  //       data: { breadcrumb: '', title: $localize`Digital Delivery`, id: environment.cmsPageIds['digitalDelivery'], editRole: 'digital_delivery_manager' },
  //     },
  //     {
  //       path: 'program-management', component: CmsPageComponent,
  //       data: { breadcrumb: '', title: $localize`Digital Delivery Program Management`, id: environment.cmsPageIds['digitalDeliveryPM'], editRole: 'digital_delivery_program_manager' },
  //     },
  //     {
  //       path: 'product-management', data: { editRole: 'digital_delivery_product_manager' },
  //       children: [
  //         {
  //           path: '', component: CmsPageComponent,
  //           data: { breadcrumb: '', title: $localize`Digital Delivery Product Management`, id: environment.cmsPageIds['digitalDeliveryProductManagement'], editRole: 'digital_delivery_product_manager' },
  //         },
  //         {
  //           path: 'process-track', data: { editRole: 'digital_delivery_product_manager' },
  //           children: [
  //             {
  //               path: '', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Process Track`, id: environment.cmsPageIds['digitalDeliveryPMPT'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'ideation', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Ideation`, id: environment.cmsPageIds['processTrackIdeation'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'analysis', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Analysis`, id: environment.cmsPageIds['processTrackAnalysis'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'development', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Development`, id: environment.cmsPageIds['processTrackDevelopment'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'go-to-market', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Go to market`, id: environment.cmsPageIds['processTrackGoToMarket'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'in-life', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`In Life`, id: environment.cmsPageIds['processTrackInLife'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'end-of-life', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`End of Life`, id: environment.cmsPageIds['processTrackEndOfLife'], editRole: 'digital_delivery_product_manager' },
  //             }
  //           ]
  //         },
  //         {
  //           path: 'people-development-track', component: CmsPageComponent,
  //           data: { breadcrumb: '', title: $localize`People Development Track`, id: environment.cmsPageIds['digitalDeliveryPMPDT'], editRole: 'digital_delivery_product_manager' },
  //         },
  //         {
  //           path: 'marketing-research-analysis-track', component: CmsPageComponent,
  //           data: { breadcrumb: '', title: $localize`Marketing Research Analysis Track`, id: environment.cmsPageIds['digitalDeliveryPMMRAT'], editRole: 'digital_delivery_product_manager' },
  //         },
  //         {
  //           path: 'delivery-assessment-track', data: { editRole: 'digital_delivery_product_manager' },
  //           children: [
  //             {
  //               path: '', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Delivery Assessment Track`, id: environment.cmsPageIds['digitalDeliveryPMDAT'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'process', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Process`, id: environment.cmsPageIds['deliveryAssessmentProcess'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'framework', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Framework`, id: environment.cmsPageIds['deliveryAssessmentFramework'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'accomplished-assessments', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Accomplished Assessments`, id: environment.cmsPageIds['deliveryAssessmentAccomplished'], editRole: 'digital_delivery_product_manager' },
  //             },
  //           ]
  //         },
  //         {
  //           path: 'training-certification-track', data: { editRole: 'digital_delivery_product_manager' },
  //           children: [
  //             {
  //               path: '', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Training Certification Track`, id: environment.cmsPageIds['digitalDeliveryPMTCT'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'trainings-completed', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Trainings Completed`, id: environment.cmsPageIds['trainingCertificationCompleted'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'upcoming-trainings', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Upcoming Trainings`, id: environment.cmsPageIds['trainingCertificationUpcoming'], editRole: 'digital_delivery_product_manager' },
  //             },
  //             {
  //               path: 'events-attended-conducted', component: CmsPageComponent,
  //               data: { breadcrumb: '', title: $localize`Events Attended/Conducted`, id: environment.cmsPageIds['trainingCertificationConducted'], editRole: 'digital_delivery_product_manager' },
  //             },
  //           ]
  //         },
  //         {
  //           path: 'master-template-track', component: CmsPageComponent,
  //           data: { breadcrumb: '', title: $localize`Master Template Track`, id: environment.cmsPageIds['digitalDeliveryPMMTT'], editRole: 'digital_delivery_product_manager' },
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: 'solution-accelerators',
    children: [
      {
        path: '',
        redirectTo: '/digital-accelerators?categories=Solution%20Accelerators',
        pathMatch: 'full',
      },
      {
        path: 'msa-java', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`MicroService Accelerator Java`, id: environment.cmsPageIds['msaJavaAccelerator'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
      {
        path: 'msa-net', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`MicroService Accelerator .Net`, id: environment.cmsPageIds['msaNetAccelerator'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
      {
        path: 'flutter-accelerator', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`Flutter Accelerator`, id: environment.cmsPageIds['flutter'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
      {
        path: 'android-accelerator-clean-architecture', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`Android Accelerator (Clean architecture)`, id: environment.cmsPageIds['androidAccCA'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
      {
        path: 'android-accelerator-mvvm', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`Android Accelerator (MVVM)`, id: environment.cmsPageIds['androidAccMVVM'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
      {
        path: 'iOS-accelerator', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`iOS Accelerator`, id: environment.cmsPageIds['iOSAccelerator'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
      {
        path: 'reactnative-accelerator', component: CmsPageComponent,
        data: {
          breadcrumb: '', title: $localize`ReactNative Accelerator`, id: environment.cmsPageIds['reactNative'],
          fullWidth: true, hideBreadcrumb: true
        }
      },
    ]
  },
  {
    path: 'industry-solutions',
    redirectTo: '/digital-accelerators?categories=Industry%20Solutions',
    pathMatch: 'full'
  },
  {
    path: 'webinars', component: CmsPageComponent,
    data: { breadcrumb: '', title: $localize`Webinars`, id: environment.cmsPageIds['webinars'], fullWidth: true, hideBreadcrumb: true },
  },
  {
    path: 'important-links', component: CmsPageComponent,
    data: {
      breadcrumb: '', title: $localize`Important Links`, id: environment.cmsPageIds['importantLinks'], fullWidth: true, hideBreadcrumb: true
    },
  },
  {
    path: 'h-combinator',
    children: [
      {
        path: '', component: CmsPageComponent, data: {
          breadcrumb: '', title: $localize`H Combinator`, id: environment.cmsPageIds['hCombinator'], fullWidth: true, hideBreadcrumb: true
        },
      },
      {
        path: 'season-1', component: CmsPageComponent, data: {
          breadcrumb: '', title: $localize`H Combinator - Season 1`, id: environment.cmsPageIds['hCombinatorS1'], fullWidth: true, hideBreadcrumb: true
        },
      },
      {
        path: 'season-2', component: CmsPageComponent, data: {
          breadcrumb: '', title: $localize`H Combinator - Season 2`, id: environment.cmsPageIds['hCombinatorS2'], fullWidth: true, hideBreadcrumb: true
        },
      }
    ]
  },
  {
    path: 'pages', data: { breadcrumb: $localize`Page`, fullWidth: true, hideBreadcrumb: true },
    children: [
      { path: '', component: CmsPageComponent },
      { path: ':id', component: CmsPageComponent }
    ]
  },
];

@NgModule({
  declarations: [
    TranslateDirective,
    CmsPageComponent,
    CmsDomComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    PipeModule,
    ContentLoaderModule,
  ],
  exports: [CmsPageComponent, CmsDomComponent]
})
export class CmsModule { }
