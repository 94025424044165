import { Component, OnInit, Input } from '@angular/core';
import { NuggetsService } from 'app/services/nuggets/nuggets.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CloudPickerService } from "../../../services/cloud-picker/cloud-picker.service";
import { LoginService } from 'app/services/auth/login.service';
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-case-study-card',
  templateUrl: './case-study-card.component.html',
  styleUrls: ['./case-study-card.component.css']
})
export class CaseStudyCardComponent implements OnInit {
  @Input() data;
  @Input() previewAccess: boolean = true;
  isAccess = false;
  showPreviewIcon = false;

  constructor(private nuggetService: NuggetsService, private modalService: ModalPopupService, private cloudPickerService: CloudPickerService, private loginService: LoginService, private collectionsService: CollectionsService) { }

  ngOnInit() {
    this.cloudPickerService.previewUpdate.subscribe(response => {
      if (this.data.id == response.entityId && this.data.search_type == response.entityCategory) {
        this.data.preview_url = response.previewUrl;
        this.modalService.close();
      }
    })
    if (this.loginService.isPrivilegedUser()) {
      this.isAccess = true
    }
  }

  removeCaseStudy(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: 'case-study', action: 'removeItem' });
  }
}
