import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
// import { CKEditorModule } from 'ngx-ckeditor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { CountoModule } from 'angular2-counto';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectiveModule } from './directive/directive.module';
import { PipeModule } from './pipe/pipe.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from '@eklipse/perfect-scrollbar';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalPopupService } from 'app/services/util/modal-popup.service';

// directives
import { HostComponentDirective } from 'app/directives/host-component.directive';

// components
import { BreadcrumbComponent } from 'app/components/breadcrumb/breadcrumb.component';
import { NuggetCardComponent } from 'app/shared/components/nugget-card/nugget-card.component';
import { ContributorCardComponent } from 'app/shared/components/contributor-card/contributor-card.component';
// import { CustomerCardComponent } from 'app/shared/components/customer-card/customer-card.component';
// import { CustomerListComponent } from 'app/shared/components/customer-list/customer-list.component';
import { EditCcLocationComponent } from 'app/shared/components/edit-cc-location/edit-cc-location.component';
import { BlogCardComponent } from 'app/shared/components/blog-card/blog-card.component';
import { EventCardComponent } from 'app/shared/components/event-card/event-card.component';
import { EventInfoComponent } from 'app/shared/components/event-info/event-info.component';
import { ActivityArtifactComponent } from 'app/shared/components/fields/activity-artifact/activity-artifact.component';
import { GooglePickerComponent } from 'app/shared/components/fields/google-picker/google-picker.component';
import { LanguageOptionsComponent } from 'app/shared/components/fields/language-options/language-options.component';
import { MemberSkillComponent } from 'app/shared/components/fields/member-skill/member-skill.component';
import { NuggetOptionComponent } from 'app/shared/components/fields/nugget-option/nugget-option.component';
import { PersonOptionsComponent } from 'app/shared/components/fields/person-options/person-options.component';
import { PracticeOptionsComponent } from 'app/shared/components/fields/practice-options/practice-options.component';
import { PracticeOptionsNewComponent } from 'app/shared/components/fields/practice-options-new/practice-options-new.component';
import { PrimaryPracticeSelectComponent } from 'app/shared/components/fields/primary-practice-select/primary-practice-select.component';
import { SecondaryPracticeSelectComponent } from 'app/shared/components/fields/secondary-practice-select/secondary-practice-select.component';
import { InitiativeOptionsComponent } from 'app/shared/components/fields/initiative-options/initiative-options.component';
import { TagOptionsComponent } from 'app/shared/components/fields/tag-options/tag-options.component';
import { TextEditorComponent } from 'app/shared/components/fields/text-editor/text-editor.component';
import { GloLoginComponent } from 'app/shared/components/glo-login/glo-login.component';
import { IframeComponent } from 'app/shared/components/iframe/iframe.component';
import { JoinPracticeComponent } from 'app/shared/components/join-practice/join-practice.component';
import { LeavePracticeComponent } from 'app/shared/components/leave-practice/leave-practice.component';
import { LocationOptionsComponent } from 'app/shared/components/location-options/location-options.component';
import { MemberCardComponent } from 'app/shared/components/member-card/member-card.component';
import { MembersChipComponent } from 'app/shared/components/members-chip/members-chip.component';
import { LocationsChipComponent } from 'app/shared/components/locations-chip/locations-chip.component';
import { LocationsListComponent } from 'app/shared/components/locations-list/locations-list.component';
import { PracticeMembersCardComponent } from 'app/shared/components/practice-members-card/practice-members-card.component';
import { MembersListComponent } from 'app/shared/components/members-list/members-list.component';
import { NuggetRecommendationComponent } from 'app/shared/components/nugget-recommendation/nugget-recommendation.component';
import { OtherSkillsComponent } from 'app/shared/components/other-skills/other-skills.component';
import { PracticeHeadComponent } from 'app/shared/components/practice-head/practice-head.component';
import { PracticesChipComponent } from 'app/shared/components/practices-chip/practices-chip.component';
import { TechnologiesChipComponent } from 'app/shared/components/technologies-chip/technologies-chip.component';
import { SliderComponent } from 'app/shared/components/slider/slider.component';
import { TagChipsComponent } from 'app/shared/components/tag-chips/tag-chips.component';
import { TopContributorsComponent } from 'app/shared/components/top-contributors/top-contributors.component';
import { TopRecommendationsComponent } from 'app/shared/components/top-recommendations/top-recommendations.component';
import { ModalComponent } from 'app/shared/components/modal/modal.component';
import { PersonCardComponent } from 'app/shared/components/person-card/person-card.component';
import { PersonPracticesComponent } from 'app/shared/components/person-practices/person-practices.component';
import {
  AdvisoryRequestAccessDialogComponent
} from 'app/components/advisories/advisory-request-access-dialog/advisory-request-access-dialog.component';
import { SkillsCardComponent } from 'app/shared/components/skills-card/skills-card.component';
import { NuggetCardLoaderComponent } from 'app/shared/components/loaders/nugget-card-loader/nugget-card-loader.component';
import { CustomerCardLoaderComponent } from 'app/shared/components/loaders/customer-card-loader/customer-card-loader.component';
import { CustomerListLoaderComponent } from 'app/shared/components/loaders/customer-list-loader/customer-list-loader.component';
import { BlogCardLoaderComponent } from 'app/shared/components/loaders/blog-card-loader/blog-card-loader.component';
import { PracticesAvatarComponent } from 'app/shared/components/practices-avatar/practices-avatar.component';
import { CaseStudyCardComponent } from 'app/shared/components/case-study-card/case-study-card.component';
import { TagFilterComponent } from 'app/shared/components/fields/tag-filter/tag-filter.component';
import { PageListComponent } from 'app/shared/components/page-list/page-list.component';
import { AdvisoryCardComponent } from 'app/shared/components/advisory-card/advisory-card.component';
import { AdvisoryCardLoaderComponent } from 'app/shared/components/loaders/advisory-card-loader/advisory-card-loader.component';
import { LikeComponent } from 'app/shared/components/like/like.component';
import { CommentsComponent } from 'app/shared/components/comments/comments.component';
import { CommentReplyComponent } from 'app/shared/components/comment-reply/comment-reply.component';
import { SearchCustomerComponent } from 'app/components/customer-activities/search-customer/search-customer.component';
import { LikedByComponent } from 'app/shared/components/liked-by/liked-by.component';
import { DeckCardLoaderComponent } from 'app/shared/components/loaders/deck-card-loader/deck-card-loader.component';
import { AssignToComponent } from 'app/shared/components/assign-to/assign-to.component';
import { AssignedComponent } from 'app/shared/components/assign-to/assigned/assigned.component';
import { LikeCommentViewCountComponent } from 'app/shared/components/like-comment-view-count/like-comment-view-count.component';
import { RecommendedNuggetCardComponent } from 'app/shared/components/recommended-nugget-card/recommended-nugget-card.component';
import { NuggetLoaderComponent } from 'app/shared/components/loaders/nugget-loader/nugget-loader.component';
import { NuggetPreviewComponent } from 'app/shared/components/nugget-preview/nugget-preview.component';
import { TooltipPersonCardComponent } from 'app/shared/components/tooltip-person-card/tooltip-person-card.component';
import { BlogLoaderComponent } from 'app/shared/components/loaders/blog-loader/blog-loader.component';
import { SupportedLanguageComponent } from 'app/shared/components/supported-language/supported-language.component';
// import { LeadersChipComponent } from 'app/shared/components/leaders-chip/leaders-chip.component';
import { ContributionLoaderComponent } from 'app/shared/components/loaders/contribution-loader/contribution-loader.component';
import { ReportNuggetComponent } from 'app/shared/components/report-nugget/report-nugget.component';
import { ReportViewComponent } from 'app/shared/components/report-view/report-view.component';
import { NuggetReportCountComponent } from 'app/shared/components/nugget-report-count/nugget-report-count.component';
import { CloudPickerComponent } from 'app/shared/components/fields/cloud-picker/cloud-picker.component';
import { ChangeNuggetPreviewComponent } from 'app/shared/components/change-nugget-preview/change-nugget-preview.component';
import { CloudFileUploadComponent } from 'app/shared/components/fields/cloud-file-upload/cloud-file-upload.component';
import { FilePickerComponent } from 'app/shared/components/fields/file-picker/file-picker.component';
import { ChangeCarouselPreviewComponent } from 'app/shared/components/change-carousel-preview/change-carousel-preview.component';
import { NuggetsPreviewGalleryComponent } from 'app/shared/components/nuggets-preview-gallery/nuggets-preview-gallery.component';
import { EmbedDocumentComponent } from 'app/shared/components/embed-document/embed-document.component';
import { CommunityOptionsComponent } from 'app/shared/components/fields/community-options/community-options.component';
import { MoveFromCoreComponent } from 'app/shared/components/move-from-core/move-from-core.component';
import { TechRadarCardComponent } from 'app/modules/feature/tech-radar/tech-radar-card/tech-radar-card.component';
import { BannerLoaderComponent } from 'app/shared/components/loaders/banner-loader/banner-loader.component';
import { ContactUsComponent } from 'app/shared/components/contact-us/contact-us.component';
import { IndustryOptionsComponent } from 'app/shared/components/industry-options/industry-options.component';
import { IndustrySegmentOptionComponent } from 'app/shared/components/industry-segment-option/industry-segment-option.component';
import { IndustrySegmentSelectComponent } from 'app/shared/components/fields/industry-segment-select/industry-segment-select.component';
import { IndustriesChipComponent } from 'app/shared/components/industries-chip/industries-chip.component';
import { PracticesCtagComponent } from 'app/shared/components/practices-ctag/practices-ctag.component';
import { PartnershipCardComponent } from 'app/shared/components/partnership-card/partnership-card.component';
import { CreateCollectionComponent } from 'app/shared/components/create-collection/create-collection.component';
import { AddInCollectionComponent } from 'app/shared/components/add-in-collection/add-in-collection.component';
import { SharedPersonComponent } from 'app/components/shared-person/shared-person.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    HostComponentDirective,
    ContributorCardComponent,
    // CustomerCardComponent,
    // CustomerListComponent,
    EditCcLocationComponent,
    EventInfoComponent,
    ActivityArtifactComponent,
    GooglePickerComponent,
    LanguageOptionsComponent,
    MemberSkillComponent,
    NuggetOptionComponent,
    PersonOptionsComponent,
    PracticeOptionsComponent,
    PracticeOptionsNewComponent,
    PrimaryPracticeSelectComponent,
    SecondaryPracticeSelectComponent,
    InitiativeOptionsComponent,
    TagOptionsComponent,
    TextEditorComponent,
    GloLoginComponent,
    IframeComponent,
    JoinPracticeComponent,
    LeavePracticeComponent,
    LocationOptionsComponent,
    MemberCardComponent,
    MembersChipComponent,
    LocationsChipComponent,
    LocationsListComponent,
    PracticeMembersCardComponent,
    MembersListComponent,
    NuggetCardComponent,
    NuggetRecommendationComponent,
    OtherSkillsComponent,
    PracticeHeadComponent,
    PracticesChipComponent,
    TechnologiesChipComponent,
    SliderComponent,
    TagChipsComponent,
    TopContributorsComponent,
    TopRecommendationsComponent,
    ModalComponent,
    PersonCardComponent,
    SkillsCardComponent,
    PersonPracticesComponent,
    AdvisoryRequestAccessDialogComponent,
    NuggetCardLoaderComponent,
    CustomerCardLoaderComponent,
    CustomerListLoaderComponent,
    BlogCardLoaderComponent,
    PracticesAvatarComponent,
    CaseStudyCardComponent,
    TagFilterComponent,
    BlogCardComponent,
    EventCardComponent,
    PageListComponent,
    AdvisoryCardComponent,
    AdvisoryCardLoaderComponent,
    LikeComponent,
    CommentsComponent,
    CommentReplyComponent,
    LikedByComponent,
    SearchCustomerComponent,
    DeckCardLoaderComponent,
    AssignToComponent,
    AssignedComponent,
    LikeCommentViewCountComponent,
    RecommendedNuggetCardComponent,
    NuggetLoaderComponent,
    NuggetPreviewComponent,
    TooltipPersonCardComponent,
    BlogLoaderComponent,
    SupportedLanguageComponent,
    // LeadersChipComponent,
    ContributionLoaderComponent,
    ReportNuggetComponent,
    ReportViewComponent,
    NuggetReportCountComponent,
    CloudPickerComponent,
    ChangeNuggetPreviewComponent,
    CloudFileUploadComponent,
    FilePickerComponent,
    ChangeCarouselPreviewComponent,
    NuggetsPreviewGalleryComponent,
    EmbedDocumentComponent,
    CommunityOptionsComponent,
    MoveFromCoreComponent,
    BreadcrumbComponent,
    TechRadarCardComponent,
    BannerLoaderComponent,
    ContactUsComponent,
    IndustryOptionsComponent,
    IndustrySegmentOptionComponent,
    IndustrySegmentSelectComponent,
    IndustriesChipComponent,
    PracticesCtagComponent,
    PartnershipCardComponent,
    CreateCollectionComponent,
    AddInCollectionComponent,
    SharedPersonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelect2Module,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    CKEditorModule,
    CountoModule,
    NgxFilesizeModule,
    ContentLoaderModule,
    ClickOutsideModule,
    DirectiveModule,
    PipeModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    NgxSliderModule,
    NgxDocViewerModule,
    PdfViewerModule,
    NgSelectModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelect2Module,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    CKEditorModule,
    CountoModule,
    NgxFilesizeModule,
    ContentLoaderModule,
    ClickOutsideModule,
    DirectiveModule,
    PipeModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    NgxSliderModule,
    NgxDocViewerModule,
    NgSelectModule,

    HostComponentDirective,

    ContributorCardComponent,
    // CustomerCardComponent,
    // CustomerListComponent,
    EditCcLocationComponent,
    EventInfoComponent,
    ActivityArtifactComponent,
    GooglePickerComponent,
    LanguageOptionsComponent,
    MemberSkillComponent,
    NuggetOptionComponent,
    PersonOptionsComponent,
    PracticeOptionsComponent,
    PracticeOptionsNewComponent,
    PrimaryPracticeSelectComponent,
    SecondaryPracticeSelectComponent,
    InitiativeOptionsComponent,
    TagOptionsComponent,
    TextEditorComponent,
    GloLoginComponent,
    IframeComponent,
    JoinPracticeComponent,
    LeavePracticeComponent,
    LocationOptionsComponent,
    MemberCardComponent,
    MembersChipComponent,
    LocationsChipComponent,
    LocationsListComponent,
    PracticeMembersCardComponent,
    MembersListComponent,
    NuggetCardComponent,
    NuggetRecommendationComponent,
    OtherSkillsComponent,
    PracticeHeadComponent,
    PracticesChipComponent,
    TechnologiesChipComponent,
    SliderComponent,
    TagChipsComponent,
    TopContributorsComponent,
    TopRecommendationsComponent,
    ModalComponent,
    PersonCardComponent,
    PersonPracticesComponent,
    NuggetCardLoaderComponent,
    CustomerCardLoaderComponent,
    CustomerListLoaderComponent,
    BlogCardLoaderComponent,
    PracticesAvatarComponent,
    CaseStudyCardComponent,
    TagFilterComponent,
    BlogCardComponent,
    EventCardComponent,
    PageListComponent,
    AdvisoryCardComponent,
    AdvisoryCardLoaderComponent,
    LikeComponent,
    CommentsComponent,
    CommentReplyComponent,
    LikedByComponent,
    SearchCustomerComponent,
    DeckCardLoaderComponent,
    AssignToComponent,
    AssignedComponent,
    LikeCommentViewCountComponent,
    RecommendedNuggetCardComponent,
    NuggetLoaderComponent,
    NuggetPreviewComponent,
    TooltipPersonCardComponent,
    BlogLoaderComponent,
    SupportedLanguageComponent,
    // LeadersChipComponent,
    ContributionLoaderComponent,
    ReportNuggetComponent,
    ReportViewComponent,
    NuggetReportCountComponent,
    CloudPickerComponent,
    ChangeNuggetPreviewComponent,
    ChangeCarouselPreviewComponent,
    CloudFileUploadComponent,
    FilePickerComponent,
    NuggetsPreviewGalleryComponent,
    EmbedDocumentComponent,
    CommunityOptionsComponent,
    MoveFromCoreComponent,
    BreadcrumbComponent,
    TechRadarCardComponent,
    BannerLoaderComponent,
    ContactUsComponent,
    IndustryOptionsComponent,
    IndustrySegmentOptionComponent,
    IndustrySegmentSelectComponent,
    IndustriesChipComponent,
    PracticesCtagComponent,
    PartnershipCardComponent,
    CreateCollectionComponent,
    AddInCollectionComponent,
    SharedPersonComponent
  ],
  providers: [
    ModalPopupService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
