import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerActivityService {
  apiUrl = environment.apiUrl;
  deleteCustomerActivityEvent = new EventEmitter();
  deleteCustomerActivityConfirmEvent = new EventEmitter();
  clientId = '';
  clientName = '';
  referrer = '';

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getCustomerActivities(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/customer-activities', { params, headers });
  }

  // searchCustomerActivities(params, headers?) {
  //   return this.http.get(this.apiUrl + '/v1/customer-activities/search', { params, headers });
  // }

  deleteActivity(id) {
    return this.http.request('delete', this.apiUrl + `/v1/customer-activities/${id}`);
  }

  getCustomers(params) {
    return this.http.get(this.apiUrl + '/v1/clients', { params });
  }

  getCustomer(id, headers?) {
    return this.http.get(this.apiUrl + `/v1/clients/${id}`);
  }

  submitProspect(data) {
    return this.http.post(this.apiUrl + '/v1/clients', data);
  }

  getCustomersActivity(id, params?) {
    return this.http.get(this.apiUrl + `/v1/customer-activities/${id}`, { params });
  }

  getCustomerActivityEntities(id, headers?) {
    return this.http.get(this.apiUrl + `/v1/customer-activities/${id}/entities`, { headers });
  }

  submitCustomersActivity(data) {
    return this.http.post(this.apiUrl + '/v1/customer-activities', data);
  }

  updateCustomersActivity(id, data) {
    return this.http.put(this.apiUrl + `/v1/customer-activities/${id}`, data);
  }

  hasAccess() {
    return (this.loginService.isPrivilegedUser() || this.loginService.isCenterCoordinator() || this.loginService.isCoreMember()
      || this.loginService.isBandFourAndAbove()) && this.loginService.submitAccess();
  }

  canEditDelete(activity) {
    let practices = [];
    if (activity['practices'] && activity['practices'].length > 0) {
      practices = activity['practices'].map(practice => practice.id);
    }

    let locations = [];
    if (activity.locations && activity.locations.length > 0) {
      locations = activity.locations.map(location => location.city);
    } else if (activity['location']) {
      locations = [activity['location'].city];
    }

    return this.loginService.isPrivilegedUser(practices) || this.loginService.isCenterCoordinator(locations, practices) ||
      (activity['created_by'] === this.loginService.currentUser.user.email);
  }

  getArtifactTypes() {
    return this.http.get(this.apiUrl + '/v1/artifact-types');
  }

  getNuggetType() {
    return this.http.get(this.apiUrl + '/v1/entity-categories?filters[parent][]=1');
  }

}

export interface CustomerActivities {
  customer_activities: [];
  total_count: number;
}
