import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormValidatorService } from '../../../../services/form-validator/form-validator.service';

@Component({
  selector: 'app-member-skill',
  templateUrl: './member-skill.component.html',
  styleUrls: ['./member-skill.component.css']
})
export class MemberSkillComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() submitted = false;

  skillForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private formValidator: FormValidatorService) { }

  ngOnInit() {
    this.skillForm = this.fb.group({
      id: this.fb.control(this.value['id']),
      status: this.fb.control(this.value['review_status_id'] || '', [Validators.required]),
      comments: this.fb.control(this.value['review_comment'] || '')
    });

    this.skillForm.get('status').valueChanges
      .subscribe(value => {
        const comment = this.skillForm.get('comments');
        if (value === 3) {
          comment.setValidators([this.formValidator.whiteSpaceValidation, Validators.maxLength(500)]);
        } else {
          comment.setValidators([]);
        }
        comment.updateValueAndValidity();

      });
  }

}
