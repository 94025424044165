import { Component, Input, OnInit } from '@angular/core';
import { PracticesService } from 'app/services/practices/practices.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-primary-practice-select',
  templateUrl: './primary-practice-select.component.html',
  styleUrls: ['./primary-practice-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PrimaryPracticeSelectComponent
    }
  ]
})
export class PrimaryPracticeSelectComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() helpText = '';
  @Input() optional = false;
  @Input() withCommunity = false;
  htmlId = 'primary-practice';
  @Input() label = $localize`Practice`
  placeholder = $localize`Select practice`;
  values = [];
  selectedPractices: any[] = [];
  // primaryPractices: any[] = [];
  options: any[] = [];
  disabled = false;
  touched = false;
  onChange: any = () => { }
  onTouched = () => { this.touched = true; }

  constructor(private practiceService: PracticesService) { }

  ngOnInit(): void {
    if (this.multiple) {
      this.htmlId += $localize`s`;
      this.label += $localize`s`;
      this.placeholder += $localize`s`;
    }
    this.practiceService.withCommunity = this.withCommunity;
    this.practiceService.fetchInitialPracticesOptions();
    this.practiceService.allOptionsSubject.subscribe(options => {
      this.options = options.filter(p => !p.parent_id)
      if (this.withCommunity) {
        this.options = this.options.filter(p => p.practiceCommunity)
      }
      this.setSelectedPractices();
    });
  }

  setSelectedPractices() {
    if (this.multiple) {
      this.selectedPractices = this.options.filter(option => this.values?.some(value => value === option.id));
    } else {
      this.selectedPractices = this.options.find(option => this.values?.some(value => value === option.id));
    }
    this.onSelectionChange(this.selectedPractices);
  }

  onSelectionChange(values: any[]) {
    if (Array.isArray(values)) {
      values = values.map(v => v.id)
    } else if (values) {
      values = [values['id']]
    } else {
      values = []
    }
    this.onChange(values);
  }

  compareWith(item, selected) {
    if (selected.id && item.id) {
      return item.id === selected.id;
    }
    return false;
  }

  remove(id: number) {
    this.selectedPractices = this.selectedPractices.filter(p => p.id !== id);
    this.onSelectionChange(this.selectedPractices);
  }

  writeValue(values: any): void {
    this.values = values;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
