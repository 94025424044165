import { Component, OnInit, Input } from '@angular/core';
import { PeopleService } from 'app/services/people/people.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-contributor-card',
  templateUrl: './contributor-card.component.html',
  styleUrls: ['./contributor-card.component.css']
})
export class ContributorCardComponent implements OnInit {
  @Input() member;
  constructor(public peopleService: PeopleService, private commonService: CommonMethodsService) { }

  ngOnInit() {
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}
