import { Component, OnInit } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  constructor(public breadcrumbsService: BreadcrumbService, public commonService: CommonMethodsService) { }

  ngOnInit(): void {
  }

}
