import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudPickerService {
  apiUrl = environment.apiUrl;
  // fileApiUrl = environment.fileApiUrl;
  CloudStorageUrl = environment.previewBaseUrl;
  driveBaseUrl = environment.driveBaseUrl;
  bucketPath = `/storage/v1/b/${environment.bucket}/o`;
  uploadProgress = 0;
  upload = false;

  previewUpdate = new EventEmitter();

  constructor(private http: HttpClient) { }

  getToken() {
    return this.http.get(this.apiUrl + '/v1/files/token');
  }

  uploadFile(filePath, file, token) {
    let params = new HttpParams();
    params = params.append('uploadType', 'media');
    params = params.append('name', filePath);

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': file.type
    });
    return this.http.post(this.CloudStorageUrl + '/upload' + this.bucketPath, file, { params, headers, 'reportProgress': true, observe: 'events' })
  }

  getFiles(prefix, token) {
    let params = new HttpParams();
    params = params.append('prefix', prefix);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.get(this.CloudStorageUrl + this.bucketPath, { params, headers })
  }

  getAclData(url, token) {
    let params = new HttpParams();
    params = params.append('projection', 'full');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers, params })
  }

  downloadDriveFile(fileId, token) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    let params = new HttpParams();
    params = params.append('alt', 'media');
    return this.http.get(this.driveBaseUrl + `/${fileId}`, { headers, params, responseType: "blob" })
  }

  downloadDriveGoogleDocFile(fileId, mimeType, token) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    let params = new HttpParams();
    params = params.append('mimeType', mimeType);
    return this.http.get(this.driveBaseUrl + `/${fileId}` + '/export', { headers, params, responseType: "blob" })
  }

  updateAcl(url, metaData, token) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });
    return this.http.patch(url, metaData, { headers })
  }

  // uploadFile(data) {
  //   return this.http.post(this.fileApiUrl + '/v1/cloudstorage/upload', data);
  // }

  // selectFile(data) {
  //   return this.http.post(this.fileApiUrl + '/v1/cloudstorage/select', data);
  // }

  // listFiles(fileKey) {
  //   let params = new HttpParams();
  //   params = params.append('file_key', fileKey);
  //   return this.http.get(this.fileApiUrl + '/v1/cloudstorage', { params });
  // }

  updatePreview(data) {
    return this.http.patch(this.apiUrl + '/v1/nuggets/preview', data);
  }

  getSignedUrl(url) {
    let params = new HttpParams();
    params = params.append('url', url);
    return this.http.get(this.apiUrl + '/v1/files/signedUrl', { params });
  }

  getMetaData(url) {
    let params = new HttpParams();
    params = params.append('url', url);
    return this.http.get(this.apiUrl + '/v1/files/metaData', { params });
  }

  getPrefix(fileKey) {
    let prefix = ""

    if (process.env.NODE_ENV !== "production") {
      prefix += "dev/"
    }

    const key = fileKey.split("-")
    if (key[0] == "preview") {
      if (key[1] == "hero") {
        prefix += `images/preview_gallery/hero_banners/home/`
      } else if (key[1] == "accelerators") {
        prefix += `images/preview_gallery/hero_banners/accelerators/`
      } else if (key[1] == "technologySelectionBanner") {
        prefix += `images/preview_gallery/hero_banners/technology_selections/`
      } else if (key[1] == "technologySelection") {
        prefix += `images/preview_gallery/technology_selections/`
      } else if (key[1] == "assessmentFramework") {
        prefix += `images/preview_gallery/assessment_frameworks/`
      } else {
        prefix += `images/preview_gallery/${key[1]}/`
      }
    } else if (key[0] == "nugget") {
      prefix += `nuggets/`

      if (key[1] == "caseStudie") {
        prefix += "case-studies/"
      } else if (key[1] == "poc") {
        prefix += "pocs/"
      } else if (key[1] == "techDocument") {
        prefix += "tech-documents/"
      } else if (key[1] == "techTalk") {
        prefix += "tech-talks/"
      } else if (key[1] == "deck") {
        prefix += "decks/"
      } else if (key[1] == "documents") {
        prefix += "documents/"
      } else if (key[1] == "technologySelection") {
        prefix += "technology-selections/"
      }
      if (key[2] == "image") {
        prefix += "images/"
      } else if (key[2] == "doc") {
        prefix += "documents/"
      } else if (key[2] == "video") {
        prefix += "videos/"
      }
    }
    return prefix
  }

  getFileType(contentType) {
    if (
      ['image/jpg',
        'image/jpeg',
        'image/png'].includes(contentType)) {
      return "image";
    } else if (
      ['video/mp4',
        'video/3gpp',
        'video/quicktime',
        'video/x-matroska',
        'video/webm',
        'video/x-m4v',
        'application/vnd.google-apps.video'].includes(contentType)) {
      return "video";
    } else if (
      ['application/pdf',
        'text/plain'].includes(contentType)) {
      return "pdf";
    } else if (
      [
        'application/vnd.google-apps.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.google-apps.presentation',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.google-apps.spreadsheet',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(contentType)) {
      return "office";
    } else {
      return "google";
    }
  }

  getFileKey(contentType, nuggetType) {
    const fileType = this.getFileType(contentType);
    if (fileType == "image") {
      if (nuggetType == "practice") {
        return "preview" + "-" + "practice_icons";
      } else {
        return "nugget" + "-" + nuggetType + "-" + "image";
      }
    } else if (fileType == "video") {
      return 'nugget' + "-" + nuggetType + "-" + "video"
    } else {
      return "nugget" + "-" + nuggetType + "-" + "doc";
    }
  }

  download(url) {
    if (url.includes('storage.cloud.google.com')) {
      this.getSignedUrl(url)
        .subscribe(signedUrl => {
          window.open(signedUrl['signed_url'], '_blank')
        })
    } else {
      window.open(url, '_blank')
    }
  }

  filterFileDetails(metaData, fileKey, fileName) {
    const key = fileKey.split("-")
    let baseUrl;
    if (key[0] == "preview") {
      baseUrl = environment.previewBaseUrl
    } else if (key[0] == "nugget") {
      baseUrl = environment.nuggetBaseUrl
    }
    const bucketName = metaData.bucket
    const name = metaData.name
    const authenticatedUrl = `${baseUrl}/${bucketName}/${name}`
    const mimetype = metaData.contentType
    const size = metaData.size
    return {
      authenticated_url: authenticatedUrl,
      name: name,
      original_name: fileName,
      mime_type: mimetype,
      size: size,
    }
  }
}
