<form [formGroup]="form" (submit)="onSubmit()" *ngIf="form" class="mt-20">
  <div class="form-group">
    <app-person-options [form]="form" controlName="assignee" label="" [submitted]="invalidForm"
      [excludeLoginUser]="true" i18n-placeholder placeholder="Specify name"
      dropdownClass="modal-dropdown"></app-person-options>
  </div>

  <!-- http errors -->
  <div class="form-group" *ngIf="httpError">
    <div class="invalid">
      {{httpError}}
    </div>
  </div>

  <div class="form-group mb-0">
    <button type="submit" class="btn btn-primary" [disabled]="submitted"
      [ngClass]="{'submitted':submitted}">Assign</button>
    <button type="button" class="btn btn-light ml-15" (click)="closePopup()">Cancel</button>
  </div>
</form>