<div [formGroup]="group" class="date-field">
  <ng-container *ngIf="field.name === 'from';else to;">
    <input name="dp" [maxDate]="filterService.maxDate" ngbDatepicker #d="ngbDatepicker" container="body"
      [formControlName]="field.name" [placeholder]="field.label" autocomplete="off" (click)="d.toggle()">
    <button class="calander-icon" (click)="d.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
  </ng-container>

  <ng-template #to>
    <input name="dp" [minDate]="filterService.minDate" ngbDatepicker #d="ngbDatepicker" container="body"
      [formControlName]="field.name" [placeholder]="field.label" autocomplete="off" (click)="d.toggle()">
    <button class="calander-icon" (click)="d.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
  </ng-template>
</div>