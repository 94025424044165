import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export class TechDocumentsService {
  apiUrl = environment.apiUrl;
  updatedId = 0;

  constructor(private http: HttpClient) { }
  // get tech documents list
  getTechDocuments(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/tech-documents', { params, headers });
  }

  // get tech document info
  getTechDocument(id, params?) {
    return this.http.get(this.apiUrl + `/v1/tech-documents/${id}`, { params });
  }

  // get tech-document-types
  @Cacheable()
  getTechDocumentTypes() {
    return this.http.get(this.apiUrl + '/v1/tech-document-types');
  }

  getRecommendation(id) {
    return this.http.get(this.apiUrl + `/v1/tech-documents/${id}/recommended`);
  }

  submitTechDocument(data) {
    return this.http.post(this.apiUrl + '/v1/tech-documents', data);
  }

  updateTechDocument(id, data) {
    return this.http.put(this.apiUrl + `/v1/tech-documents/${id}`, data);
  }

  approveReject(id, type, comments) {
    const data = {
      type,
      comments
    };
    return this.http.post(this.apiUrl + `/v1/tech-documents/${id}/status`, data);
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + `/v1/tech-documents/${id}`);
  }
}

export interface TechDocs {
  tech_documents: [];
  total_count: number;
}
