import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FilterService } from '../filter/filter.service';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  apiUrl = environment.apiUrl;
  visitorLogEvent = new EventEmitter();

  constructor(private http: HttpClient, private filterService: FilterService, private loginService: LoginService) { }

  getPassword(params) {
    return this.http.get(this.apiUrl + '/v1/stored-password', { params });
  }

  saveVisitorLog(data) {
    return this.http.post(this.apiUrl + '/v1/visitor-logs', data);
  }

  getViewCount(id, params?) {
    return this.http.get(this.apiUrl + `/v1/visitor-logs/${id}/counter`, { params });
  }

  exportCounts(id, title = $localize`View & Download Count`, entityCategoryId?) {
    this.filterService.fileName = title;
    this.filterService.apiFilters = [{ key: $localize`title`, values: [title] }];
    if (entityCategoryId) {
      this.filterService.apiFilters.push({ key: 'entity_category_id', values: [entityCategoryId] });
    }
    this.filterService.exportUri = `/v1/visitor-logs/${id}/export`;
    this.filterService.export();
  }

  showExport(ids?) {
    if (this.loginService.isPrivilegedUser(ids)) {
      return true;
    }
    return false;
  }

  loginViewCount() {
    if (this.loginService.currentUser['person']['login']) {
      return 1;
    }
    return 0;
  }

  getVersion() {
    return this.http.get(this.apiUrl + '/v1/app-version');
  }

  syncDocuments(params) {
    return this.http.get(this.apiUrl + '/v1/sync-documents', { params });
  }
}
