import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { environment } from 'environments/environment';
import { PeopleService } from 'app/services/people/people.service';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-person-options',
  templateUrl: './person-options.component.html',
  styleUrls: ['./person-options.component.css']
})
export class PersonOptionsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() label = $localize`Authors`;
  @Input() placeholder = $localize`Select author`;
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() optional = false;
  @Input() disabled = false;
  @Input() dropdownParent = '';
  @Input() excludeLoginUser = false;
  @Input() primaryPerson;
  @Input() allowHP = false;
  @Input() dropdownClass = '';
  @Input() scroll = false;

  apiUrl = environment.apiUrl;
  people;
  options: Options;
  selectedPeople = [];
  touched = false;
  data = [];
  dataOption = [];
  values: string[];

  constructor(private peopleService: PeopleService) { }

  ngOnInit() {
    if (this.value && this.value.length) {
      this.data = this.value.map((person) => ({
        id: (person.person_id || 0).toString(),
        login: person.login || this.getLoginId(person),
        text: this.peopleService.getPersonName(person),
        title: this.peopleService.getPersonTitle(person),
        email: person.contact ? person.contact.email_id : person.email_id,
        photo_url: person.photo_url
      })
      );
      this.dataOption = this.data;

      setTimeout(() => {
        this.values = this.data.map(l => l.id.toString())
        // if (this.multiple) {
        //   this.values = this.data.map(v => v.id.toString());
        // } else {
        //   this.values = this.data[0]?.id;
        // }
        // this.onSelect({ data: this.data });
      }, 0);
    }

    this.options = {
      tags: false,
      multiple: this.multiple,
      placeholder: this.placeholder,
      language: { errorLoading: () => $localize`Searching...` },
      minimumInputLength: 1,
      dropdownParent: (this.dropdownParent ? $(`#acticity-artifact`) : $(`body`)),
      dropdownCssClass: this.dropdownClass,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/people/autocomplete',
        data: (params) => ({
          'filters[search]': params.term,
          'filters[except_current_person]': this.excludeLoginUser,
          page: params.page || 1
        }),
        processResults: (data) => {
          this.dataOption = data.people;
          if (!this.allowHP)
            this.dataOption = this.dataOption.filter(p => this.primaryPerson?.login != p.login)

          this.dataOption = this.dataOption.map((person) => ({
            id: (person.person_id || 0).toString(),
            login: person.login || this.getLoginId(person),
            text: this.peopleService.getPersonName(person),
            title: this.peopleService.getPersonTitle(person),
            email: person.contact.email_id,
            photo_url: person.photo_url
          }));

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 20) < data.total_count
            }
          };
        }
      },
      escapeMarkup: (markup) => markup,
      templateResult: this.formatRepo,
    };
  }

  getLoginId(person) {
    const [login] = person.contact.email_id.split('@')
    return login;
  }

  formatRepo(repo) {
    if (repo.loading) {
      return repo.text;
    }
    const markup = `<div class="avtar-list">
      <span class="avatar avatar-sm"><img src="${repo.photo_url}"></span>
      <div class="avtar-body"><span> ${repo.text}</span>&nbsp; | &nbsp;<span class="role">${repo.email}</span></div>
      </div>` ;
    return markup;
  }

  valueChanged(event) {
    if (event) {
      this.onSelect({
        data: this.dataOption.filter(o =>
          Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
      });
    }
  }

  ngOnChanges() {
    // if (this.primaryPerson) {
    // this.onSelect({ data: this.form.get(this.controlName).value });
    // }
  }

  onSelect(event) {
    const people = event.data
      .filter(p => p.login)
      .map(person => ({
        id: person.id,
        login: person.login,
        text: person.text,
        email: person.email,
        photo_url: person.photo_url,
        title: person.title
      }));

    if (people.length) {
      this.touched = true;
      if (this.multiple) {
        this.selectedPeople = lodash.uniqBy((this.form.get(this.controlName).value || []).concat(people), 'login');
      } else {
        this.selectedPeople = people;
      }

      this.selectedPeople = this.selectedPeople
        .filter(p => this.primaryPerson?.login != p.login);

      if (!isEqual(this.selectedPeople, this.data)) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(this.selectedPeople);
      // $('#' + this.controlName).val(null).trigger('change');
      if (this.selectedPeople.length) {
        if (this.multiple) {
          setTimeout(() => {
            const input = document.querySelector(`.${this.controlName}-options .select2-search__field`) as HTMLInputElement
            input.placeholder = this.placeholder;
            input.style.width = 'auto';
          }, 0)
        } else {
          const input = document.querySelector(`.${this.controlName}-options .select2-selection__rendered`) as HTMLInputElement
          input.innerText = ``;
          setTimeout(() => {
            const input = document.querySelector(`.${this.controlName}-options .select2-selection__rendered`) as HTMLInputElement
            input.innerHTML = `<span class="select2-selection__placeholder">${this.placeholder}</span>`;
          }, 0);
        }
      }
    }
  }

  remove(id) {
    this.touched = true;
    this.selectedPeople = this.form.get(this.controlName).value.filter(person => person.id !== id);
    if (!isEqual(this.selectedPeople, this.data)) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(this.selectedPeople);
    // $('#' + this.controlName + ' option[value="' + id + '"]').remove();
    if (this.multiple)
      this.values = this.selectedPeople.map(l => l.id.toString())
  }
}
