import { LoginService } from '../auth/login.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, Resolve } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardLoginService implements CanActivate {

  constructor(protected router: Router, protected authService: LoginService, private jwtHelper: JwtHelperService,
    private route: ActivatedRoute) { }

  canActivate() {
    if (!this.jwtHelper.isTokenExpired(localStorage.getItem('refreshToken'))) {
      return this.authService.getToken().pipe(
        map(response => {
          if (response['jwtToken']) {
            const destination = localStorage.getItem('destination');
            localStorage.removeItem('destination');
            this.route.queryParamMap
              .subscribe(param => {
                if (param.has('cmsUrl')) {
                  parent.postMessage('login', param.get('cmsUrl'));
                } else {
                  this.router.navigateByUrl(destination || '/');
                }
              });
            return false;
          } else {
            this.authService.clear();
            return true;
          }
        }),
        catchError(() => {
          this.authService.clear();
          return of(true);
        })
      );
    } else {
      this.authService.clear();
      return true;
    }
  }
}

