<div class="search-tag-wrapper row ml-15">
  <ng-container *ngFor="let filter of filtersList | slice:0:displayNumber">
    <span class="search-tag" *ngIf="filter.name && (hiddenChipTypes.indexOf(filter.filterType) < 0)">
      <span [innerHTML]="filter.name"></span>
      <a href="javascript:" (click)="filterService.removeFilter(filter)">X</a></span>
  </ng-container>
  <ng-container *ngIf="moreChips > 0">
    <span class="search-tag search-tag-more" (click)="open()" appStopPropagation i18n>+{{moreChips}}
      more</span>
  </ng-container>
</div>
<div *ngIf="allowClearAll" class="filter-chip-action">
  <button class="btn btn-primary" (click)="filterService.clearFilters()" *ngIf="filtersList.length">Clear
    Filters</button>
</div>