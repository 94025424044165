import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-nuggets-preview-gallery',
  templateUrl: './nuggets-preview-gallery.component.html',
  styleUrls: ['./nuggets-preview-gallery.component.css']
})
export class NuggetsPreviewGalleryComponent implements OnChanges {
  @Input() type;
  @Input() form: UntypedFormGroup;
  @Input() controlName;
  @Input() value;
  @Input() label;
  @Input() primaryPractice = [];
  previewData;

  constructor() { }

  ngOnChanges() {
    this.previewData = { type: this.type, form: this.form, controlName: this.controlName, value: this.value, primaryPractice: this.primaryPractice && this.primaryPractice[0]};
  }
}
