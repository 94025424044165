<form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
  <!-- locations -->
  <div class="form-group">
    <app-location-options [form]="form" controlName="locations" [value]="data['locations']" [multiple]="true"
      dropdownClass="modal-dropdown">
    </app-location-options>
  </div>

  <!-- http errors -->
  <div class="form-group" *ngIf="httpError">
    <div class="invalid" *ngFor="let error of httpError">
      {{error['message']}}
    </div>
  </div>

  <div class="form-group mb-0">
    <button type="submit" class="btn btn-primary" [disabled]="submitted" [ngClass]="{'submitted':submitted}"
      i18n>Confirm</button>
    <button type="button" class="btn btn-light ml-15" (click)="cancel()" i18n>Cancel</button>
  </div>
</form>