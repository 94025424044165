import { Component, Input, OnInit } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { PracticesService } from 'app/services/practices/practices.service';
import { LocationsService } from 'app/services/locations/locations.service';
import { PeopleService } from 'app/services/people/people.service';
import { FilterService } from 'app/services/filter/filter.service';
import { TechDocumentsService } from 'app/services/tech-documents/tech-documents.service';
import { PocsService } from 'app/services/pocs/pocs.service';
import { CmsService } from 'app/services/cms/cms.service';
import { TechTalksService } from 'app/services/tech-talks/tech-talks.service';
import { DigitalAcceleratorsService } from 'app/modules/feature/digital-accelerator/digital-accelerators.service';
import { forkJoin } from 'rxjs';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-facets',
  templateUrl: './facets.component.html',
  styleUrls: ['./facets.component.css']
})
export class FacetsComponent implements OnInit {
  //filterConfig: FieldConfig[] = [];
  practicesLodded = false;
  locationsLodded = false;
  industriesLodded = false;
  memberTypeLodded = false;
  techDocumentTypesLodded = false;
  languagesLodded = false;
  pocStatusesLodded = false;
  pocTypesLodded = false;
  pageTypesLodded = false;
  @Input() tab = '';

  constructor(private practicesService: PracticesService, private locationsService: LocationsService,
    private peopleService: PeopleService, public filterService: FilterService, private techDocumentService: TechDocumentsService,
    private pocService: PocsService, private cmsService: CmsService,
    private techTalkService: TechTalksService, private digitalAcceleratorService: DigitalAcceleratorsService) { }

  ngOnInit() {
    this.filterService.pageFilters.forEach(filter => {
      if (this.filterService.fieldMap[filter].type === 'facetOptions') {
        if (filter === 'practices_facet') {
          this.practicesService.getPractices()
            .subscribe(response => {
              const options = orderBy(response['rows'], 'type.c_name')
                .map((item, index, array) => (
                  {
                    ...item,
                    groupBy: item.type.name == 'Vertical' ? "Industries" : "Practices",
                    first: array.findIndex(i => i.type.c_name === 'vertical') === index
                  }
                ))
              this.filterService.fieldMap[filter]['options'] = options;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams('practices_facet');
              $(function () {
                if (window.innerWidth >= 576) {
                  $('.open').slideDown('fast');
                  $('.open').siblings('.chevron-down').hide();
                  $('.open').siblings('.chevron-up').show();
                } else {
                  $('.open').slideUp('fast');
                  $('.open').siblings('.chevron-down').show();
                  $('.open').siblings('.chevron-up').hide();
                  $('.open').removeClass('open');
                }
              });

              this.practicesLodded = true;
            });
        } else if (filter === 'locations_facet') {
          this.locationsService.getLocations()
            .subscribe(response => {
              const options = response['rows'].map(c => {
                c['name'] = c.full_name;
                return c;
              });
              this.filterService.fieldMap[filter]['options'] = options;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);

              this.locationsLodded = true;
            });
        } else if (filter === 'industries_facet') {
          this.digitalAcceleratorService.getIndustries()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['industries']
                .map(i => ({ id: i.id, name: i.name }));
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);

              this.industriesLodded = true;
            });
        } else if (filter === 'member_types_facet') {
          this.peopleService.getMemberType()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);

              this.memberTypeLodded = true;
            });
        } else if (filter === 'tech_document_types_facet') {
          this.techDocumentService.getTechDocumentTypes()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);

              this.techDocumentTypesLodded = true;
            });
        } else if (filter === 'languages_facet') {
          this.techTalkService.getLanguages()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'].map(l => {
                l['name'] = l.language;
                return l;
              });
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
              this.languagesLodded = true;
            });
        } else if (filter === 'poc_statuses_facet') {
          this.pocService.getStatuses()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);

              this.pocStatusesLodded = true;
            });
        } else if (filter === 'poc_types_facet') {
          this.pocService.getPocTypes()
            .subscribe(response => {
              this.filterService.fieldMap[filter]['options'] = response['rows'];
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);

              this.pocTypesLodded = true;
            });
        } else if (filter === 'page_types_facet') {
          this.cmsService.getPageTypes()
            .subscribe(response => {
              const options = response['parent_titles'].map(parent => ({ id: parent, name: parent }));
              this.filterService.fieldMap[filter]['options'] = options;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
              this.pageTypesLodded = true;
            });
        } else if (filter === 'digital_accelerator_categories_facet') {
          const filterData = [{
            key: 'filters[c_names][]',
            values: ['overview_offering', 'assessment_framework', 'solution_accelerator', 'industry_solution',
              'technology_selection', 'curated_content', 'technology_radar', 'partnership']
          }];
          const params = this.filterService.getApiParams(filterData);
          forkJoin([this.digitalAcceleratorService.getAcceleratorTypes(params), this.cmsService.getPageTypes()])
            .subscribe(response => {
              let options = response[0]['rows'].map(row => ({ id: row.id, name: row.name }));
              options.push(...response[1]['parent_titles']
                .filter(parent => !options.find(o => o.name === parent))
                .map(parent => ({ id: parent, name: parent })));
              options = orderBy(options, 'name')

              const index = options.findIndex(o => o.name === 'Others');
              const other = options.splice(index, 1);
              options.push(...other)
              this.filterService.fieldMap[filter]['options'] = options;
              this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
              this.filterService.setFilterByParams(filter);
              this.pageTypesLodded = true;
            });
        }
      } else {
        this.filterService.filterConfig.push(this.filterService.fieldMap[filter]);
        this.filterService.setFilterByParams(filter);
      }
    });

    const interval = setInterval(() => {
      if (this.practicesLodded && this.locationsLodded && this.industriesLodded && this.techDocumentTypesLodded && this.languagesLodded && this.memberTypeLodded && this.pocStatusesLodded && this.pocTypesLodded && this.pageTypesLodded) {
        this.toggleFilter();
        clearInterval(interval);
      }
    }, 500);
  }

  // ngOnChanges() {
  //   $(() => {
  //     if (this.tab === 'cms-page') {
  //       $('.facet-page_types').slideDown('slow').addClass('open');
  //       $(this).find('.chevron-up').show().siblings('.chevron-down').hide();
  //     }
  //   });
  // }

  toggleFilter() {
    $(function () {
      $('.facetOptions .filter-name').on("click", function () {
        if ($(this).siblings('.filter_list').hasClass('open')) {
          $(this).siblings('.filter_list').slideUp('slow').removeClass('open');
          $(this).find('.chevron-up').hide().siblings('.chevron-down').show();
        } else {
          $('.filter_list').slideUp('slow').removeClass('open');
          $('.chevron-up').hide();
          $('.chevron-down').show();

          $(this).siblings('.filter_list').slideDown('slow').addClass('open');
          $(this).find('.chevron-up').show().siblings('.chevron-down').hide();
        }
        return false;
      });
    });
  }
}
