<ng-container [formGroup]="artifactForm">
  <label><ng-container i18n>Upload Documents & Presentations</ng-container>
    <span class="help" *ngIf="optional" i18n>(Optional)</span></label>
  <div class="help" i18n>Attach all the documents and presentations, which you have used in this activity. System stores
    these documents in secure central repository</div>

  <div class="form-row" id="acticity-artifact">
    <!-- artifact type -->
    <div class="col-md-2">
      <select class="form-select" formControlName="type">
        <option value="" disabled hidden i18n>Artifact Type</option>
        <option [value]="type['id']" *ngFor="let type of artifactType">{{type['name']}}</option>
      </select>
    </div>

    <!-- is confidential -->
    <div class="col-md-2">
      <div class="custom-control custom-checkbox mr-sm-2 mt-10">
        <input type="checkbox" class="custom-control-input" id="confidential" formControlName="confidential">
        <label class="custom-control-label confidential" for="confidential">Confidential
          <span container="body" i18n-ngbTooltip
            ngbTooltip="Mark the attachment as confidential, if it is not for public view. In case of confidential, member of activity will have access to this attachment and specified owner will take care of all request permissions.">
            <i class="fas fa-question-circle"></i></span>
        </label>
      </div>
    </div>


    <!-- owner -->
    <div class="col-md-3 artifact-owner">
      <ng-template #ownerDisabled>
        <app-person-options i18n-placeholder placeholder="Select owner" label="" [form]="artifactForm"
          controlName="owner" [disabled]="true">
        </app-person-options>
      </ng-template>
      <app-person-options [form]="artifactForm" controlName="owner" i18n-placeholder placeholder="Select owner" label=""
        *ngIf="artifactForm.get('confidential').value;else ownerDisabled;" dropdownParent="#acticity-artifact">
      </app-person-options>
    </div>

    <!-- upload artifact -->
    <div class="col-md-5  md-mt-10">
      <app-google-picker [form]="artifactForm" controlName="document" [folderId]="artifactFolerId"
        [fileTypes]="artifactFileTypes" [value]="artifactForm.get('document').value" [mimeTypes]="artifactMimeTypes"
        [fileTypesHint]="false"
        *ngIf="artifactForm.get('type').value && (!artifactForm.get('confidential').value || artifactForm.get('confidential').value && artifactForm.get('owner').value); else picker;">
      </app-google-picker>
      <ng-template #picker>
        <div class="custom-file">
          <input type="file" class="custom-file-input" disabled="true">
          <label class="custom-file-label" i18n>Select a file</label>
        </div>
        <div class="help">
          <span class="fs-italic">{{artifactFileTypes.join(', ')}}</span>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- File URL -->
  <div class="artifact-type" appMatchHeight="artifact">
    <a class="artifact" [href]="artifact['url']" *ngFor="let artifact of selectedArtifacts" target="_blank">
      <div class="artifact-types">
        <span *ngIf="(artifact.is_confidential || +artifact.type === 2);else open;" i18n-ngbTooltip
          ngbTooltip="This artifact is marked as confidential. It can be viewed only by authorized users."
          container="body" tooltipClass="lock-tootip"><i class="fas fa-lock"></i></span>
        <ng-template #open>
          <span i18n-ngbTooltip
            ngbTooltip="This artifact is marked as public. It can be viewed by all Globallogic users." container="body"
            tooltipClass="lock-tootip"><i class="fas fa-lock-open"></i></span>
        </ng-template>
        {{getArtifactType(artifact.type)}}
        <div class="remove">
          <a href="javascript:;" class="circle-actions" (click)="remove($event,artifact['id'])" appStopPropagation><i
              class="fas fa-times"></i></a>
        </div>
        <label class="artifact-label">{{artifact['fileName'] |truncate : 25}} </label>
        <label class="artifact-label" *ngIf="(artifact.is_confidential || +artifact.type === 2)">
          <a [routerLink]="['/team',artifact['owner']['login']]" appStopPropagation target="_blank"
            *ngIf="artifact['owner'] && artifact['owner']['login'];else name;">{{artifact['owner']['text']}}</a>
          <ng-template #name>{{artifact['owner']['text']}}</ng-template>
        </label>
      </div>
    </a>
  </div>

</ng-container>