import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-practices-chip',
  templateUrl: './practices-chip.component.html',
  styleUrls: ['./practices-chip.component.css']
})
export class PracticesChipComponent implements OnInit, OnChanges {
  @Input() practices;
  @Input() data;
  @Input() displayLength = 40;
  practicesList = [];
  displayNumber = 0;
  moreSkills = 0;

  constructor(private modalService: ModalPopupService) { }

  ngOnInit(): void {
    if (this.data) {
      this.practices = this.data;
      this.displayLength = 1000;
      this.ngOnChanges();
    }
  }

  ngOnChanges() {
    if (this.practices) {
      if (this.practices?.length === undefined) {
        this.practicesList.push(this.practices);
      } else {
        this.practicesList = this.practices;
      }
    }

    // get no of practices display
    let practicesName = '';
    for (const practice of this.practicesList) {
      practicesName += practice['name'] + ' ';
      if (practicesName.length > (this.displayLength - 6)) {
        break;
      }
      practicesName += ' ';
      this.displayNumber++;
    }
    // check is only one practice left and it can be display
    if (this.practicesList.length === (this.displayNumber + 1)) {
      if (practicesName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }

    this.moreSkills = this.practicesList.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Practices`, message: '', component: 'PracticesChipComponent', data: this.practicesList };
    this.modalService.open(data);
  }
}
