import { Component, OnInit } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { FilterService } from 'app/services/filter/filter.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;

  constructor(public filterService: FilterService) { }

  ngOnInit() {
  }
}
