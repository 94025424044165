<!-- <div [formGroup]="form">
    <label for="industry-segment-options" *ngIf="!hideLabel">{{label}}<span class="help" *ngIf="optional"
            i18n>(Optional)</span></label>
    <div class="help" *ngIf="helpText">{{helpText}}</div>
    <table class="table table-responsive-md">
        <thead>
            <tr>
                <th class="published-on">Industries</th>
                <th class="marketplace-url">Segments</th>
                <th class="marketplace-action"></th>
            </tr>
        </thead>
        <tbody formArrayName="industries" class="">
            <tr *ngFor="let industryForm of industries.controls; let i = index" class="tr-tg-row">
                <ng-container [formGroup]="industryForm">
                    <td>
                        <select class="form-select" formControlName="id" (blur)="onTouched()" (change)="onSelect(i)"
                            [ngClass]="{'validate': industryForm.get('id').invalid && (industryForm.get('id').touched || invalidForm)}">
                            <option value="">Select industry</option>
                            <option *ngFor="let ind of industriesOptions[i]" [value]="ind.id">{{ind.name}}</option>
                        </select>

                        <div class="invalid"
                            *ngIf="industryForm.get('id').invalid && (industryForm.get('id').touched || invalidForm)">
                            <div *ngIf="industryForm.get('id').errors.required">
                                Select industry
                            </div>
                            <div *ngIf="industryForm.get('id').errors.duplicate">
                                Duplicate industry
                            </div>
                        </div>
                    </td>
                    <td>
                        <div
                            [ngClass]="{'validate':industryForm.get('industry_segments').invalid && (industryForm.get('industry_segments').touched || invalidForm) }">
                            <ng-select2 [data]="selectedIndustries[i]?.industry_segments" [options]="options"
                                formControlName="industry_segments" class="custom-select-field segment-options"
                                width="100%"></ng-select2>
                            <div class="invalid"
                                *ngIf="industryForm.get('industry_segments').invalid && (industryForm.get('industry_segments').touched || invalidForm)">
                                <div *ngIf="industryForm.get('industry_segments').errors.required">
                                    Select segments
                                </div>

                            </div>
                        </div>
                    </td>

                    <td>
                        <button class="circle-actions" type="button" (click)="deleteRow(i)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </ng-container>
            </tr>

            <tr *ngIf="industries.length < industriesList.length">
                <td colspan="6">
                    <button class="btn btn-border" type="button" (click)="addRow()">Add</button>
                </td>
            </tr>
        </tbody>
    </table>
</div> -->

<label for="industry-segment-options" *ngIf="!hideLabel">
    {{label}}
    <span class="help" *ngIf="optional" i18n>(Optional)</span>
    <div class="help" *ngIf="helpText">{{helpText}}</div>
</label>

<ng-select [items]="filteredIndustries" [multiple]="true" bindLabel="name" groupBy="industry"
    notFoundText="No result found" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
    [clearable]="false" [searchable]="false" (change)="valueChange()" [(ngModel)]="value" [placeholder]="placeholder"
    [hideSelected]="true" class="industry-segment-options">
    <ng-template ng-optgroup-tmp let-item="item">
        {{item.industry}}
    </ng-template>
    <ng-template ng-multi-label-tmp>
        <span style="display:block; top:10px" class="ng-placeholder">{{placeholder}}</span>
    </ng-template>
</ng-select>

<div class="search-tag-wrapper row">
    <span class="search-tag" *ngFor="let v of value">{{v.name}}<a (click)="remove(v.id)">X</a></span>
</div>