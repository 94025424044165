import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuardService } from './services/guards/auth-guard.service';
import { AuthGuardLoginService } from './services/guards/auth-guard-login.service';

import { LoginComponent } from './components/login/login.component';
import { GetTokenComponent } from './components/get-token/get-token.component';
import { HomeComponent } from './components/home/home.component';
import { SubmitProspectComponent } from './components/customer-activities/submit-prospect/submit-prospect.component';
import { CmsLoginComponent } from './components/cms/cms-login/cms-login.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: $localize`Login` }, canActivate: [AuthGuardLoginService] },
  { path: 'cms-login', component: CmsLoginComponent, data: { title: $localize`Login` } },
  { path: 'login/callback', component: LoginCallbackComponent, data: { title: $localize`Login` } },
  {
    path: '', canActivate: [AuthGuardService], canActivateChild: [AuthGuardService], data: { breadcrumb: $localize`Home` },
    children: [
      {
        path: '', component: HomeComponent, data: {
          title: $localize`Practices`, breadcrumb: '', fullWidth: true, hideBreadcrumb: true
        }
      },
      { path: 'customer/add', component: SubmitProspectComponent, data: { breadcrumb: $localize`Submit Prospect` } },

      { path: '', loadChildren: () => import('./modules/feature/carousel/carousel.module').then(m => m.CarouselModule) },
      { path: '', loadChildren: () => import('./modules/feature/search/search.module').then(m => m.SearchModule) },
      { path: '', loadChildren: () => import('./modules/feature/practice/practice.module').then(m => m.PracticeModule) },
      { path: '', loadChildren: () => import('./modules/feature/poc/poc.module').then(m => m.PocModule) },
      { path: '', loadChildren: () => import('./modules/feature/tech-talk/tech-talk.module').then(m => m.TechTalkModule) },
      { path: '', loadChildren: () => import('./modules/feature/tech-document/tech-document.module').then(m => m.TechDocumentModule) },
      { path: '', loadChildren: () => import('./modules/feature/blog/blog.module').then(m => m.BlogModule) },
      // { path: '', loadChildren: () => import('./modules/feature/event/event.module').then(m => m.EventModule) },
      { path: '', loadChildren: () => import('./modules/feature/case-study/case-study.module').then(m => m.CaseStudyModule) },
      { path: '', loadChildren: () => import('./modules/feature/person/person.module').then(m => m.PersonModule) },
      { path: '', loadChildren: () => import('./modules/feature/location/location.module').then(m => m.LocationModule) },
      { path: '', loadChildren: () => import('./modules/feature/support/support.module').then(m => m.SupportModule) },
      { path: '', loadChildren: () => import('./modules/feature/newsletter/newsletter.module').then(m => m.NewsletterModule) },
      { path: '', loadChildren: () => import('./modules/feature/admin/admin.module').then(m => m.AdminModule) },
      { path: '', loadChildren: () => import('./modules/feature/tag/tag.module').then(m => m.TagModule) },
      { path: '', loadChildren: () => import('./modules/feature/sysadmin/sysadmin.module').then(m => m.SysadminModule) },
      { path: 'assessments', loadChildren: () => import('./modules/feature/assessment/assessment.module').then(m => m.AssessmentModule) },
      { path: '', loadChildren: () => import('./modules/feature/cms/cms.module').then(m => m.CmsModule) },
      { path: 'partners', loadChildren: () => import('./modules/feature/partnership/partnership.module').then(m => m.PartnershipModule) },
      { path: '', loadChildren: () => import('./modules/feature/digital-accelerator/digital-accelerator.module').then(m => m.DigitalAcceleratorModule) },
      { path: 'technology-radar', loadChildren: () => import('./modules/feature/tech-radar/tech-radar.module').then(m => m.TechRadarModule) },
      // { path: 'technology-selections', loadChildren: () => import('./modules/feature/technology-selection/technology-selection.module').then(m => m.TechnologySelectionModule) },
      { path: 'initiatives', loadChildren: () => import('./modules/feature/initiative/initiative.module').then(m => m.InitiativeModule) },
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  providers: [],
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
