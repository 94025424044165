import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from 'app/services/auth/login.service';
import { DriveService } from 'app/services/drive/drive.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.css']
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
  cmsUrl = environment.cmsUrl;

  constructor(private loginService: LoginService, private route: ActivatedRoute, private router: Router, private renderer: Renderer2,
    private jwtHelper: JwtHelperService, private commonService: CommonMethodsService, private driveService: DriveService) { }

  ngOnInit(): void {
    this.commonService.loaderEvent.emit(true);
    const locale = localStorage.getItem('locale');
    if (locale && $localize.locale !== locale) {
      localStorage.removeItem('locale');
      window.open(`/${locale}/login-callback`, '_self');
    }

    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.signIn(token);
    }
  }

  signIn(token) {
    const source = localStorage.getItem('loginSource');
    const destination = localStorage.getItem('destination');
    if (source && source == 'cms') {
      this.loginService.getToken(token)
        .subscribe(response => {
          const url = this.cmsUrl + '?t=' + response['cmsJwtToken'] + (destination ? `&redirect_to=${destination}` : '')
          window.open(url, '_self');
        }, () => {
          const url = this.cmsUrl + 'wp-login.php' + (destination ? `?redirect_to=${destination}` : '')
          localStorage.setItem('error', 'invalid_user');
          window.open(url, '_self');
        });
    } else {
      localStorage.setItem('refreshToken', token);
      this.loginService.getToken()
        .subscribe(response => {
          localStorage.setItem('accessToken', response['jwtToken']);
          localStorage.setItem('cmsToken', response['cmsJwtToken']);
          this.loginService.currentUser = this.jwtHelper.decodeToken(response['jwtToken']);
          this.driveService.checkDriveAccess();
          this.renderer.removeClass(document.body, 'bg');
          this.router.navigateByUrl(destination || '/');
        }, (e) => {
          localStorage.setItem('error', 'invalid_user');
          this.router.navigate(['/login'], { queryParams: { destination } });
        });
    }

    localStorage.removeItem('loginSource');
    localStorage.removeItem('destination');
  }

  ngOnDestroy() {
    this.commonService.loaderEvent.emit(false);
  }
}
