import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-practices-avatar',
  templateUrl: './practices-avatar.component.html',
  styleUrls: ['./practices-avatar.component.css']
})
export class PracticesAvatarComponent implements OnInit {
  @Input() practices = [];

  constructor() { }

  ngOnInit() {
  }

}
