<div class="nugget-item nugget-item-list ca-card">
  <content-loader height="65" width="1500" primaryColor="#eee" secondaryColor="#ddd">
    <svg:rect x="0" y="15" width="150" height="40" />
    <svg:rect x="250" y="26" rx="5" ry="5" width="250" height="14" />
    <svg:circle cx="700" cy="32" r="22" />
    <svg:circle cx="750" cy="32" r="22" />
    <svg:circle cx="800" cy="32" r="22" />
    <svg:circle cx="850" cy="32" r="22" />
    <svg:rect x="950" y="25" rx="9" ry="9" width="100" height="18" />
    <svg:rect x="1060" y="25" rx="9" ry="9" width="100" height="18" />
    <svg:rect x="1300" y="25" rx="9" ry="9" width="150" height="18" />
  </content-loader>
</div>
