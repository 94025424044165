<label for="{{htmlId}}">
    {{ label }}
    <span class="help" *ngIf="optional" i18n>(Optional)</span>
    <div class="help" *ngIf="helpText">{{helpText}}</div>
</label>

<ng-select [items]="options" bindLabel="label" groupBy="parent" [multiple]="multiple" [hideSelected]="true"
    [closeOnSelect]="!multiple" [selectableGroup]="false" [(ngModel)]="selectedPractices" [compareWith]="compareWith"
    (change)="onSelectionChange($event)" [placeholder]="placeholder">
    <ng-template ng-optgroup-tmp let-item="item">
        {{item.parent}}
    </ng-template>
    <ng-template ng-multi-label-tmp>
    </ng-template>
</ng-select>

<div class="search-tag-wrapper row" *ngIf="selectedPractices.length">
    <span class="search-tag" *ngFor="let practice of selectedPractices">{{practice.label}}<a
            (click)="remove(practice.id)">X</a></span>
</div>