import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-partnership-card',
  templateUrl: './partnership-card.component.html',
  styleUrls: ['./partnership-card.component.css']
})
export class PartnershipCardComponent implements OnInit {
  @Input() data: any;
  @Input() editAccess = false;
  @Output() deleteEventEmitter = new EventEmitter<any>();
  

  constructor() { }

  ngOnInit(): void {
  }

  deletePartnership(event: Event, id: number, title: string) {
    this.deleteEventEmitter.emit({event, id, title});
  }

}
