import { Component, Input, OnChanges } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-technologies-chip',
  templateUrl: './technologies-chip.component.html',
  styleUrls: ['./technologies-chip.component.css']
})
export class TechnologiesChipComponent implements OnChanges {
  @Input() technologies;
  @Input() data;
  @Input() displayLength = 40;
  technologiesList = [];
  displayNumber = 0;
  moreTechnologies = 0;

  constructor(private modalService: ModalPopupService) { }

  ngOnInit(): void {
    if (this.data) {
      this.technologies = this.data;
      this.displayLength = 1000;
      this.ngOnChanges();
    }
  }

  ngOnChanges() {
    if (this.technologies?.length === undefined) {
      this.technologiesList.push(this.technologies);
    } else {
      this.technologiesList = this.technologies;
    }

    // get no of technologies display
    let technologiesName = '';
    for (const technology of this.technologiesList) {
      technologiesName += technology + ' ';
      if (technologiesName.length > (this.displayLength - 6)) {
        break;
      }
      technologiesName += ' ';
      this.displayNumber++;
    }
    // check is only one practice left and it can be display
    if (this.technologiesList.length === (this.displayNumber + 1)) {
      if (technologiesName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }

    this.moreTechnologies = this.technologiesList.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Technologies`, message: '', component: 'TechnologiesChipComponent', data: this.technologiesList };
    this.modalService.open(data);
  }

}
