import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'class'
})
export class ClassPipe implements PipeTransform {

  transform(string: string): any {
    return string.trim().replace(/ & /g, '-').replace(/ /g, '-').replace(/_/g, '-').toLowerCase();
  }

}
