import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment } from 'environments/environment';
import { CustomerActivityService } from 'app/services/customer-activities/customer-activity.service';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { uniqBy } from 'lodash';
import { RemoveSpacePipe } from 'app/pipes/remove-space.pipe';
import { LoginService } from 'app/services/auth/login.service';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.css']
})
export class SearchCustomerComponent implements OnInit, OnChanges {
  @Input() label;
  @Input() helpText;
  @Input() optional = false;
  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() submitted = false;
  @Input() placeholder = 'Specify client name';
  @Input() multiple = false;
  @Input() referrer = '';

  options;
  apiUrl = environment.apiUrl;
  isSelected = false;
  data = [{ text: '', id: '' }];
  selectedClients = [];
  touched = false;
  access = false;
  dataOption = [];
  values: string[];

  constructor(public customerActivityService: CustomerActivityService, private router: Router, private removeSpace: RemoveSpacePipe,
    private loginService: LoginService) { }

  ngOnInit() {
    if (this.customerActivityService.hasAccess() || this.loginService.isBandThreeAndAbove()) {
      this.access = true;
    }

    this.options = {
      tags: this.access,
      multiple: this.multiple,
      placeholder: this.placeholder,
      language: { errorLoading: () => 'Searching...' },
      minimumInputLength: 1,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/clients',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1
        }),
        processResults: (data) => {
          this.dataOption = data.clients.map((client) => ({
            id: client.id,
            text: client.name + (client.erp_number ? ` (${client.erp_number})` : '')
          }));

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 20) < data.total_count
            }
          };
        }
      }
    };

    if (this.value && this.value.length) {
      this.data = this.value.map((client) => ({
        id: client.id,
        text: client.name
      }));
      this.onSelect({ data: this.data });
    } else {
      this.selectedClients = [];
    }
  }

  ngOnChanges(change) {
    if (change.value) {
      this.values = this.value.length ? this.value.map(v => v.name) : null;
    }
    // if (!change.submitted) {
    //   if (this.value && this.value.length) {
    //     this.data = this.value.map((client) => ({
    //       id: client.id,
    //       text: client.name
    //     }));
    //     this.onSelect({ data: this.data });
    //   } else {
    //     this.selectedClients = [];
    //   }
    // }
    this.touched = this.submitted;
  }

  valueChanged(event) {
    if (event) {
      this.onSelect({
        data: this.dataOption.filter(o =>
          Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
      });
    } else {
      // this.selectedClients = [];
      // this.form.get(this.controlName).setValue(this.selectedClients);
      this.touched = false;
    }
  }

  onSelect(event) {
    const clients = event.data
      .filter(client => client.text)
      .map(client => ({
        id: client.id,
        text: client.text,
      }));
    // new client
    if (event.data.length && (event.data[0].id === event.data[0].text)) {
      this.customerActivityService.clientId = '';
      this.customerActivityService.clientName = event.data[0].id;
      this.isSelected = true;
    } else {
      this.customerActivityService.clientId = event.data[0] ? event.data[0].id : '';
      if (clients.length) {
        this.touched = true;
        if (this.multiple) {
          this.selectedClients = uniqBy(clients.concat(this.form.get(this.controlName).value || []), 'id');
          $('#' + this.removeSpace.transform(this.label)).val(null).trigger('change');
        } else {
          this.selectedClients = clients;
        }
        this.form.get(this.controlName).setValue(this.selectedClients);
      } else {
        this.selectedClients = clients;
        this.form.get(this.controlName).setValue(this.selectedClients);
      }
    }
  }

  onClick() {
    this.customerActivityService.referrer = this.referrer;
    this.router.navigate(['/customer/add']);
  }

  onCancel() {
    this.isSelected = false;
    this.selectedClients = [];
    this.data = [{ text: '', id: '' }];
    this.form.get(this.controlName).setValue(this.selectedClients);
    $('#' + this.removeSpace.transform(this.label)).val(null).trigger('change');
  }

  remove(id) {
    this.touched = true;
    this.selectedClients = this.form.get(this.controlName).value.filter(person => person.id !== id);
    this.form.get(this.controlName).setValue(this.selectedClients);
    $('#' + this.removeSpace.transform(this.label) + ' select option[value="' + id + '"]').remove();
    this.value = undefined;
  }
}
