<div class="membership-type">
  <span *ngIf="headText || coHeadText">
    <ng-template #tipContent>{{headText}}<br *ngIf="headText && coHeadText">{{coHeadText}}</ng-template>
    <img class="badge__icon" src="https://storage.googleapis.com/gl-practice-library/images/phead-devops.svg"
      [ngbTooltip]="tipContent" [container]="container">
  </span>
  <ng-container *ngFor="let practice of practices | slice:0:displayPracticeNumber;">
    <ng-template #memberTypeTooltip>
      <div (mouseleave)="t.close()">
        <span [innerHTML]="getMembershipText(practice.memberships, practice.name)"></span>
        <div class="text-center" *ngIf="leaveAccess">
          <button class="btn btn-link help-tooltip" (click)="leavePractice(practice)" i18n>Leave Practice</button>
          <div class="become-member help-tooltip-inner">{{leavetMembershipText(practice)}}
          </div>
        </div>
      </div>
    </ng-template>

    <span class="ctag" ngClass="{{practice.c_name}}" [ngbTooltip]="memberTypeTooltip" [container]="container"
      tooltipClass='pm-tooltip' triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
      (mouseleave)="onMouseleave($event, t)">
      <i ngClass=" {{membership.c_name | class}}"
        *ngFor="let membership of getMembership(practice.memberships,'icon') ;let i = index;" [style.left.px]="-2-15*i">
        <img src="assets/images/{{membership.c_name | class}}.png" *ngIf="membership.c_name !=='extended'">
      </i>
      {{practice.name | slice:0:1|uppercase}}
    </span>
  </ng-container>

  <a href="javascript:;" *ngIf="moreMember > 0" (click)="open()" appStopPropagation>
    <span class="more-practices">
      +{{moreMember}}
    </span>
  </a>
</div>
