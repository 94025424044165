<!-- <div class="filter-head">Advance Filters
    <a (click)="close()">X</a>
</div> -->
<!-- <div class="filter-body" [perfectScrollbar]="config" -->
<div class="filter-body" *ngIf="filterService.advanceFilterConfig.length === filterService.advancePageFilters.length">
    <app-advance-filter-fields (clearEvent)="isDirty = $event"
        [fields]="filterService.advanceFilterConfig | orderBy:'order'"></app-advance-filter-fields>
</div>
<div class="filter-footer">
    <a class="clear-filter mr-20" *ngIf="isDirty" (click)="clear()" i18n>Clear Filters</a>
    <span class="btn btn-light mr-5 filter-close" (click)="close()" i18n>Cancel</span>
    <button (click)="apply()" type="button" class="btn btn-primary">APPLY</button>
</div>