<a [routerLink]="['/team',data['login'] || data['person_id']]" class="user-box" [ngClass]="classes"
  [ngStyle]="{'height.px':height}">
  <div class="user_avatar">
    <span class="avatar avatar-sm">
      <img [src]="data.photo_url" [title]="personName">
    </span>
  </div>
  <div class="user_details" appStopPropagation>
    <a [routerLink]="['/team',data['login'] || data['person_id']]">{{personName | titlecase}}</a>
    <div class="date mt-8">{{ getPersonTitle(data) }} |
      <a [routerLink]="['/locations', data.contact.location.city | removeSpace]">
        {{data.contact.location.city}}
      </a>
    </div>
    <div class="date mt-8">
      <a href="http://mail.google.com/a/globallogic.com/mail?view=cm&to={{data.contact.email_id}}"
        target="_blank">{{data.contact.email_id| truncate:35}}</a>
    </div>
  </div>

  <!-- actions -->
  <div class="delete activity-controls" appStopPropagation *ngIf="isHover && showAction">
    <a class="circle-actions circle-actions-bg" [routerLink]="['/practices',cName,'add-member']"
      [queryParams]="{id: data['login']}" i18n-ngbTooltip ngbTooltip="Edit" container="body"><i
        class="fas fa-pencil-alt"></i></a>


    <!-- <a class="circle-actions circle-actions-bg" (click)="changeType($event,1,2)" href="javascript:;"
      i18n-ngbTooltip ngbTooltip="Remove from Core" container="body" *ngIf="data.core"> <i class="fas fa-star"></i></a>
    <a class="circle-actions circle-actions-bg" (click)="changeType($event,2,1)" href="javascript:;"
      i18n-ngbTooltip ngbTooltip="Move to Core" container="body" *ngIf="data.extended"> <i class="far fa-star"></i></a> -->

    <!-- <ng-container *ngIf="data.organization == 'Practices'; else noncore"> -->
    <a class="circle-actions circle-actions-bg" (click)="moveFrom($event,4)" href="javascript:;" i18n-ngbTooltip
      ngbTooltip="Change Membership" container="body" *ngIf="data.core"> <i
        class="fa-regular fa-arrow-right-arrow-left"></i></a>
    <a class="circle-actions circle-actions-bg" (click)="moveFrom($event,1)" href="javascript:;" i18n-ngbTooltip
      ngbTooltip="Change Membership" container="body" *ngIf="data.sme"><i
        class="fa-regular fa-arrow-right-arrow-left"></i></a>
    <a class="circle-actions circle-actions-bg" (click)="moveFrom($event,2)" href="javascript:;" i18n-ngbTooltip
      ngbTooltip="Change Membership" container="body" *ngIf="data.extended"><i
        class="fa-regular fa-arrow-right-arrow-left"></i></a>
    <!-- </ng-container> -->

    <!-- <ng-template #noncore>
      <a class="circle-actions circle-actions-bg" (click)="changeType($event,1,2)" href="javascript:;"
        i18n-ngbTooltip ngbTooltip="Change Membership" container="body" *ngIf="data.sme"> <i
          class="fa-regular fa-arrow-right-arrow-left"></i></a>
      <a class="circle-actions circle-actions-bg" (click)="changeType($event,2,1)" href="javascript:;"
        i18n-ngbTooltip ngbTooltip="Change Membership" container="body" *ngIf="data.extended"> <i
          class="fa-regular fa-arrow-right-arrow-left"></i></a>
    </ng-template> -->

    <ng-container *ngIf="isPrivilegedUser">
      <a class="circle-actions circle-actions-bg" (click)="editLocation($event)" href="javascript:;" i18n-ngbTooltip
        ngbTooltip="Edit Center Coordinator" container="body" *ngIf="data.center_coordinator"> <i
          class="fas fa-map-marker"></i></a>
      <a class="circle-actions circle-actions-bg" (click)="editLocation($event)" href="javascript:;" i18n-ngbTooltip
        ngbTooltip="Make Center Coordinator" container="body" *ngIf="!data.center_coordinator"> <i
          class="fas fa-map-marker-alt"></i></a>
    </ng-container>
    <a class="circle-actions circle-actions-bg" (click)="removeMember($event)" href="javascript:;" i18n-ngbTooltip
      ngbTooltip="Delete" container="body"> <i class="fas fa-times"></i></a>
  </div>
</a>
