<footer class="row">
  <div class="col-md-9">
    <div class="row no-gutters">
      <ng-container *ngIf="submitAccess(); else footerLinks;">
        <div class="col-md-3" *ngIf="loginService.isPerson()">
          <ul class="footer-links md-mt-40-mob">
            <li class="head"><span i18n>Tech Submission</span></li>
            <li>
              <a routerLink="/pocs/submit" title="Tech Solution" i18n>Tech Solution</a>
              <!-- | <a routerLink="/pocs/submit-proposal" title="Submit Innovation Proposal" i18n>Proposal</a> -->
            </li>
            <li>
              <a routerLink="/tech-papers/submit" title="Submit Tech Document" i18n>Submit Tech Document</a>
            </li>
            <li *ngIf="loginService.isPerson()">
              <a routerLink="/blogs/submit" title="Submit Blog" i18n>Submit Blog</a>
            </li>
            <li>
              <a routerLink="/tech-talks/submit" title="Submit Tech Talk" i18n>Submit Tech Talk</a>
            </li>
          </ul>
        </div>
      </ng-container>

      <div class="col-md-3">
        <ul class="footer-links md-mt-10 ipad">
          <li class="head"><span i18n>Our Community</span></li>
          <li id="tour"><a routerLink="/tour" i18n>Take a Video Tour</a> | <a href="javascript:;"
              (click)="openSiteIntro()" id="guided-tour" i18n>Guided Tour</a></li>
          <li> <a href="https://glo.globallogic.com/communities/616-global-practices" target="_blank" i18n>Global
              Practice Community</a>
          </li>
          <li> <a routerLink="/public/practice-mailing-lists" i18n>Practices Mailing Lists</a> </li>
          <li> <a href="https://labs.globallogic.com" target="_blank" i18n>Globallogic Labs *</a> </li>
          <li> <a routerLink="/feedback" i18n>Give Us Feedback</a> </li>
        </ul>
      </div>

      <div class="col-md-3">
        <ul class="footer-links md-mt-10 ipad">
          <li class="head"><span i18n>Explore</span></li>
          <li><a routerLink="/technologytree" i18n>Explore Knowledge Base</a></li>
          <li><a routerLink="/newsletters" i18n>Practice Newsletters Archive</a></li>
          <li> <a routerLink="/tags" i18n>Tags</a> </li>
        </ul>
      </div>

      <div class="col-md-3" *ngIf="!isExternalUser()">
        <ul class="footer-links md-mt-10 ipad">
          <li class="head"><span i18n>Other</span></li>
          <li> <a routerLink="/release-notes" i18n>Portal Releases</a> </li>
          <li> <a routerLink="/practices-dashboard" i18n>Practices Dashboard</a> </li>
        </ul>
      </div>

      <ng-template #footerLinks>
        <div class="col-md-3">
          <ul class="footer-links md-mt-40-mob">
            <li class="head"><span i18n>Nuggets</span></li>
            <li><a routerLink="/pocs" title="Innovations" i18n>Innovations</a></li>
            <li><a routerLink="/tech-papers" title="Tech Documents" i18n>Tech Documents</a></li>
            <li><a routerLink="/blogs" title="Blog" i18n>Blogs</a></li>
            <li><a routerLink="/tech-talks" title="Tech Talks" i18n>Tech Talks</a></li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="col-md-3">
    <div class="copyright">
      <ng-container *ngIf="version">V {{version}} |</ng-container> {{ currentYear }} <ng-container i18n>Copyright
        GlobalLogic. All rights reserved.</ng-container>
    </div>
    <div class="mt-10 font-weight-normal" style="color: #cccccc" i18n>* Use VPN, if you are hitting this URL from
      outside company network</div>
  </div>
</footer>