import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CloudPickerService } from "app/services/cloud-picker/cloud-picker.service";
import { PracticesService } from 'app/services/practices/practices.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { LoginService } from 'app/services/auth/login.service';
import { environment } from 'environments/environment';
import { HttpEventType } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-cloud-picker',
  templateUrl: './cloud-picker.component.html',
  styleUrls: ['./cloud-picker.component.css']
})

export class CloudPickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data;
  images;
  practices = [];
  vPractices = [];
  hPractices = [];
  selectedPratice;
  selectedPreviewUrl;
  errorMessage = "";
  uploadAccess = false;
  uploadMessage;
  tooltipText;
  token = "";

  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, scrollingThreshold: 0, minScrollbarLength: 50 };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(
    private cloudPickerService: CloudPickerService,
    private practiceService: PracticesService,
    private toastr: ToastrService,
    private commonService: CommonMethodsService,
    private loginService: LoginService) { }

  async ngOnInit() {
    this.uploadAccess = this.loginService.hasCmsEditAccess();
    if (this.data.type && this.data.type == "carousel") {
      this.tooltipText = $localize`Allowed file types: jpg, jpeg, png (W 1400 X H ${this.data.entityCategoryId ? 350 : 600} | Max Size 2 MB)`;
      this.selectedPreviewUrl = this.data.value && this.data.value.url ? this.data.value.url : "";
    } else if (this.data.type && this.data.type == "nugget") {
      this.tooltipText = $localize`Allowed file types: jpg, jpeg, png (W 400 X H 300 | Max Size 2 MB)`;
      this.selectedPreviewUrl = this.data.value ? this.data.value : "";
      this.selectedPratice = this.data.primaryPractice;
    } else {
      if (this.data.type && this.data.type == "technology-selection") {
        this.uploadMessage = $localize`Allowed file types: jpg, jpeg, png (W 600 X H 250 | Max Size 2 MB)`;
      } else {
        this.uploadMessage = $localize`Allowed file types: jpg, jpeg, png (W 400 X H 300 | Max Size 2 MB)`;
      }
      this.selectedPreviewUrl = this.data.previewUrl;
      this.selectedPratice = this.data.previewUrl?.split('/')[this.data.previewUrl?.split('/').length - 2];
      if (this.data.showPractices) {
        this.practiceService.getPractices()
          .subscribe(response => {
            this.practices = response['rows']
            // .map(practice => ({
            //   id: practice.id, name: practice.name
            // }));

            this.hPractices = this.practices.filter(item => item.type.c_name === "horizontal");
            this.vPractices = this.practices.filter(item => item.type.c_name === "vertical");
          });
      }
    }
    this.getPreviewImages();
  }

  ngOnChanges(): void {
    this.selectedPratice = this.data.primaryPractice;
    this.getPreviewImages();
    this.selectedPreviewUrl = "";
  }

  async getToken() {
    try {
      const res = await lastValueFrom(this.cloudPickerService.getToken());
      return res['token']
    } catch (error) {
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async getFiles(prefix) {
    try {
      let getFilesRes = await lastValueFrom(this.cloudPickerService.getFiles(prefix, this.token))
      let files = getFilesRes["items"];
      files = files.sort((a, b) => {
        if (a.updated < b.updated) {
          return 1
        }
        if (a.updated > b.updated) {
          return -1
        }
        return 0
      })
      return files;
    } catch (error) {
      // this.errorMessage = "File not uploaded";
      // this.onUploadError();
      return [];
    }
  }


  async getFilesInfo(file_key) {
    const prefix = this.cloudPickerService.getPrefix(file_key);
    const files = await this.getFiles(prefix)
    let filesInfo = []
    for await (const file of files) {
      if (file.contentType.split("/")[0] == "image") {
        filesInfo.push(this.cloudPickerService.filterFileDetails(file, file_key, ""))
      }
    }
    return filesInfo;
  }

  async getPreviewImages() {
    this.token = await this.getToken();
    let file_key;
    if (this.data.type) {
      if (this.data.type == "carousel") {
        if (this.data.entityCategoryId) {
          if (this.data.entityCategoryId === 24) {
            file_key = "preview-technologySelectionBanner"
          } else {
            file_key = "preview-accelerators"
          }
        } else {
          file_key = "preview-hero"
        }
      } else if (this.data.type == "technology-selection") {
        file_key = "preview-technologySelection"
      } else if (this.data.type == "assessment") {
        file_key = "preview-assessmentFramework"
      } else if (this.data.type == "nugget") {
        file_key = "preview" + "-" + this.selectedPratice
      }
    } else {
      file_key = "preview" + "-" + this.selectedPratice
    }
    this.images = await this.getFilesInfo(file_key);
    this.images.forEach((item, i) => {
      if (item.authenticated_url === this.selectedPreviewUrl) {
        this.images.splice(i, 1);
        this.images.unshift(item);
      }
    });
    setTimeout(() => {
      this.directiveRef.update();
    }, 100);
  }

  changePratice(event) {
    this.selectedPratice = event.target.value;
    this.getPreviewImages();
    this.selectedPreviewUrl = "";
  }

  async updateAcl(objectSelfLink, key) {
    try {
      const metaData = await lastValueFrom(this.cloudPickerService.getAclData(objectSelfLink, this.token));
      if (key[0] == "nugget") {
        const newAcls = environment.aclDomains.map(domainName => ({
          entity: `domain-${domainName}`,
          role: 'READER'
        }))
        metaData['acl'].push(...newAcls);
        await lastValueFrom(this.cloudPickerService.updateAcl(objectSelfLink, metaData, this.token));
      } else if (key[0] == "preview") {
        const newAcls = {
          entity: 'allUsers',
          role: 'READER'
        }
        metaData['acl'].push(newAcls);
        await lastValueFrom(this.cloudPickerService.updateAcl(objectSelfLink, metaData, this.token));
      }
    } catch (error) {
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async uploadFile(file, fileKey) {
    const prefix = this.cloudPickerService.getPrefix(fileKey)
    const key = fileKey.split("-")
    let fileName;
    if (key[0] == "preview") {
      const files = await this.getFiles(prefix)
      var parts = file.name.split(".")
      var ext = parts[parts.length - 1]
      fileName = files.length + 1 + "." + ext
    } else if (key[0] == "nugget") {
      fileName = file.name
      const timeStamp = Date.now()
      const splittedFileName = fileName.split(".")
      if (splittedFileName.length > 1) {
        const extension = splittedFileName.pop()
        fileName = `${splittedFileName.join(".")}-${timeStamp}.${extension}`
      }
    }
    this.uploadGCS(fileName, prefix, file, fileKey)
  }

  async uploadGCS(fileName, prefix, file, fileKey) {
    let newFileName = fileName.replaceAll(/[/|;:+"]/gu, "-")
    const filePath = prefix + newFileName;
    const key = fileKey.split("-");
    this.cloudPickerService.uploadFile(filePath, file, this.token).subscribe(async res => {
      if (res.type === HttpEventType.Response) {
        await this.updateAcl(res.body['selfLink'], key);
        const filterFileDetails = this.cloudPickerService.filterFileDetails(res.body, fileKey, file.name);
        this.images.unshift(filterFileDetails);
        this.selectedPreviewUrl = filterFileDetails['authenticated_url'];
        if (this.data.type && (this.data.type == "carousel" || this.data.type == "nugget")) {
          this.updatePreviewUrl(filterFileDetails['authenticated_url'])
        }
        this.commonService.showFileLoader = false;
      }
      if (res.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * res.loaded / res.total);
        this.cloudPickerService.uploadProgress = percentDone
      }
    })
  }

  onFileChange(event) {
    this.errorMessage = '';
    if (event.target.files) {
      const file = event.target.files[0];
      //Check file type of image
      if (!['jpg', 'jpeg', 'png'].includes(file['type'].split('/')[1])) {
        this.errorMessage = $localize`Only jpg, jpeg and png file type is allowed`;
        return;
      }
      //Check image file size id less the 2 mb
      if ((file.size / 1024) > 2048) {
        this.errorMessage = $localize`File size should be less than 2 mb`;
        return;
      }
      //Check image width and height in range of 350x250 to 450x350
      var img = new Image();
      img.src = window.URL.createObjectURL(file)
      img.onload = async () => {
        let condition;
        if (this.data.type && this.data.type == "carousel") {
          if (this.data.entityCategoryId) {
            condition = img.width >= 1300 && img.width <= 1500 && img.height >= 300 && img.height <= 400
          } else {
            condition = img.width >= 1300 && img.width <= 1500 && img.height >= 500 && img.height <= 700
          }
        } else {
          if (this.data.type && this.data.type == "technology-selection") {
            condition = img.width >= 550 && img.width <= 650 && img.height >= 200 && img.height <= 300
          } else {
            condition = img.width >= 350 && img.width <= 450 && img.height >= 250 && img.height <= 350
          }
        }
        if (condition) {
          this.commonService.showFileLoader = true;
          // let formData = new FormData();
          // formData.append("file", file);
          let fileKey;
          if (this.data.type && this.data.type == "carousel") {
            if (this.data.entityCategoryId) {
              if (this.data.entityCategoryId === 24) {
                fileKey = "preview-technologySelectionBanner";
              } else {
                fileKey = "preview-accelerators";
              }
            } else {
              fileKey = "preview-hero";
            }
          } else if (this.data.type == "technology-selection") {
            fileKey = "preview-technologySelection";
          } else {
            fileKey = "preview" + "-" + this.selectedPratice;
          }
          this.cloudPickerService.uploadProgress = 0;
          await this.uploadFile(file, fileKey);
          // this.cloudPickerService.uploadFile(formData).subscribe((response) => {
          //   this.commonService.showLoader = false;
          //   this.images.unshift(response);
          //   this.selectedPreviewUrl = response['authenticated_url'];
          //   if (this.data.type && this.data.type == "carousel") {
          //     this.updatePreviewUrl(response['authenticated_url'])
          //   }
          // }, e => this.commonService.showLoader = false)
        } else {
          if (this.data.type && this.data.type == "carousel") {
            if (this.data.entityCategoryId) {
              this.errorMessage = $localize`Image width and height in range of 1300 x 300 to 1500 x 400 is required`;
            }
            else {
              this.errorMessage = $localize`Image width and height in range of 1300 x 500 to 1500 x 700 is required`;
            }
          }
          else {
            if (this.data.type && this.data.type == "technology-selection") {
              this.errorMessage = $localize`Image width and height in range of 550 x 200 to 650 x 300 is required`;
            } else {
              this.errorMessage = $localize`Image width and height in range of 350 x 250 to 450 x 350 is required`;
            }

          }
          return;
        }
      }
    }
  }

  getEntitycategoryId(entityCategory) {
    switch (entityCategory) {
      case "poc":
        return 4;
      case "tech_document":
        return 3;
      case "tech_talk":
        return 2;
      case "case_study":
        return 6;
      case "blog":
        return 5;
      case "event":
        return "event";
      case "deck":
        return "deck";
      case "technology_selection":
        return 24;
      case "assessment_framework":
        return 17;
      case "curated_content":
        return 25;
    }
  }

  updatePreviewUrl(previewUrl) {
    if (this.data.type && this.data.type == "carousel") {
      this.selectedPreviewUrl = previewUrl;
      this.data.form.get(this.data.controlName).setValue(this.selectedPreviewUrl);
      let tokens = RegExp('https://storage.googleapis.com/(.+?)/(.+)', 'g').exec(this.selectedPreviewUrl);
      this.data.value.url = this.selectedPreviewUrl;
      this.data.value.name = tokens[2].split("/")[tokens[2].split("/").length - 1]
    } if (this.data.type && this.data.type == "nugget") {
      this.selectedPreviewUrl = previewUrl;
      this.data.form.get(this.data.controlName).setValue(this.selectedPreviewUrl);
    } else {
      this.selectedPreviewUrl = previewUrl;
    }
  }

  updatePreview() {
    const formData = { preview_url: this.selectedPreviewUrl, entity_id: this.data.entityId, entity_category_id: this.getEntitycategoryId(this.data.entityCategory) };
    this.cloudPickerService.updatePreview(formData).subscribe(response => {
      this.toastr.success(response['message']);
      this.cloudPickerService.previewUpdate.emit({ previewUrl: this.selectedPreviewUrl, entityId: this.data.entityId, entityCategory: this.data.entityCategory });
    })
  }

  onUploadError() {
    this.cloudPickerService.uploadProgress = 0
    this.commonService.showFileLoader = false;
  }

  ngOnDestroy(): void {
    this.onUploadError();
  }
}
