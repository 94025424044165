import { Component, OnInit, Input, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'app/services/auth/login.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TooltipService } from 'app/services/tooltip/tooltip.service';


@Component({
  selector: 'app-person-practices',
  templateUrl: './person-practices.component.html',
  styleUrls: ['./person-practices.component.css']
})
export class PersonPracticesComponent implements OnInit, OnChanges {
  @Input() person: JSON;
  @Input() container = 'body';
  @Input() data;
  @Input() displayNumber = 5;
  @Input() allowLeave = false;
  moreMember = 0;
  displayPracticeNumber = 0;
  practices;
  head = [];
  coHead = [];
  headText = '';
  coHeadText = '';
  leaveAccess = false;
  leavePracticeEvent = new Subscription();
  @ViewChildren(NgbTooltip) tooltips: QueryList<NgbTooltip>;

  constructor(private modalService: ModalPopupService, private commonService: CommonMethodsService,
    private loginService: LoginService, private practicesService: PracticesService,
    private toastrService: ToastrService, private tooltipService: TooltipService) { }

  ngOnInit() {
    if (this.data) {
      this.person = this.data['person'];
      this.allowLeave = this.data['allowLeave'];
      this.displayNumber = 20;
      this.getPractices();
      this.checkLeaveAccess();
    }

    this.leavePracticeEvent = this.practicesService.leavePracticeEvent.
      subscribe(response => {
        this.person['practices'] = this.person['practices'].filter(p => p.id !== response.id);
        this.getPractices();
        this.toastrService.success(response['message']);
      });
  }

  ngOnChanges() {
    this.getPractices();
    this.checkLeaveAccess();
  }

  checkLeaveAccess() {
    if (this.allowLeave && this.loginService.currentUser.person.login === this.person['login']) {
      this.leaveAccess = true;
    } else {
      this.leaveAccess = false;
    }
  }

  getPractices() {
    this.practices = this.person['practices'];
    this.head = this.person['practice_heads'];
    this.coHead = this.person['practice_co_heads'];
    this.getHeadText();
    if (this.practices) {
      this.displayPracticeNumber = this.commonService.getDisplayCount(this.practices.length, this.displayNumber);
      this.moreMember = this.practices.length - this.displayPracticeNumber;
      if (this.head || this.coHead) {
        this.moreMember++;
        this.displayPracticeNumber--;
        if (this.practices.length === this.displayNumber) {
          this.moreMember++;
          this.displayPracticeNumber--;
        }
      }
    }
  }

  // get membership list after merge Center Coordinator cities
  getMembership(membershipList, usedFor) {
    let centerCoordinator;
    const cities = [];
    const memberships = [];
    membershipList.forEach(membership => {
      if (membership.c_name === 'center_coordinator') {
        if (membership.center) {
          if (centerCoordinator === undefined) {
            centerCoordinator = membership;
          }
          cities.push(membership.center.city);
        }
      } else if (membership.c_name !== 'extended' || usedFor === 'text') {
        memberships.push(membership);
      }
    });

    if (centerCoordinator !== undefined) {
      centerCoordinator['cities'] = cities.join(', ');
      memberships.push(centerCoordinator);
    }
    return memberships;
  }

  // get practice membership text
  getMembershipText(memberships, practice: string) {
    let text = '';
    const membershipList = this.getMembership(memberships, 'text');
    membershipList.forEach(membership => {
      text += text.length > 0 ? '<br>' : '';
      if (membership.c_name === 'center_coordinator') {
        text += $localize`${membership.type} of ${practice} at ${membership.cities}`;
      } else if (membership.c_name === 'sme') {
        text += $localize`${membership.type} of ${practice}`;
      } else if (membership.c_name === 'core') {
        text += $localize`${membership.type} of ${practice}`;
      } else {
        text += $localize`Member of ${practice}`;
      }
    });
    return text;
  }

  getHeadText() {
    let practicesHead = [];
    let practicesCoHead = [];
    if (this.head !== undefined) {
      this.head.forEach(practice => {
        if (practicesHead.length > 0) {
          practicesHead.push([practice['name']]);
        } else {
          practicesHead = [practice['name']];
        }
      });
      this.headText = practicesHead.length > 0 ? $localize`Practice Head of ${practicesHead.join(', ')}` : '';
    }

    if (this.coHead !== undefined) {
      this.coHead.forEach(practice => {
        if (practicesCoHead.length > 0) {
          practicesCoHead.push([practice['name']]);
        } else {
          practicesCoHead = [practice['name']];
        }
      });
      this.coHeadText = practicesCoHead.length > 0 ? $localize`Practice Co-Head of ${practicesCoHead.join(', ')}` : '';
    }
  }

  // closeAllTooltips() {
  //   this.tooltips.forEach((tooltip => {
  //     console.log(tooltip)
  //     tooltip.close();
  //   }))
  // }

  hideTooltip() {
    this.tooltipService.hideTooltip();
  }

  open() {
    // this.closeAllTooltips()
    this.hideTooltip()
    const data = {
      title: $localize`Practices Memberships`, message: '', component: 'PersonPracticesComponent',
      data: { person: this.person, allowLeave: this.allowLeave }
    };
    this.modalService.open(data);
  }

  leavetMembershipText(practice) {
    return this.practicesService.leavetMembershipText(practice);
  }

  leavePractice(practice) {
    const data = {
      title: $localize`Leave Practice`, message: '', component: 'LeavePracticeComponent',
      data: { id: practice.id, name: practice.name }
    };
    this.modalService.open(data);
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.pm-tooltip');
  }
}
