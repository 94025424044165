import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  apiUrl = environment.apiUrl;
  collectionEvent = new Subject();

  closeModelEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  getCollections(params?) {
    return this.http.get(this.apiUrl + `/v1/collections`, { params });
  }

  getCollection(id) {
    return this.http.get(this.apiUrl + `/v1/collections/${id}`);
  }

  createCollection(data) {
    return this.http.post(this.apiUrl + `/v1/collections`, data);
  }

  shareCollection(id, data) {
    return this.http.post(this.apiUrl + `/v1/collections/${id}/share`, data);
  }

  updateCollection(id, data) {
    return this.http.put(this.apiUrl + `/v1/collections/${id}`, data);
  }

  getCollectionEntities(id, params) {
    return this.http.get(this.apiUrl + `/v1/collections/${id}/entities`, { params });
  }

  updateCollectionEntities(id, data) {
    return this.http.put(this.apiUrl + `/v1/collections/${id}/entities`, data);
  }

  deleteCollection(id) {
    return this.http.delete(this.apiUrl + `/v1/collections/${id}`);
  }
}
