import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PeopleService } from 'app/services/people/people.service';
import { findIndex } from 'lodash';
import { FilterService } from 'app/services/filter/filter.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-top-contributors',
  templateUrl: './top-contributors.component.html',
  styleUrls: ['./top-contributors.component.css']
})
export class TopContributorsComponent implements OnInit {
  @Input() members = [];
  @Input() scrollable = false;
  @Input() height = 330;
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public commonService: CommonMethodsService, public peopleService: PeopleService, private filterService: FilterService) { }

  ngOnInit() {
    // get member detail
    const personIds = this.members.map(m => m.person.login);
    const params = this.filterService.getApiParams([{ key: 'filters[people][]', values: personIds }]);
    this.peopleService.getTeam(params)
      .subscribe(response => {
        this.members = this.members.map(m => {
          const i = findIndex(response['people'], { login: m.person.login });
          if (i > -1) {
            m.person = response['people'][i];
          }
          return m;
        });
      });
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}
