<div class="nugget-item nugget-item-list ca-card">
  <content-loader height="40" width="1500" primaryColor="#eee" secondaryColor="#ddd">
    <svg:rect x="0" y="10" width="250" height="20" />
    <svg:rect x="350" y="10" width="350" height="20" />
    <svg:rect x="750" y="10" width="200" height="20" />
    <svg:rect x="1050" y="10" width="100" height="20" />
    <svg:rect x="1200" y="10" width="120" height="20" />
    <svg:rect x="1350" y="10" width="150" height="20" />
  </content-loader>
</div>
