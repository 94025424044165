<label for="{{htmlId}}">
  {{ label }}
  <span class="help" *ngIf="optional" i18n>(Optional)</span>
</label>
<div class="help" *ngIf="helpText">{{helpText}}</div>

<ng-select [items]="options" bindLabel="name" groupBy="industry" [multiple]="multiple" [hideSelected]="true"
  [closeOnSelect]="!multiple" [selectableGroup]="true" [selectableGroupAsModel]="false" [(ngModel)]="selectedSegments"
  [compareWith]="compareWith" (change)="onSelectionChange($event)" [placeholder]="placeholder">
  <ng-template ng-optgroup-tmp let-item="item">
    {{item.industry}}
  </ng-template>
  <ng-template ng-multi-label-tmp>
  </ng-template>
</ng-select>

<div class="search-tag-wrapper row" *ngIf="selectedSegments.length">
  <span class="search-tag" *ngFor="let segment of selectedSegments">{{segment.name}}<a
      (click)="remove(segment.id)">X</a></span>
</div>