<!-- <div class="nugget-item d-block"> -->
<content-loader height="200" width="300" primaryColor="#eee" secondaryColor="#ddd">
  <svg:rect x="0" y="0" width="150" height="50" />
  <svg:rect x="0" y="70" rx="5" ry="5" width="250" height="14" />
  <svg:circle cx="22" cy="140" r="22" />
  <svg:circle cx="72" cy="140" r="22" />
  <svg:circle cx="122" cy="140" r="22" />
  <svg:circle cx="172" cy="140" r="22" />
  <svg:circle cx="222" cy="140" r="22" />
  <svg:rect x="0" y="180" rx="9" ry="9" width="100" height="18" />
  <svg:rect x="110" y="180" rx="9" ry="9" width="100" height="18" />
</content-loader>
<!-- </div> -->
