import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild, Inject } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { CommentService } from 'app/services/comment/comment.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoginService } from 'app/services/auth/login.service';
import { PeopleService } from 'app/services/people/people.service';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { Subscription } from 'rxjs';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { findIndex, flatten, uniq } from 'lodash';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() entityId;
  @Input() entityCategoryId;
  @Input() showAll = false;
  @Input() classes = '';
  comments;
  commentForm: UntypedFormGroup;
  commentEditForm: UntypedFormGroup;
  currenrUser;
  submitted = false;
  ckeConfig = this.commentService.ckeConfig;
  commentEvent: Subscription;
  focused = false;
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public commentService: CommentService, private filterService: FilterService, private loginService: LoginService,
    private peopleService: PeopleService, private formValidator: FormValidatorService, private modalService: ModalPopupService,
    private commonService: CommonMethodsService, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.currenrUser = this.loginService.currentUser['person'];
    if (this.loginService.isPerson()) {
      this.createCommentForm();
    }

    this.commentEvent = this.commentService.commentEvent
      .subscribe(response => {
        if (response['event'] === 'edit') {
          this.editCommentForm(response['id']);
        } else {
          this.deleteComment(response['id']);
        }
      });
  }

  ngOnChanges() {
    const filter = [{ key: 'entity_category_id', values: [this.entityCategoryId] }];
    const params = this.filterService.getApiParams(filter);
    this.commentService.getComments(this.entityId, params)
      .subscribe(response => {
        this.comments = response['comments'];
        this.commentService.commentCount = response['total_count'];
        this.commentService.scrollToComment();

        let personIds = this.comments.map(c => {
          return [c.person.login, ...c.replies.map(r => r.person.login)];
        });
        personIds = uniq(flatten(personIds));
        if (personIds.length) {
          const param = this.filterService.getApiParams([{ key: 'filters[people][]', values: personIds }]);
          this.peopleService.getTeam(param)
            .subscribe(people => {
              this.comments = this.comments.map(c => {
                const i = findIndex(people['people'], { login: c.person.login });
                if (i > -1) {
                  c.person = people['people'][i];
                }
                c.replies = c.replies.map(r => {
                  const ind = findIndex(people['people'], { login: r.person.login });
                  if (ind > -1) {
                    r.person = people['people'][ind];
                  }
                  return r;
                });
                return c;
              });
            });
        }
      });
    if (this.commentForm) {
      this.commentForm.reset();
    }
  }

  createCommentForm() {
    this.commentForm = new UntypedFormGroup({
      comment: new UntypedFormControl(null, [Validators.maxLength(1000)])
    });
  }

  editCommentForm(id) {
    this.commentService.editCommentId = id;
    const comment = this.comments.filter(c => c.id === id)[0];
    this.commentEditForm = new UntypedFormGroup({
      comment: new UntypedFormControl(comment['comment'], [this.formValidator.whiteSpaceValidation, Validators.maxLength(1000)])
    });
    setTimeout(() => {
      this.directiveRef.scrollToElement(`#comment-${id}`, -50, 100);
    }, 1000);
  }

  submitComment(type?) {
    this.submitted = true;
    if (type === 'edit') {
      const comment = this.commentEditForm.get('comment').value.trim();
      const data = {
        comment: comment,
        emails: this.commentService.getTagedEmail(comment)
      };
      this.commentService.updateComment(this.entityId, this.commentService.editCommentId, data)
        .subscribe(response => {
          this.comments.forEach((c, i) => {
            if (c.id === this.commentService.editCommentId) {
              this.comments[i] = { ...response['comment'], replies: c['replies'] };
            }
          });
          this.cancel('edit');
          this.submitted = false;
        }, error => this.submitted = false);
    } else {
      const comment = this.commentForm.get('comment').value.trim();
      const data = {
        entity_category_id: this.entityCategoryId,
        comment: comment,
        emails: this.commentService.getTagedEmail(comment)
      };

      this.commentService.submitComment(this.entityId, data)
        .subscribe(response => {
          this.comments.unshift(response['comment']);
          this.commentService.commentCount += 1;
          this.commentForm.get('comment').reset();
          this.commentForm.get('comment').disable();
          setTimeout(() => {
            this.commentForm.get('comment').enable();
            this.commentForm.get('comment').markAsUntouched();
          }, 200);
          this.submitted = false;
        }, error => this.submitted = false);
    }
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person);
  }

  cancel(type?) {
    if (type === 'edit') {
      this.commentEditForm = undefined;
      this.commentService.editCommentId = 0;
    } else {
      if (this.commentForm.get('comment').value) {
        this.commentForm.get('comment').reset();
        this.commentForm.get('comment').disable();
        setTimeout(() => {
          this.commentForm.get('comment').enable();
          this.commentForm.get('comment').markAsUntouched();
        }, 100);
      }
    }
  }

  deleteComment(cid) {
    const data = {
      title: $localize`Delete comment`, message: $localize`Are you sure you want to delete this comment?`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commentService.deleteComment(this.entityId, cid)
            .subscribe(response => {
              this.comments = this.comments.filter(c => c.id !== cid);
              this.commentService.commentCount = response['total_count'];
            });
        }
      });
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }

  // scrollToElement(element) {
  //   this.pageScrollService.scroll({
  //     document: this.document,
  //     scrollTarget: element,
  //     scrollOffset: 300,
  //     duration: 1000
  //   });
  // }

  onReply(e) {
    setTimeout(() => {
      const element = '#' + (e.action === 'edit' ? 'comment' : 'reply-form') + `-${e.id}`;
      this.directiveRef.scrollToElement(element, -100, 100);
    }, 1000);
  }

  ngOnDestroy() {
    this.commentEvent.unsubscribe();
  }
}
