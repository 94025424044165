<div class="row">
  <div class="col-md-8">
    <content-loader height="300" width="600" primaryColor="#eee" secondaryColor="#ddd">
      <svg:rect x="0" y="20" rx="5" ry="5" width="400" height="20" />
      <svg:rect x="0" y="50" rx="5" ry="5" width="200" height="14" />
      <svg:rect x="0" y="70" rx="5" ry="5" width="200" height="14" />
      <svg:rect x="0" y="100" rx="5" ry="5" width="200" height="14" />
      <svg:rect x="0" y="150" rx="5" ry="5" width="600" height="14" />
      <svg:rect x="0" y="180" rx="5" ry="5" width="600" height="14" />
      <svg:rect x="0" y="220" rx="5" ry="5" width="50" height="14" />
      <svg:rect x="0" y="245" rx="5" ry="5" width="70" height="14" />
      <svg:rect x="90" y="245" rx="5" ry="5" width="70" height="14" />
    </content-loader>
  </div>
  <div class="col-md-4 mt-30">
    <content-loader height="250" width="200" primaryColor="#eee" secondaryColor="#ddd">
      <ng-container *ngIf="['blog'].includes(type)">
        <svg:rect x="0" y="0" width="60" height="60" />
        <svg:rect x="70" y="0" width="120" height="10" />
        <svg:rect x="70" y="20" width="50" height="8" />
        <svg:circle cx="80" cy="50" r="10" />

        <svg:rect x="0" y="70" width="60" height="60" />
        <svg:rect x="70" y="70" width="120" height="10" />
        <svg:rect x="70" y="90" width="50" height="8" />
        <svg:circle cx="80" cy="120" r="10" />

        <svg:rect x="0" y="140" width="60" height="60" />
        <svg:rect x="70" y="140" width="120" height="10" />
        <svg:rect x="70" y="160" width="50" height="8" />
        <svg:circle cx="80" cy="190" r="10" />
      </ng-container>

      <ng-container *ngIf="['customer-activity'].includes(type)">
        <svg:rect x="40" y="0" width="120" height="100" />
        <svg:rect x="20" y="120" width="100" height="10" />
        <svg:rect x="20" y="140" width="160" height="8" />
        <svg:rect x="20" y="155" width="160" height="8" />
      </ng-container>
    </content-loader>
  </div>
</div>
