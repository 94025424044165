<div [ngClass]="{'individual-radar-card': !showFull}" class="radar-card" (mouseenter)="showActionIcon=true"
  (mouseleave)="showActionIcon=false">
  <div class="card-actions" *ngIf="showActionIcon">
    <ng-container *ngIf="editable && editAccess">
      <a class="circle-actions" [routerLink]="['/technology-radar', radar.id,'edit']"><i class="fas fa-pen"></i></a>
      <a class="circle-actions" (click)="deleteRadar(radar)"><i class="fas fa-trash-alt"></i></a>
    </ng-container>
    <app-create-collection [entityId]="radar.id" [entityCategory]="26"></app-create-collection>
  </div>
  <div class="radar-status">
    <div class="draft" *ngIf="(radar.draft_radar_id || radar.status.c_name=='draft') && editable && editAccess">Draft
    </div>
  </div>
  <h2 class="pad-0 radar-title" [routerLink]="['/technology-radar', radar.c_name]">{{radar.title}}</h2>
  <p class="mt-10 description">{{radar.description}}</p>
  <div *ngIf="showFull">
    <div *ngIf="createdBy.length">
      <app-members-chip class="created-by" [members]="createdBy"></app-members-chip>
    </div>
    <div [ngClass]="{'radar-type-practice': radarType != 'Company'}" class="radar-type">{{radarType | truncate:20}}
      <span [ngClass]="{'radar-type-practice-tag': radarType != 'Company'}" class="radar-type-tag"></span>
    </div>
  </div>
</div>