<form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
  <div class="form-group">
    <p class="lh-15" i18n>Please do let us know why you want to leave {{data['name']}} Practice.</p>
  </div>

  <!-- locations -->
  <div class="form-group" id="commentsGroup"
    [ngClass]="{'validate':form.get('comments').invalid && (form.get('comments').touched || invalidForm)}">
    <textarea class="remarks-field" formControlName="comments" rows="8"></textarea>
    <div *ngIf="form.get('comments').invalid && (form.get('comments').touched || invalidForm)" class="invalid">
      <div *ngIf="form.get('comments').errors.required || form.get('comments').errors.whitespace" i18n>Enter comments
      </div>
      <div *ngIf="form.get('comments').errors.minlength" i18n>Comments should be more
        than {{form.get('comments').errors.minlength.requiredLength}} characters </div>
      <div *ngIf="form.get('comments').errors.maxlength" i18n>Comments should be less
        than {{form.get('comments').errors.maxlength.requiredLength}} characters</div>
    </div>
  </div>

  <!-- http errors -->
  <div class="form-group" *ngIf="httpError">
    <div class="invalid">
      {{httpError}}
    </div>
  </div>

  <div class="form-group mb-0">
    <button type="submit" class="btn btn-primary" [disabled]="submitted" [ngClass]="{'submitted':submitted}"
      i18n>Submit</button>
    <button type="button" class="btn btn-light ml-15" (click)="cancel()" i18n>Cancel</button>
  </div>
</form>
