<div class="contact-us-container">
  <ul>
    <li i18n>If you are looking to ask a specific question on "HOW" to do something technical, please use <a
        href="https://techq.globallogic.com" target="_blank">TechQ</a> instead of the
      Practice Distribution list (Practice DL).</li>
    <li i18n>The Practice DLs are good to ask general questions on if we have experience in a certain area, etc. If you
      don't get a response, it basically means that nobody has any experience with what you are asking.</li>
    <li i18n>Don't make people read too much...remember that everyone has day jobs. Be specific, clear, and concise in
      your query.</li>
    <li i18n>Remember to say "Please reply directly to me ONLY if you have any guidance". This will remind people to NOT
      reply to all that tends to sometimes irritate the community. Suggest you do "To: Yourself, Bcc: Practice DL"
      to avoid unintended replies to the entire DL.</li>
    <li i18n>Be thoughtful enough to make your questions actually answerable. Open-ended or multi-part questions tend
      not to get answered easily or precisely.</li>
    <li i18n>If you are going to post to the Practice DL, then also be open to listening and/or contributing to them in
      the future.</li>
    <li i18n>Don't abuse the Practice DL e.g. daily questions.</li>
  </ul>
  <div class="contact-mail">
    <a class="btn btn-primary" href="http://mail.google.com/a/globallogic.com/mail?view=cm&to={{data.mail}}"
      target="_blank" (click)="close($event)" i18n>Continue</a>
  </div>
</div>
