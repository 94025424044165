<ng-template #carouselLoader>
  <div class="container" style="height:550px;">
    <content-loader height="550" width="1200" primaryColor="#eee" secondaryColor="#ddd">
      <svg:rect x="70" y="120" height="18" width="600" />
      <svg:rect x="70" y="160" height="40" width="400" />
      <svg:rect x="70" y="220" height="18" width="800" />
      <svg:rect x="70" y="250" height="18" width="800" />
      <svg:rect x="70" y="290" height="35" width="200" />
    </content-loader>
  </div>
</ng-template>

<div class="carousels-container">
  <ng-container *ngIf="carouselsLoaded;else carouselLoader">
    <ngb-carousel *ngIf="carousels.carousels.length;else add" [showNavigationArrows]="showNavigationArrows"
      [showNavigationIndicators]="showNavigationIndicators">
      <ng-container *ngFor="let carousel of carousels.carousels">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img [src]="carousel.preview_url">
          </div>
          <div class="carousel-caption">
            <div class="container carousel-caption-container">
              <ng-container *ngIf="carousel.tag_line">
                <div class="carousel-tag-line">{{carousel.tag_line}}</div>
              </ng-container>
              <h3 class="carousel-title">{{carousel.title}}</h3>
              <ng-container *ngIf="carousel.description">
                <div class="carousel-description">{{carousel.description}}</div>
              </ng-container>
              <ng-container *ngIf="carousel.event_date">
                <div class="carousel-date">{{carousel.event_date | date}}
                  <ng-container *ngIf="carousel.event_time"> {{carousel.event_time}}</ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="carousel.action_name && carousel.action_url">
                <div class="carousel-action mt-20">
                  <a [href]="carousel.action_url" [target]="carousel.target"
                    class="btn btn-primary">{{carousel.action_name}}</a>
                </div>
              </ng-container>
              <!-- <div class="carousel-action carousel-admin-action pull-right" *ngIf="editAccess">
              <a [routerLink]="['/carousels','submit']" class="btn btn-primary btn-sm">Add</a>
              <a [routerLink]="['/carousels',carousel.id,'edit']" class="btn btn-primary btn-sm ml-10">Edit</a>
              <button type="submit" class="btn btn-primary btn-sm ml-10"
                (click)="delete(carousel.id, carousel.title)">Delete</button>
            </div> -->

              <div class="carousel-action carousel-admin-action" *ngIf="editAccess">
                <a [routerLink]="['/carousels','submit']" class="circle-actions" i18n-ngbTooltip ngbTooltip="Add"
                  container="body"><i class="fas fa-plus"></i></a>
                <a [routerLink]="['/carousels',carousel.id,'edit']" class="circle-actions ml-10" i18n-ngbTooltip
                  ngbTooltip="Edit" container="body"> <i class="fas fa-pencil-alt"></i></a>
                <a href="javascript:;" class="circle-actions ml-10" (click)="delete(carousel.id, carousel.title)"
                  i18n-ngbTooltip ngbTooltip="Delete" container="body"><i class="fas fa-trash-alt"></i></a>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>
    <ng-template #add>
      <div class="carousel-actions carousel-add-action" *ngIf="editAccess && carouselsLoaded">
        <a [routerLink]="['/carousels','submit']" class="btn btn-primary btn-sm">Add Carousel</a>
      </div>
    </ng-template>
  </ng-container>
</div>

<!-- <div class="columns container">
  <div class="row">
    <div class="column">
      <div class="important">Practices Important Links</div>
      <div class="home-ncard-body">
        <h4>
          <a routerLink="/documents/223">Introduction to Global Practices</a>
        </h4>
        <p>Help create awareness, excitement and motivation on the Technology & Innovation fabric of the company for new
          joiners.</p>

        <h4><a routerLink="/public/practice-mailing-lists">Practices Mailing List</a></h4>
        <p>Find out the right practice mailing list for your queries and feedback.</p>

        <h4><a routerLink="/newsletters">Practice Newsletters Archive</a></h4>
        <p>Get deeper historic insights into practices by reading our past newsletters.</p>

        <h4><a href="https://labs.globallogic.com" target="_blank">GlobalLogic Labs*</a></h4>
        <p>Dedicated Gitlab server available for globytes for building Innovation collaboratively as a team.</p>

        <p class="vpn-alert mb-0">
          <span style="color: #ff0000;">
            * Use VPN, if you are hitting above URL from outside companynetwork
          </span>
        </p>
      </div>
    </div>

    <ng-template #trendingLoader>
      <div>
        <content-loader height="600" width="300" primaryColor="#eee" secondaryColor="#ddd">
          <svg:rect x="0" y="0" width="300" height="190" />
          <svg:rect x="10" y="200" width="200" height="15" />
          <svg:rect x="10" y="220" width="200" height="15" />
          <svg:circle cx="32" cy="265" r="22" />
          <svg:circle cx="82" cy="265" r="22" />
          <svg:circle cx="132" cy="265" r="22" />
          <svg:rect x="10" y="295" width="275" height="15" />
          <svg:rect x="10" y="315" width="275" height="15" />
          <svg:rect x="10" y="335" width="100" height="15" />
        </content-loader>
      </div>
    </ng-template>

    <div class="column">
      <ng-container *ngIf="trendingNugget;else trendingLoader">
        <div class="home-ncard" [ngStyle]="{backgroundImage: 'url(' + trendingNugget.preview_url + ')'}">
          <span class="trending"><img src="assets/images/icon-trending.png"> Trending</span>
        </div>
        <div class="home-ncard-body">
          <div class="title truncate-2" [innerHTML]="trendingNugget.title | safeHtml"></div>
          <app-practices-chip [practices]="trendingNugget.practices" *ngIf="trendingNugget.practices">
          </app-practices-chip>
          <div class="mb-10 mt-10" *ngIf="trendingNugget.authors && trendingNugget.authors.length">
            <app-members-chip [members]="trendingNugget.authors" [displayNumber]="6">
            </app-members-chip>
          </div>
          <div class="descri truncate-3" [innerHTML]="trendingNugget.description | truncate:300 | safeHtml"></div>
          <div class="learn-more"><a [routerLink]="[getRoute(trendingNugget), trendingNugget.id]">Learn More <i
                class="fa-regular fa-arrow-right"></i></a></div>
        </div>
      </ng-container>
    </div>

    <div class="column mr-0">
      <div class="hitachi-apsd h-combinator">
        <div class="apsd-descri">
          <h1>H Combinator</h1>
          <p>As Hitachi Digital, we believe Hitachi employees have great potential to come up with new ideas and build
            business for the future.</p>
          <p>H Combinator is new in-house business incubation program to share &amp; develop new ideas into new business
            with an access to insights from Silicon Valley VCs and Hitachi Group BUs.</p>
          <div class="learn-more"><a routerLink="/h-combinator">Learn More <i class="fa-regular fa-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-30">
    <div class="column height-630">
      <div class="hitachi-apsd apsd-bg">
        <div class="apsd-descri">
          <h1>Hitachi APSD</h1>
          <p>Our Acceletor catalog as solution usecases of Sentimental Analysis, Data Preparation Service, Manufacturing
            Insights, Application Service for Blockchain from Hitachi Application Services Division.</p>
          <div class="learn-more"><a [routerLink]="['/digital-accelerators']"
              [queryParams]="{providers: 'Hitachi APSD'}">Learn More <i class="fa-regular fa-arrow-right"></i></a></div>
        </div>
      </div>
    </div>

    <div class="column height-630">
      <div class="initiatives">Practices Initiatives</div>
      <div class="home-ncard-body">
        <div class="title">Project Starship</div>
        <p class="mb-10">Project Starship is targeted to develop the in-house certified engineering pool strength at
          scale
          on the major Hyperscalers.</p>
        <div class="learn-more"><a
            href="https://sites.google.com/globallogic.com/aws-certification-drive/skills-enhancement" target="_blank"
            rel="noopener">Learn More <i class="fa-regular fa-arrow-right"></i></a></div>
        <div class="title mt-30">Software Composition Analysis</div>
        <p class="mb-10">Centrally hosted instance of Dependency Tracker for use by teams building solution
          accelerators.
        </p>
        <div class="learn-more"><a href="https://sca.globallogic.com/" target="_blank" rel="noopener">Learn More <i
              class="fa-regular fa-arrow-right"></i></a></div>
        <div class="title mt-30">TechQ</div>
        <p class="mb-10">TechQ is one place for all your queries related to technologies, and domain questions.</p>
        <div class="learn-more"><a href="https://techq.globallogic.com/" target="_blank" rel="noopener">Learn More <i
              class="fa-regular fa-arrow-right"></i></a></div>
        <div class="title mt-30">Architecture Recommendation Tool</div>
        <p class="mb-10">Artificial Intelligence (AI) driven tool to help SMEs to get an initial recommendation on
          architecture style and corresponding technical documentation reference for their projects.</p>
        <div class="learn-more"><a href="https://sites.google.com/globallogic.com/art2/home" target="_blank"
            rel="noopener">Learn More <i class="fa-regular fa-arrow-right"></i></a></div>
      </div>
    </div>

    <ng-template #latestLoaders>
      <div>
        <content-loader height="90" width="300" primaryColor="#eee" secondaryColor="#ddd">
          <svg:circle cx="30" cy="30" r="22" />
          <svg:rect x="70" y="10" width="210" height="10" />
          <svg:rect x="70" y="25" width="210" height="10" />
          <svg:rect x="70" y="40" width="100" height="8" />
          <svg:rect x="70" y="55" width="210" height="10" />
          <svg:rect x="70" y="70" width="210" height="10" />
        </content-loader>
      </div>
    </ng-template>

    <ng-template #latestLoader>
      <div class="activity-row" *ngFor="let loader of latestNuggetsLoaders">
        <ng-container *ngTemplateOutlet="latestLoaders"></ng-container>
      </div>
    </ng-template>

    <div class="column pad-15 mr-0 height-630">
      <div class="activity-stream" *ngIf="latestNuggets; else latestLoader" [perfectScrollbar]="config">
        <div class="activity-row" *ngFor="let nugget of latestNuggets">
          <div class="activity-user">
            <ng-container *ngIf="nugget.person.person_id;else inActive;">
              <ng-template #toolTipContent>
                <app-tooltip-person-card [data]="nugget.person" (mouseleave)="t.close()">
                </app-tooltip-person-card>
              </ng-template>
              <a class="avatar" [routerLink]="['/team',nugget.person['login']]" [ngbTooltip]="toolTipContent"
                tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
                (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)">
                <img [src]="nugget.person.photo_url">
              </a>
            </ng-container>

            <ng-template #inActive>
              <a class="avatar" [routerLink]="['/team',nugget.person['login']]">
                <img src="https://storage.googleapis.com/gl-practice-library/images/avatar.jpg">
              </a>
            </ng-template>
          </div>
          <div class="activity-content">
            <div><a [routerLink]="['/team', nugget.person['login']]">{{getPersonName(nugget.person)}}</a>
              submitted a {{getType(nugget)}} <a [routerLink]="[getRoute(nugget), nugget.id]"
                [innerHTML]="nugget.title | safeHtml"></a>
            </div>
            <div class="date">{{nugget.date | date}}</div>
            <div class="mt-5 truncate-2" [innerHTML]="nugget.abstract | truncate:200 | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- cms content -->
<app-cms-dom id="cms-dom" [page_id]="pageId"></app-cms-dom>

<ng-template #awardsLoaders>
  <div>
    <content-loader height="700" width="500" primaryColor="#eee" secondaryColor="#ddd">
      <svg:circle cx="250" cy="200" r="170" />
      <svg:rect x="20" y="430" width="460" height="60" />
      <svg:rect x="20" y="520" width="460" height="60" />
    </content-loader>
  </div>
</ng-template>

<ng-template #awardsLoader>
  <div class="award-list">
    <ul>
      <li *ngFor="let loader of homeAwardsLoaders">
        <ng-container *ngTemplateOutlet="awardsLoaders"></ng-container>
      </li>
    </ul>
  </div>
</ng-template>

<div class="awardes mt-20">
  <div class="container">
    <div class="award-list" *ngIf="awards.awards.length;else awardsLoader">
      <ul>
        <li *ngFor="let award of awards.awards"><img [src]='award.person.photo_url'>
          <div class="awarde-name"><a
              [routerLink]="['/team', award.person.login]">{{award.person.name.first_name}}<br>{{award.person.name.last_name}}</a>
          </div>
          <div class="awarde-practice mt-10">{{award.practice.name}}</div>
        </li>
        <li>
          <div class="discover-sme">
            <div class="des"><strong i18n>Turning dreams into reality!</strong>&nbsp;<ng-container i18n>Discover what
                our SMEs are upto.</ng-container>
            </div>
            <p><a class="btn btn-primary" [routerLink]="['/team']" i18n>MEET OUR EXPERTS</a></p>
            <p></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>