<!-- <div (clickOutside)="showForm=false">
  <button class="btn btn-primary ml-5" (click)="open()" *ngIf="editAccess">View Report</button>
  <button class="btn btn-primary ml-5" (click)="showForm = !showForm">Report</button>
  <div class="update-poc-status report-nugget" *ngIf="form && showForm">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <textarea formControlName="comment"></textarea>
      <button class="btn btn-primary btn-sm mt-10" type="submit" [disabled]="form.invalid"
        [ngClass]="{'btn-disabled':form.invalid}">SUBMIT</button>
    </form>
  </div>
</div> -->
<ng-container *ngIf="isPerson">
  <ng-template #reportTT>
    <div class="font-weight-bold" i18n>Report {{type}}</div>
    <p i18n>Let us know about the problem with this {{type | lowercase}}.</p>
  </ng-template>

  <a href="javascript:;" class="circle-actions mt-5 ml-10" (click)="open()" [ngbTooltip]="reportTT" container="body"><i
      class="fas fa-flag"></i></a>
</ng-container>
