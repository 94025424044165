<ng-container *ngIf="!cloudStorage;else storageView">
  <div *ngIf="driveAccess; else noAccess;">
    <div class="nugget-preview" [innerHTML]="htmlValue() | safeHtml" *ngIf="previewReady;else download">
    </div>

    <ng-template #download>
      <div class="no-preview" [style.height]="docHeight">
        <div class="no-preview-content">
          <img src="assets/images/video-processing.png">
          <p i18n>We're processing this video. Check back later or download this video now.</p>
          <a [href]="downloadUrl" class="btn btn-primary" i18n>Download</a>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #noAccess>
    <div class="no-preview" [style.height]="docHeight">
      <div class="no-preview-content">
        <p i18n>Your browser's cookie settings are blocking this document to load.</p>
        <ng-container i18n>Click <a [href]="url" target="_blank">here</a> to visit the
          document.</ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #storageView>
  <ng-container *ngIf="viewerType;else loader;">
    <ng-container [ngSwitch]="viewerType">
      <video *ngSwitchCase="'video'" style="width:100%;" [style.height]="docHeight" controls
        controlsList="{{disableDownload ? 'nodownload' : ''}}">
        <source [src]="url">
      </video>

      <img [src]="url" *ngSwitchCase="'image'" style="width:100%;" [style.height]="docHeight" />

      <iframe [src]="url | safe" *ngSwitchCase="'embed'" style="width:100%;" [style.height]="docHeight"></iframe>

      <pdf-viewer [original-size]="false" [src]="url" style="width:100%;" [style.height]="docHeight"
        *ngSwitchCase="'pdf'"></pdf-viewer>

      <ngx-doc-viewer disableContent="{{disableDownload ? 'popout' : ''}}" [url]="url" *ngSwitchDefault
        [viewer]="viewerType" style="width:100%;" [style.height]="docHeight">
      </ngx-doc-viewer>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loader>
  <div [height]="docHeight">
    <content-loader height="400" width="400" primaryColor="#eee" secondaryColor="#ddd">
      <svg:rect x="0" y="0" width="400" height="400" />
    </content-loader>
  </div>
</ng-template>