<label *ngIf="label">{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<ng-select2 width="100%" [options]="options" (valueChanged)="valueChanged($event)" class="custom-select-field"
  [id]="controlName" [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}" [data]="data"
  [disabled]="disabled">
</ng-select2>
<div class="search-tag-wrapper row">
  <div class="authors" *ngFor="let community of selectedCommunities">
    <div class="avatar avatar-sm">
      <img [src]="community.logo">
    </div>
    <div class="mt-8"><a [routerLink]="">{{community.name}}</a></div>
    <div class="authors-action">
      <a (click)="remove(community.id)" class="circle-actions circle-actions-sm"><i class="fas fa-times"></i></a>
    </div>
  </div>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required">
    {{placeholder}}
  </div>
</div>
