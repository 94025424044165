import { Injectable } from '@angular/core';
import { AsyncValidatorFn, UntypedFormArray, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }

  checkboxesRequiredValidation(control: UntypedFormArray) {
    if (control.getRawValue().filter(val => val).length === 0) {
      return { required: true };
    }
    return null;
  }

  whiteSpaceValidation(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').toString().replace(/\r?\n|\r/g, ' ').replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ')
      .trim().length === 0;
    return isWhitespace ? { 'whitespace': true } : null;
  }

  urlValidation(control: UntypedFormControl) {
    const url = (control.value || '');
    if (url) {
      const validUrl = url
        .match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
      return validUrl ? null : { 'url': true };
    }
    return null;
  }

  validateUrl(url) {
    return url
      .match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
  }

  gitUrlValidation(control: UntypedFormControl) {
    const validUrl = (control.value || '');
    if (validUrl) {
      const pattern = '^(http:\/\/labs.globallogic.com\/|https:\/\/labs.globallogic.com\/' +
        '|http:\/\/github.com\/|https:\/\/github.com\/)';
      const patt = new RegExp(pattern);
      const res = patt.test(validUrl);
      return res ? null : { 'url': true };
    }
    return null;
  }

  sameUrlValidation(otherControl) {
    return (control: UntypedFormControl) => {
      const value = (control.value || '');
      const otherValue = (otherControl.value || '');
      if (value) {
        return this.sameUrl(value, otherValue) ? { 'sameUrl': true } : null;
      }
      return null;
    };
  }

  sameUrl(value, otherValue) {
    value = (value || '').replace('https://', '').replace('http://', '').replace('www.', '').replace(/\//g, '').trim();
    otherValue = (otherValue || '').replace('https://', '').replace('http://', '').replace('www.', '').replace(/\//g, '')
      .trim();
    return value === otherValue;
  }

  existValidator(service, id = null): AsyncValidatorFn {
    return (control: UntypedFormControl): Observable<ValidationErrors> => {
      const data = { id, name: control.value };
      return service.checkName(data).pipe(
        map((result: boolean) => result['valid'] ? null : { exist: true })
      );
    };
  }

  numberValidation(control) {
    const value = (control.value || 0);
    if (value) {
      const pattern = '^[0-9]+$';
      const patt = new RegExp(pattern);
      const res = patt.test(value);
      return !res ? { 'number': true } : null;
    }
    return null;
  }

  tagExist(tags, keyword) {
    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i];
      if (keyword == tag.name || keyword == tag.alias_name) {
        return true;
      } else {
        let synonyms = tag.synonyms;
        for (let i = 0; i < synonyms.length; i++) {
          var synonym = synonyms[i];
          if (keyword == synonym.synonym || keyword == synonym.alias_synonym) {
            return true;
          }
        }
      }
    }
    return false;
  }

  tagExistValidator(service, id = 0): AsyncValidatorFn {
    return (control: UntypedFormControl): Observable<ValidationErrors> => {
      let params = { 'filters[search]': control.value };
      if (id > 0) {
        params['filters[exceptTagId]'] = id;
      }
      return service.getTags(params).pipe(
        map((result: boolean) => this.tagExist(result['tags'], control.value) ? { tagexist: true } : null)
      );
    };
  }

  cnameValidation(control: UntypedFormControl) {
    const cname = (control.value || '');
    if (cname) {
      const pattern = '^[a-zA-Z0-9-]+$';
      const patt = new RegExp(pattern);
      const res = patt.test(cname);
      return res ? null : { 'cname': true };
    }
    return null;
  }

  atLeastOne(otherControl) {
    return (control: UntypedFormControl) => {
      const value = (control.value || '');
      const otherValue = (otherControl.value || '');
      return (value || otherValue) ? null : { atLeastOne: true }
    }
  }

  maxArrayLength(maxlength) {
    return (control: UntypedFormControl) => {
      const value = (control.value || []);
      return (value.length <= maxlength) ? null : { maxlength: true, requiredLength: maxlength }
    }
  }
}

