<div class="filter_nested_multiselect">
    <ng-select [items]="field.options" [multiple]="true" bindLabel="name" groupBy="groupBy"
        notFoundText="No result found" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
        [clearable]="false" [searchable]="false" (change)="valueChange($event)" [(ngModel)]="selected"
        [placeholder]="'Select ' + field.label" [ngClass]="field.name+'-filter'">
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
            {{item.groupBy}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
            {{item.name}}
        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items">
            <ng-container *ngFor="let item of items;let last = last">
                {{item.name}}{{last ? '' : ', '}}
            </ng-container>
        </ng-template>
    </ng-select>
</div>