import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-edit-cc-location',
  templateUrl: './edit-cc-location.component.html',
  styleUrls: ['./edit-cc-location.component.css']
})
export class EditCcLocationComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() data;
  submitted = false;
  httpError;

  constructor(private fb: UntypedFormBuilder, private modalService: ModalPopupService, private practiceService: PracticesService,
    private toastrService: ToastrService, private commonService: CommonMethodsService) { }

  ngOnInit() {
    this.form = this.fb.group({
      locations: this.fb.control([], [])
    });
  }

  cancel() {
    this.modalService.close();
  }

  onSubmit() {
    if (this.form.value['locations'].length || this.data['locations'].length) {
      if (!this.submitted) {
        this.submitted = true;
        const data = { action: 'ccUpdate', locations: this.form.value['locations'].map(loc => loc.id) };
        this.practiceService.addMember(this.data['id'], this.data['mid'], data)
          .subscribe(response => {
            this.toastrService.success(response['message']);

            const headers = new HttpHeaders({
              'Cache-Control': 'no-cache'
            });

            let action = 'remove';
            if (data.locations.length) {
              action = 'add';
            }

            this.updateGmailGroups(this.data['email'], this.data['id'], action, 3);
            this.practiceService.updateMembershipEvent.emit(headers);
            this.cancel();
            this.submitted = false;
          }, error => {
            if (data.locations.length) {
              this.submitted = false;
              this.httpError = this.commonService.parseHttpErrors(error);
            } else {
              this.cancel();
            }
          });
      }
    } else {
      this.cancel();
    }
  }

  updateGmailGroups(emailId, practiceId, action, member_type_id?, from_member_type_id?) {
    this.practiceService.updateGmailGroups(emailId, practiceId, action, member_type_id, from_member_type_id)
      .subscribe(response => { }, error => { console.error((error)); });
  }
}
