import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LikeService } from 'app/services/like/like.service';
import { FilterService } from 'app/services/filter/filter.service';
import { ScrollService } from 'app/services/scroll/scroll.service';
import { PeopleService } from 'app/services/people/people.service';
import { Subscription } from 'rxjs';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-liked-by',
  templateUrl: './liked-by.component.html',
  styleUrls: ['./liked-by.component.css']
})
export class LikedByComponent implements OnInit, OnDestroy {
  @Input() data;
  likes = [];
  page = 2;
  pageEnd = false;
  loading = false;
  scrollEvent = new Subscription();

  constructor(private likeService: LikeService, private filterService: FilterService, private scrollService: ScrollService,
    private peopleService: PeopleService, private changeDetection: ChangeDetectorRef, private commonService: CommonMethodsService) { }

  ngOnInit() {
    this.likes = this.data['likes'];
    // this.scrollEvent = this.scrollService.scrollEvent
    //   .subscribe(response => {
    //     if (!this.pageEnd && !this.loading && response['event'] === 'complete') {
    //       this.getLikes();
    //     }
    //   });
  }

  getLikes() {
    this.loading = true;
    this.changeDetection.detectChanges();
    const filter = [
      { key: 'page', values: [this.page] },
      { key: 'perPage', values: [20] },
      { key: 'entity_category_id', values: [this.data['cid']] }
    ];
    const params = this.filterService.getApiParams(filter);
    this.likeService.getLikes(this.data['id'], params)
      .subscribe(response => {
        this.likes = [...this.likes, ...response['likes']];
        if (this.page * 20 >= response['total_count']) {
          this.pageEnd = true;
        }
        this.page++;
        this.loading = false;
        this.changeDetection.detectChanges();
        this.scrollService.scrollEvent.emit({ event: 'update' });
      }, () => {
        this.loading = false;
        this.changeDetection.detectChanges();
      });
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person);
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }

  ngOnDestroy() {
    this.scrollEvent.unsubscribe();
  }
}
