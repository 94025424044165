import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommentService } from 'app/services/comment/comment.service';
import { PeopleService } from 'app/services/people/people.service';
import { LoginService } from 'app/services/auth/login.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html',
  styleUrls: ['./comment-reply.component.css']
})
export class CommentReplyComponent implements OnInit {
  @Input() entityId;
  @Input() entityCategoryId;
  @Input() replies;
  @Input() comment;

  @Output() onreply = new EventEmitter();

  hideReplies = true;
  commentReplyForm: UntypedFormGroup;
  commentEditForm: UntypedFormGroup;
  currenrUser;
  submitted = false;
  ckeConfig = this.commentService.ckeConfig;
  supperUser = false;
  isPerson = this.loginService.isPerson();

  constructor(public commentService: CommentService, private loginService: LoginService, private peopleService: PeopleService,
    private formValidator: FormValidatorService, private modalService: ModalPopupService, private commonService: CommonMethodsService) { }

  ngOnInit() {
    this.currenrUser = this.loginService.currentUser['person'];
    for (const reply of this.replies) {
      if (reply['id'] === this.commentService.commentId) {
        this.hideReplies = false;
      }
    }

    this.supperUser = this.loginService.isServiceAccount() || this.loginService.isSuperUser() || this.loginService.isGlobalHead();
  }

  createReplyCommentForm() {
    this.commentReplyForm = new UntypedFormGroup({
      comment: new UntypedFormControl(null, [this.formValidator.whiteSpaceValidation, Validators.maxLength(1000)]),
    });
    this.onreply.emit({ id: this.comment['id'], action: 'reply' });
  }

  editCommentForm(id, type?) {
    this.commentService.editCommentId = id;
    if (type === 'reply') {
      const comment = this.replies.filter(c => c.id === id)[0];
      this.commentEditForm = new UntypedFormGroup({
        comment: new UntypedFormControl(comment['comment'], [this.formValidator.whiteSpaceValidation, Validators.maxLength(1000)])
      });
      this.onreply.emit({ id, action: 'edit' });
    } else {
      this.commentService.commentEvent.emit({ event: 'edit', id });
    }
  }

  submitReplyComment(type?) {
    this.submitted = true;
    if (type === 'edit') {
      const comment = this.commentEditForm.get('comment').value.trim();
      const data = {
        comment: comment,
        emails: this.commentService.getTagedEmail(comment)
      };
      this.commentService.updateComment(this.entityId, this.commentService.editCommentId, data)
        .subscribe(response => {
          this.replies.forEach((c, i) => {
            if (c.id === this.commentService.editCommentId) {
              this.replies[i] = response['comment'];
            }
          });
          this.submitted = false;
          this.cancel('edit');
        }, error => this.submitted = false);
    } else {
      const comment = this.commentReplyForm.get('comment').value.trim();
      const data = {
        entity_category_id: this.entityCategoryId,
        comment: comment,
        parent_comment_id: this.comment['id'],
        emails: this.commentService.getTagedEmail(comment)
      };

      this.commentService.submitComment(this.entityId, data)
        .subscribe(response => {
          this.commentReplyForm = undefined;
          this.replies.unshift(response['comment']);
          this.commentService.commentCount += 1;
          this.hideReplies = false;
          this.submitted = false;
        }, error => this.submitted = false);
    }
  }

  cancel(type?) {
    if (type === 'edit') {
      this.commentEditForm = undefined;
      this.commentService.editCommentId = 0;
    } else {
      this.commentReplyForm = undefined;
    }
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person);
  }

  getEditCommentId() {
    return this.commentService.editCommentId;
  }

  deleteComment(cid, type?) {
    if (type === 'reply') {
      const data = {
        title: $localize`Delete comment`, message: $localize`Are you sure you want to delete this comment?`,
        size: 'md', showFooter: true
      };
      this.modalService.confirm(data)
        .then((confirmed) => {
          if (confirmed) {
            this.commentService.deleteComment(this.entityId, cid)
              .subscribe(response => {
                this.replies = this.replies.filter(c => c.id !== cid);
                this.commentService.commentCount = response['total_count'];
              });
          }
        });

    } else {
      this.commentService.commentEvent.emit({ event: 'delete', id: cid });
    }
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}
