// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // cmsUrl: 'http://localhost/cms/',
  // apiDomain: 'localhost:3000',
  // apiUrl: 'http://localhost:3000',
  cmsUrl: 'http://cms-practices-dev.globallogic.com/',
  apiDomain: 'practice-api-dev-dot-practice-portal.appspot.com',
  apiUrl: 'https://practice-api-dev-dot-practice-portal.appspot.com',
  googleClientId: '56492559812-jit8842gsk7vr718t27i6qfng0ika3rp.apps.googleusercontent.com',
  googleDeveloperKey: 'AIzaSyCGIJtiWqPexphYikvLRQ8vhBiBUEQIYfI',
  src: 'dev',
  bucket: 'gl-practice-library',
  uploadHosts: ["storage.googleapis.com", "www.googleapis.com", "storage.cloud.google.com"],
  previewBaseUrl: "https://storage.googleapis.com",
  nuggetBaseUrl: "https://storage.cloud.google.com",
  driveBaseUrl: "https://www.googleapis.com/drive/v3/files",
  aclDomains: ['globallogic.com', 'method.com'],
  bucketUrl: 'https://storage.googleapis.com/gl-practice-library',
  cmsBucketUrl: 'https://storage.googleapis.com/gl-practice-library/cms-dev',
  tagApiUrl: 'https://tag-api-dot-practice-portal.appspot.com/api/v1',
  driveUrl: 'https://drive.google.com/a/globallogic.com',
  // carouselFolderId: '1rl5ZNf_O-bjsIY3pJCsUs4MDYOmFpxpq',
  tempFolderId: '1p16pq1oAWfHcYInpS588nwtFRaBf2wfO',
  // techDocFolderId: '172bJFxCHMl8k_9pWMOMuoXxkJvQEQo1n',
  // techTalkVideoFolderId: '1_piwfzclaaKdAXz9iBHqCjtkbgPmq_Kw',
  // techTalkPresentationFolderId: '1LRcGBIsm_Ze9OrJtC7tiy9CVNnFenSNf',
  // pocProposalFolderId: '1sLzCyB3-xVrbRGvO3nq8w59OcOlOHyXu',
  // pocPreviewFolderId: '1igd_pMufXo6CWlXro-Cniwu3Cg6jbKqf',
  // pocDemoFolderId: '10Ml-XsDB5iLVdfQapxWNAqJsWsqR6rxN',
  // pocDocumentFolderId: '1FpuSXGEKZOcKo7bDhvWDHxY-fwmI5RqM',
  // pocCloudCostingProposalFolderId: '1NSxtawOanfDBdGo22MkAYJgmpqamrF_4',
  // pocCloudCostingProposalTemplateId: '1S_-lp3DeRORNwUbF5Z8EJDgk6vRS0nj_US35USSsrmA',
  // customerActivityPublicFolderId: '1VNibCtqU9EpcEQ_3AOyNGwQnmg5kTCzB',
  // customerActivityConfidentialFolderId: '15bQoyxB4UvqhkweSc_VA7DTyaETZLg3Z',
  // advisoryFolderId: '1ZVHGxjTu_n3jKcTT8a-VLaxeFLkmX986',
  // clientFolderId: '1bI7xsJDBsKdo90pY5kbK5AkqlSMuATA2',
  // deckBigdataFolderId: '1eB7x3gqKylHEmEXVk_sdUY37OPuBntD6',
  // deckCloudFolderId: '10maw3gd4kJRusxTQ09Lp3uqKKDVM3Gw8',
  // deckEmbeddedFolderId: '1iyL8QfQaD0lg0fjudg60zYfwSEpdz1l8',
  // deckMobilityFolderId: '146H1xNyX66WKv4JGnV_DoycwZRIZhbaT',
  // deckUXUIFolderId: '1sGXC2UwfL6DP_jXJq38qZfPxkzfsMzXO',
  // deckSecurityFolderId: '1VwdVIKey03qgOya7nSTZMwYx1WV_9vhq',
  // deckDevopsFolderId: '1t-EueCMNyzj4rkHQ0YW1yDF3EHLRXE4c',
  // deckArchituctureFolderId: '1BEIa_frGBQrlA0JE1l9h8HEkF4HPEAuc',
  // deckAgileFolderId: '1BsKbbcUDiu-vBx-uMZhmRQaLSPYARIuy',
  // deckAIFolderId: '11RqdpNhtndrgtKmcZl_6ArF7lbBB29g7',
  // deckBlockchainFolderId: '1w_9JHkewRo343yaEzJvaG7Jn94HUh6T-',
  // deckKnowledgeManagementFolderId: '1JUIy2mOB37TK9GpfJCGsGjElVhBms2b2',
  // deckDigitalAssuranceFolderId: '1IZqoYUcgVzzv3nmrQDbKJeOopPxkKOEa',
  // personDocumentFolderId: '1U_dc1TkVHKn5gwsMV6w35yVsuysIAPFO',
  gloUrl: 'https://glo-staging.globallogic.com/',
  // practicesDataStudioUrl: 'https://datastudio.google.com/embed/reporting/1EwvKfyjXJP07ggqPEcoT2B3j2ePEC-nM/page/BVlY',
  siteUrl: 'https://practices-dev.globallogic.com',
  diyGuideID: 68,
  partnershipPlaybookID: 69,
  tourID: 71,
  msctID: 72,
  mscExcelID: 75,
  assessmentSGID: 90,
  cmsPageIds: {
    home: 130,
    practices: 139,
    partnerships: 35,
    admin: 25,
    taskTracker: 93,
    webex: 79,
    memberManagementHelp: 86,
    partnershipPlaybook: 89,
    mailingLists: 91,
    pocWorkflow: 99,
    calendar: 77,
    forum: 85,
    newsletters: 22,
    newsletterFY19Q1: 64,
    newsletterFY19Q2: 66,
    newsletterAug2017: 68,
    newsletterSep2017: 73,
    newsletterOctNov2017: 70,
    newsletterDec2017Jan2018: 62,
    newsletterAgileJul2018: 45,
    newsletterEmbeddedSep2018: 50,
    newsletterGlobalPracticesAwardsAnnouncementFY20Q1Q2: 52,
    newsletterGlobalPracticesFY20Q3: 58,
    newsletterSecurityPracticeMar2020: 75,
    newsletterAIMLPracticeApr2020: 48,
    // solutionAccelerators: 103,
    // opeNgineAccelerator: 105,
    // microServiceAccelerator: 158,
    // dpaAccelerator: 164,
    // mlAccelerator: 188,
    // intelliInsightsAccelerator: 190,
    // coreRetailAccelerator: 199,
    // bopusAccelerator: 207,
    // curbsideAccelerator: 197,
    // chatbotAccelerator: 204,
    // nglpAccelerator: 185,
    // shopingCartAccelerator: 172,
    // voiceCommerceAccelerator: 167,
    // storeProximityAccelerator: 169,
    // paymentIntegrationAccelerator: 182,
    // barcodeQrAccelerator: 201,
    // digitalWalletAccelerator: 192,
    // productReviewsAccelerator: 180,
    // customerSegmentationAccelerator: 195,
    // salesForecastingAccelerator: 176,
    // assessments: 785,
    // industryAccelerator: 782,
    importantLinks: 209,
    webinars: 118,
    // phoenixAccelerator: 273,
    // aiotAccelerator: 375,
    // autofusionAccelerator: 750,
    // inteliQAccelerator: 877,
    // diyGuide: 81,
    // diyGuideMethod: 82,
    digitalAccelerators: 1025,
    // androidAppAccelerator: 1232,
    // gtaf: 1311,
    // PRIMeAccelerator: 1560,
    // intelligentSpaces: 1853,
    // ottChannelDevelopmentAccelerator: 1858,
    // ottTestlab: 1859,
    // cloudwave: 1949,
    reactNative: 1916,
    // scaleQA: 1976,
    // nativeMobile: 1972,
    flutter: 1918,
    androidAccCA: 1232,
    androidAccMVVM: 1911,
    iOSAccelerator: 1914,
    // mobileAccelerator: 2210,
    msaJavaAccelerator: 1828,
    msaNetAccelerator: 1829,
    digitalDelivery: 1760,
    digitalDeliveryPM: 1760,
    digitalDeliveryProductManagement: 1760,
    digitalDeliveryPMPT: 1760,
    digitalDeliveryPMPDT: 1760,
    digitalDeliveryPMMRAT: 1760,
    digitalDeliveryPMDAT: 1760,
    digitalDeliveryPMTCT: 1760,
    digitalDeliveryPMMTT: 1760,
    practiceSecuritySca: 2382,
    techRadar: 1730,
    processTrackIdeation: 4738,
    processTrackAnalysis: 4743,
    processTrackDevelopment: 4745,
    processTrackGoToMarket: 4747,
    processTrackInLife: 4749,
    processTrackEndOfLife: 4751,
    trainingCertificationCompleted: 4753,
    trainingCertificationUpcoming: 4756,
    trainingCertificationConducted: 4758,
    deliveryAssessmentProcess: 4760,
    deliveryAssessmentFramework: 4762,
    deliveryAssessmentAccomplished: 4764,
    practicesNewsletter: 2114,
    pocFAQ: 2122,
    writerCafe: 2206,
    writerCafeSeason1: 2274,
    writerCafeSeason2: 2233,
    hCombinator: 2171,
    hCombinatorS1: 2746,
    collection: 2840,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
