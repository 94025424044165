<div [ngClass]="{'height-40':fixHeight}" appStopPropagation>
  <ng-container *ngFor="let member of membersList | slice:0:displayLength">
    <a [routerLink]="['/team',member['login'] || member['person_id']]" class="mr-5"
      *ngIf="(member['login'] || member['person_id']);else noLogin">
      <ng-template #toolTipContent>
        <app-tooltip-person-card [data]="member" (mouseleave)="t.close()">
        </app-tooltip-person-card>
      </ng-template>
      <span class="avatar" [ngClass]="classes" [ngbTooltip]="toolTipContent" #tooltip="ngbTooltip" tooltipClass='p-card-tooltip'
        container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
        (mouseleave)="onMouseleave($event,t)">
        <img [src]="member.photo_url">
      </span>
    </a>

    <ng-template #noLogin>
      <a href="javascript:;" class="mr-5">
        <ng-template #toolTipContent>
          <app-tooltip-person-card [data]="member" (mouseleave)="t.close()">
          </app-tooltip-person-card>
        </ng-template>
        <span class="avatar" [ngClass]="classes" [ngbTooltip]="toolTipContent" #tooltip="ngbTooltip" tooltipClass='p-card-tooltip'
          container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
          (mouseleave)="onMouseleave($event,t)">
          <img [src]="member.photo_url">
        </span>
      </a>
    </ng-template>
  </ng-container>

  <a href="javascript:;" class="more-user" [ngClass]="addMoreClass" *ngIf="moreMember > 0" (click)="open()"
    appStopPropagation>+{{moreMember}}</a>
</div>
