<!-- More Links -->
<div class="advance-filters" (clickOutside)="onClickOutside($event)" clickOutsideEvents="click,touchstart">
  <a *ngIf="filterService.advancePageFilters.length" (click)="toggleAdvanceFilter()">Advance Filters</a>
  <div class="advance-filter-modal" [ngClass]="{'d-block':showAdvanceFilter}">
    <app-advance-filter-form (closeEvent)="closeAdvanceFilter()"></app-advance-filter-form>
  </div>
</div>

<div class="more-actions ml-30">
  <a class="circle-actions" (click)="filterService.clearFilters()" *ngIf="filterService.filters.length" i18n-ngbTooltip
    container="body" ngbTooltip="Clear Filters"><i class="fa-sharp fa-regular fa-filter-circle-xmark"></i></a>
  <a class="circle-actions" (click)="filterService.export()" *ngIf="showExport" i18n-ngbTooltip container="body"
    ngbTooltip="Export to Excel"><i class="fa-sharp fa-light fa-file-spreadsheet"></i></a>
  <a class="circle-actions" (click)="filterService.copy()" *ngIf="filterService.filters.length" i18n-ngbTooltip
    container="body" ngbTooltip="Copy Filtered URL"><i class="fa-sharp fa-light fa-copy"></i></a>
</div>





<!-- <div class="more-actions ml-10">
  <a class="circle-actions circle-actions-more">
    <i class="fas fa-ellipsis-h"></i>
  </a>
  <div class="more-actions-items">
    <a (click)="filterService.copy()" i18n>Copy Filtered URL</a>
    <a (click)="filterService.export()" *ngIf="showExport" i18n>Export to Excel</a>
  </div>
</div> -->

<!-- <a class="clear-filter" (click)="filterService.clearFilters()" *ngIf="filterService.filters.length" i18n>Clear
  Filter</a> -->