import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivationStart, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { LoginService } from './services/auth/login.service';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from './services/filter/filter.service';
import { CommonMethodsService } from './services/util/common-methods.service';
import { map, filter } from 'rxjs/operators';
import { AppService } from './services/app/app.service';
import { IntrojsService } from './services/introjs/introjs.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { PracticesService } from './services/practices/practices.service';
import { CloudPickerService } from "./services/cloud-picker/cloud-picker.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  id = '';
  showNotifications = false;
  showActions = false;
  class = 'container';
  showBackButton = false;
  cms = '';
  introRunning = false;
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  uploadProgress = 0;


  // initialize breadcrumb
  constructor(private router: Router, public loginService: LoginService, private titleService: Title,
    private modalService: NgbModal, public filterService: FilterService, public commonService: CommonMethodsService,
    private route: ActivatedRoute, private appService: AppService, private changeDetectorRef: ChangeDetectorRef,
    private introjsService: IntrojsService, private breadcrumbsService: BreadcrumbService,
    private practicesService: PracticesService, private cloudPickerService: CloudPickerService) { }

  ngOnInit() {
    if (['technology.globallogic.com', 'lumada.globallogic.com'].includes(location.hostname)) {
      window.open('https://practices.globallogic.com', '_self');
      return;
    }

    this.introjsService.introJSEvent
      .subscribe(response => this.introRunning = response);

    this.route.queryParamMap
      .subscribe(param => {
        if (param.has('cmsUrl')) {
          this.cms = param.get('cmsUrl');
        }
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.modalService.dismissAll(); // close model popup
      }

      if (event instanceof ActivationStart) {
        this.showBackButton = event.snapshot.data.showBack || false;
        this.titleService.setTitle(event.snapshot.data['title'] || event.snapshot.data['breadcrumb']?.label || event.snapshot.data['breadcrumb'] || 'Practices');
        if (event.snapshot.data['fullWidth']) {
          this.class = ' full-width-page';
        } else if (event.snapshot.data['fluid']) {
          this.class = ' container-fluid';
        } else {
          this.class = ' container';
        }

        if (event.snapshot.data['class']) {
          this.class += ' ' + event.snapshot.data['class'];
        }
        // hide breadcrumb
        if (event.snapshot.data['hideBreadcrumb']) {
          this.commonService.showBreadcrumb = false;
        } else {
          this.commonService.showBreadcrumb = true;
        }
      }

      // For Google Analytics
      if (event instanceof NavigationEnd) {
        this.showNotifications = false;
        this.showActions = false;
        if (this.commonService.searchForm && this.commonService.searchForm.get('search').value &&
          !event.url.includes('/search-results')) {
          this.commonService.searchForm.get('search').setValue(null);
        }

        if (environment.production) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      }
    });

    // create logs
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      map((route) => route.snapshot),
    ).subscribe((route) => {
      const data = { referer: window.location.href };
      if (route.data.entityCategoryId) {
        data['entity_category_id'] = route.data.entityCategoryId;
        data['entity_id'] = route.paramMap.get('id');
      }
      if (route.queryParams && ['tech-talk', 'tech-document'].includes(route.queryParams.type)) {
        data['entity_category_id'] = route.queryParams.type === 'tech-talk' ? 2 : 3;
        data['entity_id'] = route.paramMap.get('id');
      }
      if (route.data.saveEntityId) {
        data['entity_id'] = route.paramMap.get('id');
      }

      this.appService.saveVisitorLog(data)
        .subscribe(() => {
          this.appService.visitorLogEvent.emit(true);
        },
          e => { this.appService.visitorLogEvent.emit(true); });
    });

    this.commonService.loaderEvent
      .subscribe(r => {
        this.commonService.showLoader = r;
        this.changeDetectorRef.detectChanges();
      });


    this.commonService.fileLoaderEvent
      .subscribe(r => {
        this.commonService.showFileLoader = r;
        this.changeDetectorRef.detectChanges();
      });

    setTimeout(() => {
      // refresh practice cards
      const headers = new HttpHeaders({
        'Cache-Control': 'no-cache'
      });
      this.getPracticesCard(headers);
    }, 5000);
  }

  onActivate(event) {
    if (!this.introjsService.displayTour) {
      window.scroll(0, 0);
    }
    // if (!this.introjsService.tourLoadedOnce) {
    //   this.introjsService.siteIntro();
    // }   
  }

  getPracticesCard(headers?) {
    this.practicesService.getPracticesCards(headers)
      .subscribe();
  }

  deletePractice(params) {
    const { practiceName, practiceId } = params;
    this.practicesService.deletePracticeHelper(practiceName, practiceId);
  }

  getHeaderClass() {
    if (this.filterService.class.indexOf('filter-page ') > -1)
      return this.filterService.class.replace("filter-page ", "header-")

    return '';
  }
}
