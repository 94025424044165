<div class="custom-file" [ngClass]="{'validate':errorMessage}">
  <button class="btn btn-border btn-radius" tooltipClass='document-tooltip' [ngbTooltip]="extension || ''"
    container="body" type="button" (click)="uploader.click()" (blur)="onBlur($event)">
    <i class="fa-solid fa-arrow-up-from-bracket"></i> &nbsp;&nbsp;{{buttonText}}
  </button>
</div>
<input id="uploader" hidden #uploader type="file" [accept]="mimeTypes" onclick="value = null"
  (change)="onFileChange($event)" appStopPropagation>

<div class="invalid" *ngIf="errorMessage">{{errorMessage}}</div>
