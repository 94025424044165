<h2 class="mb-10"> <ng-container i18n>Tags</ng-container>
  <a class="circle-actions ml-5" i18n-ngbTooltip ngbTooltip="Edit" *ngIf="editAccess" (click)="edit = !edit">
    <i class="fas fa-pencil-alt"></i>
  </a>
</h2>
<div class="alert al-mar clearfix" [hidden]="!statusMsg" [ngClass]="alertClass">{{statusMsg}}</div>
<ng-container *ngIf="!edit;else editTags;">
  <span class="nugget-tag" *ngFor="let tag of tags">{{tag.name}}</span>
</ng-container>

<ng-template #editTags>
  <form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <ng-select2 [options]="options" width="100%" (valueChanged)="valueChanged($event)" [value]="value" [data]="data">
      </ng-select2>
      <input formControlName="tags" type="hidden">
    </div>
    <div class="">
      <input class="btn btn-primary" type="submit" value="Update">
      <a href="javascript:void(0)" class="ml-10" (click)="cancel()">Cancel</a>
    </div>
  </form>
</ng-template>