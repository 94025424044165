import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PeopleService } from 'app/services/people/people.service';
import { FilterService } from 'app/services/filter/filter.service';
import { findIndex, isEqual } from 'lodash';
import { Router } from '@angular/router';
import { TooltipService } from 'app/services/tooltip/tooltip.service';

@Component({
  selector: 'app-members-chip',
  templateUrl: './members-chip.component.html',
  styleUrls: ['./members-chip.component.css']
})
export class MembersChipComponent implements OnChanges {
  moreMember = 0;
  @Input() members = [];
  @Input() totalMembers = 0;
  @Input() loadPractices = false;
  @Input() displayNumber = 5;
  @Input() classes = 'avatar-sm';
  @Input() filters = [];
  @Input() fixHeight = true;
  addMoreClass = 'more-user-sm';
  membersList = [];
  // height = 40;
  originalMember;
  displayLength = 0;

  @ViewChild('tooltip') tooltip: NgbTooltip;

  constructor(private modalService: ModalPopupService, private commonService: CommonMethodsService, public peopleService: PeopleService,
    private filterService: FilterService, private router: Router, private tooltipService: TooltipService) {
  }

  ngOnChanges() {
    if (!isEqual(this.members, this.originalMember)) {
      this.originalMember = this.members;

      if (this.members && this.members.length === undefined) {
        this.membersList.push(this.members);
      } else {
        this.membersList = this.members || [];
      }
      this.membersList = this.membersList;
      this.displayLength = this.commonService.getDisplayCount((this.totalMembers || this.membersList.length), this.displayNumber);
      this.moreMember = (this.totalMembers || this.membersList.length) - this.displayLength;
      if (this.classes === 'avatar-xs') {
        this.addMoreClass = '';
        // this.height = 30;
      } else if (this.classes === 'avatar-xl') {
        // this.height = 75;
      }

      if (this.loadPractices) {
        // get member detail
        const personIds = this.membersList.map(m => m.login);
        if (personIds.length) {
          const params = this.filterService.getApiParams([{ key: 'filters[people][]', values: personIds }]);
          this.peopleService.getTeam(params)
            .subscribe(response => {
              this.membersList = this.membersList.map(m => {
                const i = findIndex(response['people'], { login: m.login });
                if (i > -1) {
                  m = response['people'][i];
                }
                return m;
              });
            });
        }
      }
    }

    this.tooltipService.hideTooltip$.subscribe(() => {
      this.tooltip.close();
    })
  }

  open() {
    if (this.totalMembers) {
      const queryParams = this.filterService.getQueryParams(this.filters);
      let params = '';
      Object.keys(queryParams).forEach((param) => {
        params += (params ? '&' : '?') + param + '=' + encodeURIComponent(queryParams[param]);
      });
      window.open(`/team${params}`, '_blank');
      return false;
    }
    const data = { title: 'Members', message: '', component: 'MembersListComponent', data: this.membersList, size: 'sm' };
    this.modalService.open(data);
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

