<div class="nugget-card" (mouseenter)="showPreviewIcon=true" (mouseleave)="showPreviewIcon=false">
  <a [routerLink]="['/practices',data.practice?.c_name,'sales-materials',data.id]">
    <div class="card card-full-img"
      [ngStyle]="{backgroundImage: 'url(' + data.preview_url + ')', 'background-color':'#ccc'}">
      <div class="case-study-action" *ngIf="!data.readOnly" appStopPropagation>
        <ng-container *ngIf="isPrivileged && showSortIcons">
          <ng-container *ngIf="!first">
            <a class="circle-actions mt-5" (click)="updateOrder($event, 'first')" placement="bottom" i18n-ngbTooltip
              i18n-ngbTooltip ngbTooltip="Move to first" container="body">
              <i class="fas  fa-angle-double-left font-16"></i>
            </a>
            <a class="circle-actions mt-5" (click)="updateOrder($event, 'up')" placement="bottom" i18n-ngbTooltip
              i18n-ngbTooltip ngbTooltip="Move to left" container="body">
              <i class="fas fa-caret-left font-16"></i>
            </a>
          </ng-container>
          <ng-container *ngIf="!last">
            <a class="circle-actions mt-5" (click)="updateOrder($event, 'down')" placement="bottom" i18n-ngbTooltip
              i18n-ngbTooltip ngbTooltip="Move to right" container="body">
              <i class="fas fa-caret-right font-16"></i>
            </a>
            <a class="circle-actions mt-5" (click)="updateOrder($event, 'last')" placement="bottom" i18n-ngbTooltip
              i18n-ngbTooltip ngbTooltip="Move to last" container="body">
              <i class="fas fa-angle-double-right font-16"></i>
            </a>
          </ng-container>
        </ng-container>
      </div>
      <div class="background"></div>
      <div class="infos">
        <div class="title truncate-2">
          <span [title]="data.title">
            {{data.title}}
          </span>
        </div>
        <div class="divider"></div>
        <div class="subtitle">{{data.collateral_type?.name | truncate:40}}</div>
      </div>
      <div class="deck-user-action" appStopPropagation>
        <ng-container *ngIf="!data.readOnly">
          <app-change-nugget-preview [practice]="data.practice" [entityId]="data.id" entityCategory="deck"
            [previewUrl]="data.preview_url" *ngIf="isPrivileged && showPreviewIcon">
          </app-change-nugget-preview>
          <a [routerLink]="['/practices',data.practice.c_name,'sales-materials',data.id,'edit']"
            class="circle-actions mt-5" *ngIf="isPrivileged" i18n-ngbTooltip i18n-ngbTooltip ngbTooltip="Edit"
            container="body"><i class="fas fa-pencil-alt"></i></a>
          <a href="javascript:;" class="circle-actions mt-5" i18n-ngbTooltip i18n-ngbTooltip ngbTooltip="Download"
            container="body" (click)="saveLog($event)" *ngIf="downloadUrl"> <i class="fas fa-download"></i></a>
          <a (click)="deleteDeck($event, data.id, data.title)" class="circle-actions mt-5" *ngIf="isPrivileged"
            i18n-ngbTooltip i18n-ngbTooltip ngbTooltip="Delete" container="body">
            <i class="fas fa-trash-alt"></i></a>
          <app-create-collection [entityId]="data.id" [entityCategory]="15"></app-create-collection>
        </ng-container>
        <a (click)="removeDeck($event, data.id, '15')" class="circle-actions"
          *ngIf="data.removeCollection && showPreviewIcon" i18n-ngbTooltip i18n-ngbTooltip
          ngbTooltip="Remove from My Collection" container="body"><i class="fa-solid fa-xmark"></i></a>
      </div>
    </div>
  </a>
</div>