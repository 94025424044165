import { Injectable } from '@angular/core';
import { map as rxmap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { flatten, intersection, findIndex, map } from 'lodash';
import { of, timer } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = environment.apiUrl;
  currentUser: any;
  showUserCard = false;
  driveAccess = false;
  // driveAccessChecked = false;
  cookieAlertClosed = true;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router) {
    this.setToken();
  }

  getToken(token?) {
    token = (token || localStorage.getItem('refreshToken'))
    if (token) {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + (token)
      });
      return this.http.get(this.url + '/v1/verify-auth/token', { headers });
    }
  }

  setToken() {
    // const accessToken = localStorage.getItem('accessToken');
    // this.currentUser = this.jwtHelper.decodeToken(accessToken);

    timer(0, 43200000).subscribe(() => {
      const token$ = this.getToken();
      if (token$) {
        token$.subscribe(response => {
          localStorage.setItem('accessToken', response['jwtToken']);
          localStorage.setItem('cmsToken', response['cmsJwtToken']);
          this.currentUser = this.jwtHelper.decodeToken(response['jwtToken']);
        });
      }
    });
  }

  login(data) {
    return this.http.post(this.url + '/v1/verify-auth', JSON.stringify(data), httpOptions)
      .pipe(rxmap((response: HttpResponse<any>) => {
        const result = response['jwtToken'] || '';
        if (result) {
          localStorage.setItem('refreshToken', result)
          return result;
        } else {
          return false;
        }
      }));
  }

  logout() {
    this.clear();
    this.router.navigate(['login'], { queryParams: { destination: this.router.url } });
  }

  isLoggedIn(url?) {
    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (this.jwtHelper.isTokenExpired(token)) {
      if (!this.jwtHelper.isTokenExpired(refreshToken)) {
        return this.getToken()
          .pipe(rxmap(response => {
            if (response['jwtToken']) {
              localStorage.setItem('accessToken', response['jwtToken']);
              localStorage.setItem('cmsToken', response['cmsJwtToken']);
              this.currentUser = this.jwtHelper.decodeToken(response['jwtToken']);
              return true;
            } else {
              this.clear();
              return false;
            }
          }))
      } else {
        this.clear();
        if (url === '' || url === '/') {
          this.router.navigate(['/login']);
        } else {
          localStorage.setItem('destination', url);
          this.router.navigate(['/login'], { queryParams: { destination: url } });
        }
        return of(false);
      }
    } else {
      this.currentUser = this.jwtHelper.decodeToken(token);
      return of(true);
    }
  }

  isPerson() {
    return !!this.currentUser.person.login;
  }

  isMethodLoggedIn() {
    if (this.currentUser && this.currentUser['user']['email'].indexOf('method.com') > -1) {
      return true;
    }
    return false;
  }

  isGloballogicLoggedIn() {
    if (this.currentUser && this.currentUser['user']['email'].indexOf('globallogic.com') > -1) {
      return true;
    }
    return false;
  }

  submitAccess() {
    return (this.isGloballogicLoggedIn() || this.isMethodLoggedIn()) && (!this.isExternalUser() || this.hasFullAccess('external_user'));
  }

  clear() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('cmsToken');
    localStorage.removeItem('destination');
    localStorage.removeItem('projectName');

    this.currentUser = null;
  }

  isPracticeHead(ids?) {
    return this.checkPracticeHead(this.currentUser.person.practice_heads, ids);
  }

  isPracticeCoHead(ids?) {
    return this.checkPracticeHead(this.currentUser.person.practice_co_heads, ids);
  }

  checkPracticeHead(headInfo, ids?) {
    if (!ids && headInfo?.length) {
      return true;
    } else if (ids && (headInfo && intersection(map(headInfo, 'id'), ids).length > 0)) {
      return true;
    } else {
      return this.isPracticeDelegate(ids);
    }
  }

  isPracticeDelegate(ids?) {
    if (ids) {
      return findIndex(this.currentUser?.person?.roles,
        r => ids.includes(r['practice_id']) && r['c_name'] === 'practice_head_delegate') > -1;
    } else {
      return this.checkPersonRole('practice_head_delegate');
    }
  }

  isServiceAccount() {
    return this.checkPersonRole('service_account');
  }

  isGlobalHead() {
    return this.checkPersonRole('global_head');
  }

  isSuperUser() {
    return this.checkPersonRole('super_user');
  }

  isRegionalHead() {
    return this.checkPersonRole('regional_head');
  }

  isAdmin() {
    return this.checkPersonRole('admin');
  }

  isSysAdmin() {
    return this.checkPersonRole('sys_admin');
  }

  isCMSEditor() {
    return this.checkPersonRole('cms_editor');
  }

  isDev() {
    return this.checkPersonRole('dev');
  }

  isAwardNominator() {
    return this.checkPersonRole('award_nominator');
  }

  hasFullAccess(role) {
    return this.checkPersonAccess('full', role);
  }

  hasReadOnlyAccess(role) {
    return this.checkPersonAccess('read_only', role);
  }

  isDigestManager() {
    return this.checkPersonRole("digest_manager");
  }

  isExternalUser() {
    return this.checkPersonRole("external_user");
  }

  checkPersonRole(role) {
    if (findIndex(this.currentUser?.person.roles, { c_name: role }) > -1) {
      return true;
    }
    return false;
  }

  checkPersonAccess(type, role) {
    if (findIndex(this.currentUser?.person?.roles, { access_type: type, c_name: role }) > -1) {
      return true;
    }
    return false;
  }

  isPrivilegedUser(ids?, includeRH = true) {
    if (this.isAllAccess() || this.isPracticeHead(ids) || this.isPracticeCoHead(ids) || includeRH && this.hasFullAccess('regional_head')) {
      return true;
    }
    return false;
  }

  hasCmsEditAccess() {
    if (this.isAllAccess() || this.isCMSEditor()) {
      return true;
    }
    return false;
  }

  hasDigestAccess() {
    if (this.isAllAccess() || this.isDigestManager()) {
      return true;
    }
    return false;
  }

  getCenterCoordinatorLocation(ids?) {
    let practices = this.currentUser.person.practices || [];
    if (ids) {
      practices = practices.filter(practice => ids.includes(practice.id));
    }
    let locations = [];
    if (practices.length) {
      locations = flatten(practices.map(practice => {
        return practice.memberships.filter(membership => membership.c_name === 'center_coordinator')
          .map(membership => membership.center?.city);
      }));
    }
    return locations;
  }

  getCenterCoordinatorLocationId(ids?) {
    let practices = this.currentUser.person.practices || [];
    if (ids) {
      practices = practices.filter(practice => ids.includes(practice.id));
    }
    let locations = [];
    if (practices.length) {
      locations = flatten(practices.map(practice => {
        return practice.memberships.filter(membership => membership.c_name === 'center_coordinator')
          .map(membership => membership.center?.id);
      }));
    }
    return locations;
  }

  isCenterCoordinator(locations?, ids?) {
    const memberLocations = this.getCenterCoordinatorLocation(ids);
    if (locations && locations.length) {
      return intersection(memberLocations, locations).length > 0;
    }
    return memberLocations.length > 0;
  }

  getCorePractices(ids?) {
    let practices = this.currentUser.person.practices || [];
    if (ids) {
      practices = practices.filter(practice => ids.includes(practice.id));
    }
    if (practices.length) {
      practices = practices.filter(practice => {
        return findIndex(practice.memberships, { c_name: 'core' }) > -1;
      });
    }
    return practices;
  }

  getSmePractices(ids?) {
    let practices = this.currentUser.person.practices || [];
    if (ids) {
      practices = practices.filter(practice => ids.includes(practice.id));
    }
    if (practices.length) {
      practices = practices.filter(practice => {
        return findIndex(practice.memberships, { c_name: 'sme' }) > -1;
      });
    }
    return practices;
  }

  isCoreMember(ids?) {
    const corePractices = this.getCorePractices(ids);
    const smePractices = this.getSmePractices(ids);
    if (ids && ids.length) {
      return intersection(map(corePractices, 'id'), ids).length > 0 || intersection(map(smePractices, 'id'), ids).length > 0;
    }
    return corePractices.length > 0 || smePractices.length > 0;
  }

  isOnlyCoreMember(ids?) {
    const corePractices = this.getCorePractices(ids);
    if (ids && ids.length) {
      return intersection(map(corePractices, 'id'), ids).length > 0;
    }
    return corePractices.length > 0;
  }

  getBand() {
    let band = 0;
    if (this.currentUser.person.band) {
      band = +this.currentUser.person.band.split(' ')[1];
    }
    return band;
  }

  isBandThreeAndAbove() {
    return this.getBand() >= 3;
  }

  isBandFourAndAbove() {
    return this.getBand() >= 4;
  }

  isTechnologySelectionAccess() {
    return this.hasFullAccess('technology_selection_manager') || this.isPrivilegedUser()
  }

  isAllAccess() {
    return this.isServiceAccount() || this.isSuperUser() || this.isGlobalHead()
  }

  isDocumentAccess() {
    return this.hasFullAccess('document_manager') || this.isPrivilegedUser()
  }
}
