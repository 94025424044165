<label for="language-options">{{label}}</label>
<div class="help">{{helpText}}</div>
<ng-select2 [data]="languages" [options]="options" (valueChanged)="valueChanged($event)" [value]="values"
  [id]="'language-options'" class="custom-select-field" width="100%"
  [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}"></ng-select2>
<div class="search-tag-wrapper row" *ngIf="multiple">
  <span class="search-tag" *ngFor="let language of selectedLanguages">
    {{language.text}}<a (click)="remove(language.id)">X</a>
  </span>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required">
    {{placeholder}}
  </div>
</div>
