<form class="dynamic-form" [formGroup]="filterService.form" (submit)="onSubmit($event)">
  <ng-container *ngFor="let field of fields;">
    <!-- <div class="filter-input" *ngIf="field.type === 'filterLink'" [ngClass]="field.type" [hidden]="field.hide">
      <ng-container appDynamicField [field]="field" [group]="filterService.form"></ng-container>
    </div>
    <ng-template> -->
    <div class="filter-input" *ngIf="field.type !== 'noUi'" [ngClass]="field.type" [hidden]="field.hide">
      <ng-container appDynamicField [field]="field" [group]="filterService.form"></ng-container>
    </div>
    <!-- </ng-template> -->
  </ng-container>
</form>