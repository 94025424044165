<div class="login-wraper">
  <div class="login-content">
    <img src="assets/images/practices-logo-w.png" alt="Practice Portal" class="mb-20">
    <h1 class="login-head" i18n>CMS LOGIN</h1>
    <button (click)="duoLogin()" type="button" class="btn btn-primary mt-50" i18n>
      Sign in with Globallogic/Method Email Account
    </button>

    <!-- <button (click)="socialSignIn('google')" type="submit" class="btn btn-primary mt-50">Sign in with Globallogic/Method
        Email Account</button> -->
    <div *ngIf="invalidLogin" class="alert alert-danger mt-22" i18n>Invalid user and/or domain.</div>

    <div class="or">
      <span></span>
      <legend>or</legend>
      <span></span>
    </div>
    <!-- <div class="explore-practices"><a routerLink="/public/cloud">Explore Globallogic Practices</a></div> -->
    <div class="explore-practices">
      <a [href]="siteUrl" target='_blank' i18n>Explore Globallogic Practices</a>
    </div>
  </div>
</div>
