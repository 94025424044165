<div class="login-wraper" [ngStyle]="{ height: screenHeight }">
  <div class="login-content">
    <img src="assets/images/practice-logo.png" alt="Practice Portal" class="mb-20">
    <h1 class="login-head" *ngIf="accessToken.length; else loginHeading">Your JWT Token is</h1>
    <ng-template #loginHeading>
      <h1 class="login-head">PORTAL LOGIN</h1>
    </ng-template>
    <button (click)="socialSignIn('google')" type="submit" class="btn btn-primary mt-50" *ngIf="accessToken.length == 0">Sign in with Globallogic Email
      Account</button>
    <div *ngIf="invalidLogin" class="alert alert-danger mt-22">Invalid user and/or domain.</div>

    <div class="mt-15" *ngIf="accessToken.length">
      <textarea class="token">{{accessToken}}</textarea>
    </div>
  </div>
</div>
