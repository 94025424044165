import { Injectable, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LoginService } from '../auth/login.service';
import { isEqual } from 'lodash';
import { ModalPopupService } from './modal-popup.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {
  showLoader = false;
  showFileLoader = false;
  scrollbarYOptions = { axis: 'y', theme: 'minimal-dark' };
  searchForm: UntypedFormGroup;
  isPublic = false;
  driveDomain = 'globallogic.com';
  itemSortEvent = new EventEmitter();
  loaderEvent = new EventEmitter();
  fileLoaderEvent = new EventEmitter();
  notifications = [];
  actions = [];
  showFooter = true;
  hideMenu = false;
  showBreadcrumb = false;
  breadcrumbs;
  breadcrumbFilters = [];

  constructor(private loginService: LoginService, private modalService: ModalPopupService, private router: Router) {
    if (this.loginService.isMethodLoggedIn()) {
      this.driveDomain = 'method.com';
    }
  }

  scrollOptions(axis, theme) {
    return { axis: axis || 'y', theme: theme || 'minimal-dark' };
  }

  getDisplayCount(length: number, display: number) {
    if (length <= display) {
      return display;
    }
    return --display;
  }

  getDriveFileIdFromUrl(url) {
    if (url) {
      const id = url.match(/[-\w]{25,}/);
      return id ? id[0] : '';
    }
    return '';
  }

  getDownloadUrl(url) {
    // get file id from url
    const fileId = this.getDriveFileIdFromUrl(url);
    let downloadUrl = '';
    if (url && fileId) {
      // create download link
      if (url.indexOf('docs.google.com/presentation/d/') > -1) {
        downloadUrl = `https://docs.google.com/a/globallogic.com/presentation/d/${fileId}/export/pptx`;
      } else if (url.indexOf(`docs.google.com/document/d/`) > -1) {
        downloadUrl = `https://docs.google.com/a/globallogic.com/document/d/${fileId}/export?format=doc`;
      } else if (url.indexOf(`docs.google.com/spreadsheets/d/`) > -1) {
        downloadUrl = `https://docs.google.com/a/globallogic.com/spreadsheets/d/${fileId}/export?format=xlsx`;
      } else {
        downloadUrl = environment.driveUrl + `/uc?export=download&id=${fileId}`;
      }
    }
    return downloadUrl;
  }

  getNuggetDate(nugget, type) {
    if (type === 'poc') {
      return nugget.poc_date;
    } else if (type === 'tech-talk') {
      return nugget.event_date;
    } else if (type === 'tech-doc') {
      return nugget.creation_date;
    } else if (type === 'deck') {
      return nugget.created_at;
    } else if (type === 'curated-content') {
      return nugget.created_at;
    } else {
      return nugget.created_date;
    }
  }

  truncate(value: string, limit = 50, completeWords = false, ellipsis = '...', filterHtml = true) {
    if (value) {
      if (filterHtml) {
        value = value.replace(/\r?\n|\r/g, ' ').replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ').replace(/\s\s+/g, ' ');
      }
      if (completeWords && value.length > limit) {
        const li = value.substr(0, limit).lastIndexOf(' ');
        limit = li > -1 ? li : limit;
      }

      return value.substr(0, limit) + (value.length > limit ? ellipsis : '');
    }
    return '';
  }

  truncateBreadcrumb(text: string) {
    return this.truncate(text, 80);
  }

  dateObjectToDate(date) {
    if (!date) {
      return date;
    }
    return `${date.year}-${date.month}-${date.day}`;
  }

  dateObjectToDateTime(date) {
    if (!date) {
      return date;
    }
    const today = new Date();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return `${date.year}-${date.month}-${date.day} ${time}`;
  }

  dateStringToDateObject(date) {
    if (!date) {
      return date;
    }
    const dateObj = new Date(date);
    return { year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate() };
  }

  getTodayDate() {
    const date = new Date();
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  // remove html
  sanitizeString(string) {
    return string.replace(/\r?\n|\r/g, ' ').replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ').replace(/&#{0,1}[a-z0-9]+;/ig, '');
  }

  parseHttpErrors(error) {
    if (error.error.error) {
      if (typeof error.error.error === 'string') {
        return [{ message: error.error.error }];
      } else {
        return error.error.error;
      }
    } else {
      return error.error;
    }
  }

  chunk(arr, size) {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  }

  getImportantLinkParams(importantLink) {
    const params = {};
    const param = importantLink.url.split('?')[1];
    if (!param) {
      return params;
    }
    param.split('&').map(p => {
      const ps = p.split('=');
      params[ps[0]] = ps[1];
    });
    return params;
  }

  onFormCancel(originalValues, newValues, route, id?, queryParams?) {
    // if (!this.isEqual(originalValues, newValues)) {
    //   const data = {
    //     title: $localize`Leave page`, message: $localize`This page has unsaved changes, are you sure you want to leave this page?`,
    //     size: 'md', showFooter: true, btnOkText: $localize`Yes`, btnOkClass: 'btn-light', btnCancelText: $localize`No`, btnCancelClass: 'btn-primary'
    //   };
    //   this.modalService.confirm(data)
    //     .then((confirmed) => {
    //       if (confirmed) {
    //         if (id) {
    //           this.router.navigate([route, id], { queryParams });
    //         } else {
    //           this.router.navigate([route]);
    //         }
    //       }
    //     })
    //     .catch(() => { });
    // } else {
    if (id) {
      this.router.navigate([route, id], { queryParams });
    } else {
      this.router.navigate([route]);
    }
    // }
  }

  isEqual(originalValues, newValues) {
    return isEqual(originalValues, newValues);
  }

  updatePosition(items, data) {
    switch (data.direction) {
      case 'first':
        const fi = this.getIndex(items, data.id);
        items = this.array_move(items, fi, 0);
        break;
      case 'up':
        const ui = this.getIndex(items, data.id);
        items = this.array_move(items, ui, ui - 1);
        break;
      case 'down':
        const di = this.getIndex(items, data.id);
        items = this.array_move(items, di, di + 1);
        break;
      case 'last':
        const li = this.getIndex(items, data.id);
        items = this.array_move(items, li, items.length - 1);
        break;
    }
    return items;
  }

  array_move(arr, old_index, new_index) {
    if (new_index < 0 || arr.length <= new_index) {
      return arr;
    }
    // if (new_index >= arr.length) {
    //   new_index = arr.length - 1;
    // }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  getIndex(items, id) {
    return items.findIndex(d => d.id === id);
  }

  scrollTo(element) {
    let elementPosition = element.offsetTop;
    if (this.showBreadcrumb) {
      elementPosition += 80;
    }
    try {
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    } catch (e) {
      console.error(e);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  closeTooltip(event, t, element) {
    let x = 0;
    let y = 0;
    const onMouseMove = (e) => {
      x = e.x;
      y = e.y;
    }
    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('mouseenter', onMouseMove, false);

    try {
      setTimeout(() => {
        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseenter', onMouseMove, false);
        if (!document.elementFromPoint(x, y).closest(element)) {
          t.close();
        }
      }, 100);
    } catch (e) {
      console.error(e);
    }
  }
}
