import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { PeopleService } from 'app/services/people/people.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-have-a-query',
  templateUrl: './have-a-query.component.html',
  styleUrls: ['./have-a-query.component.css']
})
export class HaveAQueryComponent implements OnInit {
  @Input() queryType = '';
  @Input() mailingInfo;
  @Output() closeQuery = new EventEmitter();
  practiceInfo;
  people = [];
  memberType = '';
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  currentCity = this.loginService.currentUser.person?.location.city;

  constructor(private peopleService: PeopleService, private loginService: LoginService) { }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden'
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person)
  }

  setPracticeInfo(practice) {
    this.practiceInfo = practice;
    this.people = [];
    this.memberType = '';
  }

  setPeople(practice, type) {
    this.people = [];
    this.memberType = type;
    if (type === 'ph') {
      if (practice.head) {
        this.people.push(practice.head)
      }
      if (practice.cohead) {
        this.people.push(practice.cohead)
      }
    } else if (type === 'cc') {
      this.people = practice.members;
    }
    this.directiveRef.update();
  }

  dismiss() {
    this.closeQuery.emit(true);
  }

  ngOnDestroy(): void {
    document.body.style.removeProperty('overflow');
  }
}
