import { Component, OnInit, Input, HostListener } from '@angular/core';
import { PeopleService } from 'app/services/people/people.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { LoginService } from 'app/services/auth/login.service';
import { FilterService } from 'app/services/filter/filter.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.css']
})
export class MemberCardComponent implements OnInit {
  @Input() data;
  @Input() height;
  @Input() classes;
  personName = '';
  isPrivilegedUser = false;
  cName = '';
  isHover = false;
  showAction = true;

  @HostListener('mouseover') onHover(event) {
    this.isHover = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isHover = false;
  }

  constructor(private peopleService: PeopleService, private modalService: ModalPopupService, private practiceService: PracticesService,
    private loginService: LoginService, private filterService: FilterService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.personName = this.peopleService.getPersonName(this.data);
    this.isPrivilegedUser = this.loginService.isPrivilegedUser([this.practiceService.currentPractice[0]['id']], false);
    this.cName = this.practiceService.currentPractice[0]['c_name'];

    if (!this.isPrivilegedUser && this.filterService.ccLocations.length &&
      !this.filterService.ccLocations.includes(this.data['location']['id'])) {
      this.showAction = false;
    }
  }

  getPersonTitle(person) {
    return this.peopleService.getPersonTitle(person);
  }

  // changeType(event, from, to) {
  //   this.practiceService.changeType(event, from, to, this.data);
  // event.preventDefault();
  // let message;
  // if(to === 2 && from === 1) {
  //   message = `Are you sure you want to change ${this.personName} membership from Subject Matter Expert to Member?`;
  // }
  // if (to === 1 && from === 2) {
  //   message = `Are you sure you want to change ${this.personName} membership from Member to Subject Matter Expert?`
  // }
  // if(to === 4 && from === 2) {
  //   message = `Are you sure you want to change ${this.personName} membership from Member to Core Member?`;
  // }
  // // if (to === 2 && from === 4) {
  // //   message = `Are you sure you want to change ${this.personName} membership from Core Member to Member?`
  // // }

  // const data = {
  //   title: 'Update Membership', message: message, size: 'md', showFooter: true, btnOkText: 'Yes', btnCancelText: 'No'
  // };
  // this.modalService.confirm(data)
  //   .then((confirmed) => {
  //     if (confirmed) {
  //       const memberData = {
  //         original_member_type: from,
  //         member_type: to
  //       };

  //       this.practiceService.updateMembership(this.practiceService.currentPractice[0]['id'], this.data['login'], memberData)
  //         .subscribe(response => {
  //           const headers = new HttpHeaders({
  //             'Cache-Control': 'no-cache'
  //           });
  //           this.toastrService.success(response['message'])
  //           this.practiceService.updateMembershipEvent.emit(headers);
  //           this.updateGmailGroups(this.data.contact.email_id, this.practiceService.currentPractice[0]['id'], 'move', to, from);
  //         });
  //     }
  //   })
  //   .catch(() => { });
  // }

  removeMember(event) {
    this.practiceService.removeMember(event, this.data);

    // event.preventDefault();
    // const data = {
    //   title: 'Remove Membership', message: `Are you sure you want to remove ${this.personName} from
    //    ${this.practiceService.currentPractice[0]['name']} practice?`,
    //   size: 'md', showFooter: true, btnOkText: 'Yes', btnCancelText: 'No'
    // };

    // this.modalService.confirm(data)
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.practiceService.removeMembership(this.practiceService.currentPractice[0]['id'], this.data['login'])
    //         .subscribe(() => {
    //           const headers = new HttpHeaders({
    //             'Cache-Control': 'no-cache'
    //           });
    //           this.practiceService.deleteMembershipEvent.emit({ headers, type: 'practice-member' });

    //           this.updateGmailGroups(this.data.contact.email_id, this.practiceService.currentPractice[0]['id'], 'remove_all');
    //         });
    //     }
    //   })
    //   .catch(() => { });
  }

  moveFrom(event, from) {
    this.practiceService.moveFrom(event, from, this.data);
    // event.preventDefault();
    // const memberData = {
    //   id: this.practiceService.currentPractice[0]['id'],
    //   login: this.data['login'],
    //   email: this.data['contact']['email_id'],
    //   personName: this.personName,
    //   orignalMemberId: from
    // };

    // const data = {
    //   title: 'Update Membership',
    //   message: '',
    //   component: 'MoveFromCoreComponent', data: memberData
    // };

    // this.modalService.open(data);
  }

  editLocation(event) {
    this.practiceService.editLocation(event, this.data);

    // event.preventDefault();
    // const currentPracticeMemberships = _.filter(this.data['practices'],
    //   { id: this.practiceService.currentPractice[0]['id'] })[0]['memberships'];
    // let locations = [];
    // if (_.findIndex(currentPracticeMemberships, { c_name: 'center_coordinator' }) > -1) {
    //   locations = currentPracticeMemberships.filter(member => member['center']).map(member => member['center']);
    // }
    // const memberData = {
    //   id: this.practiceService.currentPractice[0]['id'],
    //   mid: this.data['login'],
    //   email: this.data['contact']['email_id'],
    //   locations: locations
    // };
    // const data = { title: 'Center Coordinator', message: '', component: 'EditCcLocationComponent', data: memberData };
    // this.modalService.open(data);
  }

  // updateGmailGroups(emailId, practiceId, action, member_type_id?, from_member_type_id?) {
  //   this.practiceService.updateGmailGroups(emailId, practiceId, action, member_type_id, from_member_type_id)
  //     .subscribe();
  // }
}
