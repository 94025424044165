<div class="nugget-card" (mouseenter)="showPreviewIcon=true" (mouseleave)="showPreviewIcon=false">
  <a [routerLink]="['/case-studies',data.id]">
    <div class="card card-full-img" [ngStyle]="{backgroundImage: 'url(' + data.preview_url + ')'}">
      <div class="card-top-actions">
        <ng-container *ngIf="!data.readOnly">
          <app-change-nugget-preview [practice]="data.practices[0]" [entityId]="data.id"
            [entityCategory]="data.search_type" [previewUrl]="data.preview_url"
            *ngIf="previewAccess && isAccess && showPreviewIcon">
          </app-change-nugget-preview>
          <app-create-collection [entityId]="data.id" [entityCategory]="6"
            *ngIf="showPreviewIcon"></app-create-collection>
        </ng-container>
        <div class="change-nugget-preview" (click)="removeCaseStudy($event, data.id, '6')" *ngIf="data.removeCollection && showPreviewIcon" >
          <span class="circle-actions" i18n-ngbTooltip ngbTooltip="Remove from My Collection" container="body"><i
              class="fa-solid fa-xmark"></i></span>
        </div>
      </div>
      <div class="background"> </div>
      <div class="infos">
        <div class="title truncate-2">
          <span [title]="data['client_name']">
            {{data.client_name}}
          </span>
        </div>
        <div class="divider"></div>
        <div class="subtitle truncate-2">
          <span [title]="data['project_name']">
            {{data.project_name}}
          </span>
        </div>
      </div>
    </div>
  </a>
</div>