import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  apiUrl = environment.apiUrl;
  form: UntypedFormGroup;
  documentEvent = new EventEmitter();

  constructor(private http: HttpClient) { }
  getDocuments(params?) {
    return this.http.get(this.apiUrl + '/v1/documents', { params });
  }

  getDocument(id: number) {
    return this.http.get(this.apiUrl + `/v1/documents/${id}`);
  }

  submitDocument(data) {
    return this.http.post(this.apiUrl + '/v1/documents', data);
  }

  updateDocument(id, data) {
    return this.http.put(this.apiUrl + `/v1/documents/${id}`, data);
  }
  updateDocumentTitle(id, data) {
    return this.http.put(this.apiUrl + `/v1/documents/${id}/title`, data);
  }

  deleteDocument(id: number) {
    return this.http.delete(this.apiUrl + `/v1/documents/${id}`);
  }
}
