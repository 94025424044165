import { Component, OnInit, Input } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PeopleService } from 'app/services/people/people.service';
import { environment } from 'environments/environment';
import { LoginService } from 'app/services/auth/login.service';
import { uniq } from 'lodash';
import { PracticesService } from 'app/services/practices/practices.service';

@Component({
  selector: 'app-practice-members-card',
  templateUrl: './practice-members-card.component.html',
  styleUrls: ['./practice-members-card.component.css']
})
export class PracticeMembersCardComponent implements OnInit {
  @Input() membersType;
  @Input() membersCount;
  @Input() members;
  @Input() currentPractice;
  @Input() email;
  @Input() mailLabel;

  title;
  queryParam;
  description;
  buttonText;
  layout = 4;
  bucketUrl = environment.bucketUrl;
  currentUserLocation;

  constructor(public commonService: CommonMethodsService, private peopleService: PeopleService, private loginService: LoginService,
    private practicesService: PracticesService) { }

  ngOnInit(): void {
    this.currentUserLocation = this.loginService.currentUser.person?.location || "";
    switch (this.membersType) {
      case "center_coordinators":
        this.title = $localize`Center Coordinators`;
        this.queryParam = `Center Coordinator`
        this.description = $localize`Coordinators are Core or SMEs members who have further responsibilities of coordinating the various practice activities in their respective locations.`
        this.buttonText = $localize`View ALL Center Coordinators`
        break;
      case "core":
        this.title = $localize`Core Members`;
        this.queryParam = `Core Member`
        this.description = $localize`Group of recognised Core members for the practice. Core members strengthen the practice in the organization through their expertise, experience, and active contributions. Core members own, lead and drive practice activities including consulting and advisory, client Innovations, sales responses, evaluating new frameworks or tools, defining processes, oversight of new engineering initiatives, technical knowledge sharing sessions`
        this.buttonText = $localize`View ALL Core Members`
        if (this.membersCount > 4) this.layout = 6;
        break;
      case "sme":
        this.title = $localize`Subject Matter Experts`;
        this.queryParam = `Subject Matter Expert`
        this.description = $localize`Group of recognised SMEs for the practice. SMEs strengthen the practice in the organization through their expertise, experience, and active contributions. SMEs are involved in many practice activities including consulting and advisory, client Innovations, sales responses, evaluating new frameworks or tools, defining processes, oversight of new engineering initiatives, technical knowledge sharing sessions.`
        this.buttonText = $localize`View ALL Subject Matter Experts`
        if (this.membersCount > 4) this.layout = 6;
        break;
      case "members":
        this.title = $localize`Members`;
        this.queryParam = `Member`
        this.description = $localize`Engineers interested or passionate about learning and contributing various practices activities. Members have opportunities to contribute in the area of interest and progress to the SME group.`
        this.buttonText = $localize`View ALL Members`
        if (this.membersCount > 4 && this.membersCount <= 6) {
          this.layout = 6;
        } else if (this.membersCount > 6) {
          this.layout = 36;
        }
        break;
    }
  }

  getPersonTitle(person) {
    return this.peopleService.getPersonTitle(person);
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person);
  }

  rearrange(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  getPersonCCLocations(person) {
    let locations = this.peopleService.getPersonCCLocations(person, [this.currentPractice.id]);

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].country == this.currentUserLocation?.country) {
        this.rearrange(locations, i, 0);
      }
    }

    const index = locations.findIndex(object => {
      return object.city === this.currentUserLocation?.city;
    });

    if (index > -1) {
      this.rearrange(locations, index, 0);
    }

    return locations;
  }

  onMouseleave(event, t, tooltipClass) {
    this.commonService.closeTooltip(event, t, tooltipClass);
  }

  openContacUs() {
    this.practicesService.openContacUs(this.email, this.mailLabel)
  }
}
