<div class="practice-query" [perfectScrollbar]="config">
  <ng-container *ngIf="queryType==='global'">
    <div class="query-head">Connect with Global Practice Head!
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="personData" [ngTemplateOutletContext]="{person:mailingInfo.global_head}"
      *ngIf="mailingInfo.global_head">
    </ng-container>
  </ng-container>
  <ng-container *ngIf="queryType==='practice'">
    <div class="query-head">Practice Specific Query? We're here to help!
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="query-content">
      <p>Select in which practice you would like ask your query.</p>
      <div class="query-chips mt-10">
        <a href="javascript:;" *ngFor="let practice of mailingInfo.practices" (click)="setPracticeInfo(practice);"
          [ngClass]="{'active':practiceInfo && (practice.id === practiceInfo.id)}">{{practice.name}}</a>
      </div>
    </div>

    <div class="query-content" *ngIf="practiceInfo">
      <p>Select in which group you would like ask your query.</p>
      <div class="query-chips mt-10">
        <a href="javascript:;" (click)="setPeople(practiceInfo,'cc')" *ngIf="practiceInfo.members.length"
          [ngClass]="{'active':memberType === 'cc'}">
          {{currentCity}} Center Coordinator</a>
        <a href="javascript:;" (click)="setPeople(practiceInfo,'ph')" *ngIf="practiceInfo.head || practiceInfo.cohead"
          [ngClass]="{'active':memberType === 'ph'}">
          {{practiceInfo.name}} Practice Head</a>
        <a target="_blank"
          href="http://mail.google.com/a/globallogic.com/mail?view=cm&amp;to={{practiceInfo.mailing_list}}">
          All {{practiceInfo.name}} Members</a>
        <a target="_blank"
          href="http://mail.google.com/a/globallogic.com/mail?view=cm&amp;to={{practiceInfo.core_mailing_list}}">
          All {{practiceInfo.name}} Core Members</a>
        <a target="_blank"
          href="http://mail.google.com/a/globallogic.com/mail?view=cm&amp;to={{practiceInfo.center_coordinator_mailing_list}}">
          All {{practiceInfo.name}} Center Coordinators
        </a>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="personData" [ngTemplateOutletContext]="{person:person}"
      *ngFor="let person of people">
    </ng-container>
  </ng-container>
</div>

<ng-template #personData let-person="person">
  <div class="query-content">
    <div class="user_avatar"><span class="avatar avatar-xl"><img [src]="person.photo_url"></span>
    </div>
    <div class="user_details">
      <div><a [routerLink]="['/team',person.login]" class="font-weight-bold">{{getPersonName(person)}}</a></div>
      <div>
        <div class="d-inline-block align-top"><i class="fas fa-envelope"></i></div>
        <div class="d-inline-block ml-5">
          <a target="_blank" href="http://mail.google.com/a/globallogic.com/mail?view=cm&amp;to={{person.email_id}}">
            {{person.email_id}}</a>
        </div>
      </div>
      <div *ngIf="person.work || person.mobile">
        <div class="d-inline-block align-top"><i class="fas fa-phone-alt"></i></div>
        <div class="d-inline-block ml-5">
          <div *ngIf="person.work"><a href="tel:{{person.work}}"> {{person.work}}</a></div>
          <div *ngIf="person.mobile"><a href="tel:{{person.mobile}}"> {{person.mobile}}</a></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
