import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
// import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-get-token',
  templateUrl: './get-token.component.html',
  styleUrls: ['./get-token.component.css']
})
export class GetTokenComponent implements OnInit {
  invalidLogin = false;
  error: any;
  screenHeight = '768px';
  accessToken: any;

  constructor(private loginService: LoginService) {
    this.screenHeight = `${window.innerHeight}px`;
  }

  ngOnInit() {
    this.accessToken = '';

    if (this.loginService.isLoggedIn()) {
      this.accessToken = `Bearer ${localStorage.getItem('accessToken')}`;
    }
  }

  public socialSignIn(socialPlatform: string) {
    // let socialPlatformProvider;
    // if (socialPlatform === 'google') {
    //   socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    // }

    // this.socialAuthService.signIn(socialPlatformProvider).then(
    //   (userData) => {
    //     if (userData) {
    //       this.signIn(userData);
    //     }
    //   }
    // );
  }

  signIn(credentials) {
    this.loginService.login(credentials)
      .subscribe(result => {
        if (result) {
          this.accessToken = `Bearer ${localStorage.getItem('accessToken')}`;
        } else {
          this.invalidLogin = true;
        }
      },
        error => {
          this.error = error; // error path
          this.invalidLogin = true;
        }
      );
  }
}
