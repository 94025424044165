<div class="border-none mb-20" [ngClass]="classes">
  <h3 class="cms-title">
    <a [routerLink]="data['url'] || '/pages/' + data['id']" [innerHTML]="data['title']"></a>
    <ng-container *ngIf="data['parent_title'] && (data['parent_title'] !== 'Others')">
      <i></i><span [innerHTML]="data['parent_title']"></span></ng-container>
  </h3>

  <div class="cms-description" [innerHTML]="(data['description'] || data['short_description']) | truncate:250">
  </div>
</div>
