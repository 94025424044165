import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2, Inject, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

declare const $: any;

@Directive({
  selector: '[appTranslate]'
})
export class TranslateDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() appTranslate: string;
  listenClickFunc: Function;

  constructor(private element: ElementRef, private router: Router, private commonService: CommonMethodsService,
    private renderer: Renderer2) { }

  ngOnChanges() {
    this.element.nativeElement.innerHTML = this.appTranslate;
  }

  ngAfterViewInit() {
    const navigationElements = Array.prototype.slice.call(this.element.nativeElement.querySelectorAll('[href]'));
    navigationElements.forEach(elem => {
      this.listenClickFunc = this.renderer.listen(elem, 'click', (event) => {
        event.preventDefault();
        // handle links
        setTimeout(() => {
          const href = elem.getAttribute('href');
          const target = elem.getAttribute('target');
          if (!href) {
            return false;
          }
          if (href.indexOf('mailto:') > -1) {
            window.open(href);
            return false;
          }
          // external links
          if (this.urlValidation(href)) {
            window.open(href);
          } else {
            // scroll links
            if (href.indexOf('#') > -1) { // scroll on same page
              const id = href.split('#')[1];
              const element = this.element.nativeElement.querySelector('#' + id);
              this.commonService.scrollTo(element);
              return false;
            }

            // internal links
            if (target && target !== '_self') {
              window.open(href, target);
            } else {
              this.router.navigateByUrl(href);
            }
          }
        });
      });
    });
  }

  urlValidation(url) {
    if (url) {
      return url
        .match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/){0,1}[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
    }
    return false;
  }

  ngOnDestroy() {
    if (this.listenClickFunc) {
      this.listenClickFunc();
    }
  }
}
