<form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
  <div class="form-group" id="moveFromCoreGroup">

    <label i18n>Are you sure you want to change {{data['personName']}} membership to :</label>
    <div class="custom-control custom-radio custom-control-inline" *ngIf="[1,2].includes(data['orignalMemberId'])">
      <input type="radio" class="custom-control-input" id="core" formControlName="memberType" value="4">
      <label class="custom-control-label font-weight-normal" for="core" i18n>Core</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline" *ngIf="[2,4].includes(data['orignalMemberId'])">
      <input type="radio" class="custom-control-input" id="sme" formControlName="memberType" value="1">
      <label class="custom-control-label font-weight-normal" for="sme" i18n>Subject Matter Expert</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline" *ngIf="[1,4].includes(data['orignalMemberId'])">
      <input type="radio" class="custom-control-input" id="member" formControlName="memberType" value="2">
      <label class="custom-control-label font-weight-normal" for="member" i18n>Member</label>
    </div>
  </div>

  <!-- http errors -->
  <div class="form-group" *ngIf="httpError">
    <div class="invalid">
      {{httpError}}
    </div>
  </div>

  <div class="form-group mb-0">
    <button type="submit" class="btn btn-primary" [disabled]="submitted" [ngClass]="{'submitted':submitted}"
      i18n>Confirm</button>
    <button type="button" class="btn btn-light ml-15" (click)="cancel()" i18n>Cancel</button>
  </div>
</form>
