import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  updateTagsEvent = new EventEmitter();
  tagActionEvent = new EventEmitter();
  apiUrl = environment.apiUrl;

  tagApiUrl = environment.tagApiUrl;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  updateTags(id, tags, entityCategoryId, blogId?) {
    const headers = new HttpHeaders({
      'Authorization': 'Web'
    });

    const data = {
      email: this.loginService.currentUser.user.email,
      entity_id: id,
      entity_category_id: entityCategoryId,
      tags: tags
    };

    if (blogId) {
      data['blog_id'] = blogId;
    }
    return this.http.post(this.apiUrl + '/v1/tags/update_mapping', data, { headers });
  }

  getTags(params?) {
    return this.http.get(this.apiUrl + '/v1/tags', { params });
  }

  getTag(id) {
    return this.http.get(this.apiUrl + `/v1/tags/${id}`);
  }

  getNuggetTags(id, params?) {
    return this.http.get(this.apiUrl + '/v1/nuggets/' + id + '/tags', { params });
  }

  submitTag(data) {
    return this.http.post(this.apiUrl + '/v1/tags/', data);
  }

  updateTag(id, data) {
    return this.http.put(this.apiUrl + '/v1/tags/' + id, data)
  }

  mergeTag(currentTagId, alternateTagId, data) {
    return this.http.post(this.apiUrl + `/v1/tags/${currentTagId}/merge/${alternateTagId}`, data);
  }

  deleteTag(currentTagId, data) {
    return this.http.delete(this.apiUrl + `/v1/tags/${currentTagId}`, { body: data });
  }

  addAsSynonym(currentTagId, newTagId, data) {
    return this.http.post(this.apiUrl + `/v1/tags/${currentTagId}/add_As_synonym/${newTagId}`, data);
  }

  approveTag(id, data) {
    return this.http.post(this.apiUrl + `/v1/tags/${id}/approve`, data)
  }
}
