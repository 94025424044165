import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {

  constructor(private loginService: LoginService) { }

  checkUserAccess() {
    const access = { adminAccess: false, sysadminAccess: false };
    if (this.loginService.currentUser) {
      if (this.loginService.isPrivilegedUser() || this.loginService.isDev() || this.loginService.isAdmin() ||
        this.loginService.isCenterCoordinator() || this.loginService.isCoreMember() || this.loginService.isRegionalHead()) {
        access.adminAccess = true;
      }

      if (this.loginService.isServiceAccount() || this.loginService.isGlobalHead() || this.loginService.isSuperUser() ||
        this.loginService.isDev() || this.loginService.isSysAdmin() || this.loginService.isRegionalHead()) {
        access.sysadminAccess = true;
      }
    }
    return new Observable((observer) => {
      observer.next(access);
      observer.complete();
    });

  }
}
