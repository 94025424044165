import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-nugget-preview',
  templateUrl: './nugget-preview.component.html',
  styleUrls: ['./nugget-preview.component.css']
})
export class NuggetPreviewComponent implements OnInit, OnChanges {
  @Input() url = '';
  // @Input() fileId = '';
  @Input() docHeight = '410px';
  @Input() previewReady = true;
  @Input() driveAccess = true;
  @Input() viewerType = "google";
  @Input() cloudStorage;
  @Input() disableDownload = false;

  downloadUrl = '';

  constructor(private commonService: CommonMethodsService) { }

  ngOnInit() {
    // this.viewerType = this.viewerType || "google";
  }

  ngOnChanges(): void {
    if (!this.previewReady) {
      this.downloadUrl = this.commonService.getDownloadUrl(this.url)
    }
  }

  htmlValue() {
    if (this.url) {
      return `
        <iframe src="${this.url}" width="100%" height="${this.docHeight}" frameborder="0" allow="autoplay;" allowfullscreen></iframe>
      `;
    }
    return '';
  }
}
