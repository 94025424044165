<h2 class="pad-0 mb-15">{{label}}</h2>
<ul class="nugget-recommendations" [perfectScrollbar]="config">
  <li class="clearfix" [routerLink]="[route, nugget.id]" *ngFor="let nugget of nuggets" (click)="click()">
    <img class="nugget" [src]="nugget.preview_url">
    <div class="recommendations-subject">
      <div class="truncate-2">
        <span [title]="getTitle(nugget)">
          {{getTitle(nugget)}}
        </span>
      </div>
      <div class="date">{{nugget.date | date:'mediumDate':'GMT'}}</div>
      <div class="recommendations-member">
        <app-members-chip [members]="nugget.authors || nugget.author" [displayNumber]="3" classes="avatar-xs">
        </app-members-chip>
      </div>
    </div>
  </li>
</ul>